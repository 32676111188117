<template>
    <template v-if="readOnly">
        <div class=" p-col p-nogutter  p-grid p-ai-center ">
            <Button
                icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text"
                v-tooltip.focus="$t('xtype.fileField.enable')"
                style="height: 2rem; width: 1.22rem;align-self: center"
            />
            <Button class="p-button-outlined p-button-info bg-white-button " @click="downloadAttachment"  :disabled="isProcessing() || !item.FileName">{{$t('xtype.fileField.download')}}</Button>
            <span class="p-col text-left p-ml-2 text-ellipsis link " style="width: 20px;" @click="downloadAttachment" :class="{'p-disabled': isProcessing() || !item.FileName}"> {{item.FileName}} </span>
        </div>
    </template>
    <template v-else-if="preview">
        <div class=" p-col p-nogutter p-grid ">
            <Button
                icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text"
                v-tooltip.focus="$t('xtype.fileField.enable')"
                style="height: 2rem; width: 1.22rem;align-self: center"
                />
            <Button class="p-button-outlined p-button-info bg-white-button" style="" disabled >{{$t('xtype.fileField.label')}}</Button>
            <InputText 
                type="text"
                class="p-col"
                v-model="item.Placeholder"
            disabled/>
        </div>
    </template>
    <template v-else>
        <div class=" p-col p-nogutter  p-grid no_padding">
            <Button
                    icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text"
                v-tooltip.focus="$t('xtype.fileField.enable')"
                style="height: 2rem; width: 1.22rem;align-self: center"
                />
            <Button class="p-button-outlined p-button-info bg-white-button" 
                disabled
            >{{$t('xtype.fileField.label')}}</Button>
            <InputText 
                type="text"
                class="p-col"
                style="padding: 9px"
                v-model="item.Placeholder"
                @input="input" 
                :disabled="disabled"
            />
        </div>
    </template>
</template>

<script>
import http from '../../mixins/api'
import download from '../../mixins/download'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Processing from '@/mixins/processing'
import Tooltip from 'primevue/tooltip'



export default {
    name: 'fileField',
    props: ['item', 'Id', 'readOnly', 'preview', 'disabled'],
    mixins: [Processing],
    components: {
        InputText,
        Button,
    },
    data() {
        return {
            allowField: null
        }
    },
    methods: {
        input: function() {
            const data = {
                Id: this.Id,
                Xtype: "fileField",
                ItemId: this.item.ItemId,
                Placeholder: this.item.Placeholder,
            }

            this.$emit("setDataInItem", data)
        },
        downloadAttachment: async function() {
            this.startProcessing()
            await download.fileDownload(this.item.UploadFileId, this.item.FileName)
            this.endProcessing()
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


