<template>
    <MainPaperFormView :Type="'Form'" :Status="2" :canWatch="editableForm" > </MainPaperFormView>
</template>

<script>
import MainPaperFormView from '@/components/MainPaperFormView'

export default {
    name: 'AcceptFormBox',
    components: {
        MainPaperFormView
    },
    data: function() {
        return {
            Loading: false,
        }
    },
    created() {
    },
    computed: {
        editableForm: {
            get: function() {
                return this.$store.getters["gridDataStore/isEditableForm"]
            },
        }
    },
    methods: {
    },
}
</script>


