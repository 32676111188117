<template>
    <div class="editTemplateNoteInplace p-col-12 p-field p-grid p-nogutter p-jc-start p-pb-0 p-mb-0" style="padding-right: 0px;" >
        <Inplace class="p-col-12 p-grid p-nogutter no_margin no_padding" :active="activeNoteInPlace" @open="openNoteInplace"  :disabled="disabled" >
            <template #display >
                <div class="p-col-12 no_margin no_padding">
                    {{TemplateNote || $t("templateNote.placeHolder") }}
                </div>
            </template>
            <template #content >
                <div class="p-col-12 p-grid p-nogutter  no_margin no_padding">
                    <Textarea 
                     style="width: 100%;" 
                     v-model="TemplateNote" 
                     :autoResize="true" 
                     rows="2" 
                     @blur="onTemplateNoteBlur" 
                     ref="textareaRef" 
                     @keydown.enter.ctrl.exact="enterTemplateNote"
                     @keydown.enter.meta.exact="enterTemplateNote"
                     />
                </div>
                <div class="p-col-12 p-grid p-nogutter  no_padding p-ml-0 p-mt-2">
                    <Button @click="saveTemplateNote" class="p-button-outlined p-button-info" style="line-height: 12px;" > <div style="display: contents; text-transform:capitalize;">{{$t("utils.save")}} </div> <br /> <div style="font-size: 10px;display: contents;">(Ctrl+Enter)</div> </Button>
                    <Button @click="cancelTemplateNote" icon="pi pi-times" class="p-button-rounded p-button-text  p-button-plain" />
                </div>
            </template>
        </Inplace>
    </div>
</template>

<script>
import Inplace from 'primevue/inplace';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button'


export default {
    name: 'editTemplateNoteInplace',
    props: ["Type"],
    components: {
        Textarea,
        Inplace,
        Button,
    },
    data() {
        return {
            activeNoteInPlace: false,
            TemplateNote_tmp: "",
            InitTemplateNote: "",
            onButtonClickedStatus: 0, // 0, 1, 2
        }
    },
    watch: {
        activeNoteInPlace: function(active) {
            if(active) {
                const self = this
                this.$nextTick(function() {
                    self.InitTemplateNote = self.TemplateNote
                    self.onButtonClickedStatus = 0
                    if(self.$refs.textareaRef && self.$refs.textareaRef.$el) {
                        self.$refs.textareaRef.$el.select()
                    }
                })
            }

        }
    },
    computed: {
        TemplateNote: {
            get: function() {
                if(this.Type == "draft") {
                    return this.$store.getters["editTemplate/getTemplateNote"]
                }
                else if(this.Type == "post") {
                    return this.$store.getters["viewPostedForm/getFormNote"]
                } else if(this.Type == "sent") {
                    return this.$store.getters["viewPostedFormFromPostedUser/getSenderFormNote"]
                }
            },
            set: function(value) {
                this.TemplateNote_tmp = value
            },
        },
        disabled: {
            get: function() {
                if(this.Type == "post") {
                    return this.$store.getters["viewPostedForm/getFormNoteDisabled"]
                } else if(this.Type == "sent") {
                    return this.$store.getters["viewPostedFormFromPostedUser/getSenderFormNoteDisabled"]
                }
            },
        }
    },
    methods: {
        openNoteInplace: function() {
            this.activeNoteInPlace = true
            this.TemplateNote_tmp = this.TemplateNote
        },
        enterTemplateNote: function() {
            this.$refs.textareaRef.$el.blur()
        },
        onTemplateNoteBlur: function() {
            const self = this
            setTimeout(function() {
                if (self.onButtonClickedStatus == 0) {
                    self.saveTemplateNote()
                }
            }, 300)
        },
        saveTemplateNote: async function() {
            this.onButtonClickedStatus = 1
            const value = this.TemplateNote_tmp
            const self = this;
            if(this.Type == "draft") {
                const check = await this.$store.dispatch("editTemplate/checkUpdateTemplateNoteVersion")
                console.log(check)
                if(!check.Success) {
                    this.$confirm.require({
                        message: this.$i18n.tc("templateNote.anotherUserEditted", {USEREMAIL: check.LastEditWorkUserEmail}),
                        accept: async function() {
                            await self.updateTemplateFormNote(value)
                        },
                        reject: () => {
                            self.cancelTemplateNote()
                        }
                    })
                } else {
                    this.updateTemplateFormNote(value)
                }
            } else if(this.Type == "post") {
                const check = await this.$store.dispatch("viewPostedForm/checkUpdateFormVersion")
                if(!check.Success) {
                    this.$confirm.require({
                        message: this.$i18n.tc("templateNote.anotherUserEditted", {USEREMAIL: check.LastFormNoteUpdatedWorkUserEmail}),
                        accept: async function() {
                            await self.updateFormNote(value)
                        },
                        reject: () => {
                            self.cancelTemplateNote()
                        }
                    })
                } else {
                    this.updateFormNote(value)
                }
            } else if(this.Type == "sent") {
                await this.$store.dispatch("viewPostedFormFromPostedUser/updateSenderFormNoteById", value)
                this.activeNoteInPlace = false
            }
        },

        async updateTemplateFormNote(value) {
            await this.$store.dispatch("editTemplate/updateTemplateNote", value)
            const _id = this.$store.getters["editTemplate/getTemplateId"]
            this.$store.commit("gridDataStore/setDataInDataList", {_id: _id, prop: "TemplateNote", value: value})
            this.activeNoteInPlace = false
        },

        updateFormNote: async function(value) {
            await this.$store.dispatch("viewPostedForm/updateFormNote", value)
            const _id = this.$store.getters["viewPostedForm/getFormId"]
            this.$store.commit("gridDataStore/setDataInDataList", {_id: _id, prop: "FormNote", value: value})
            this.activeNoteInPlace = false
        },

        cancelTemplateNote: function() {
            this.onButtonClickedStatus = 2
            this.activeNoteInPlace = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


