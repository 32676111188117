<template>
    <div style="height: 100%; " v-bind:class="{onlyEditingTemplateFormView: !isPreview}">
    <div class="p-grid p-jc-center p-nogutter p-p-2 " v-bind:class="{onlyEditingTemplateFormView: !isPreview}" >
        <div class="p-col-9 p-md-9 p-lg-9 ">
            <div class="p-d-flex p-flex-column p-grid p-nogutter">
                <template v-if="Status == 0">
                    <div class="p-col-12 p-grid p-nogutter text-right">
                        <!-- <div class="p-col-12 text-right"> -->
                        <!--     {{$t("draft.header.createdUserEmail")}} -->
                        <!--     <i v-tooltip="$t('draft.header.createdUserEmailTooltip')" class="pi pi-info-circle" /> : -->
                        <!--     {{CreatedUserEmail}} -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 text-right"> -->
                        <!--     {{$t("draft.header.createdAndUpdatedDate")}}{{$filters.formatDate(UpdatedDate, null, "HH:mm:ss" )}} -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 p-d-flex text-right align-item-end" style="align-items: center;"> -->
                        <!--     {{$t("draft.header.templateId")}} -->
                        <!--         <i v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>: -->
                        <!--     {{TemplateId}} -->
                        <!-- </div> -->
                        <div class="p-col-12 p-d-flex text-right align-item-center align-item-end"> </div>
                    </div>
                    <div class="p-col-12 p-grid p-nogutter">
                        <div class="p-col-8 p-fluid p-formgrid p-grid p-nogutter" style="    align-content: flex-start;">

                            <div class="p-col-12 p-grid p-field  p-as-start" style="">
                                <label class="p-col-fixed" style="width:140px">{{$t("draft.documentName")}}</label>
                                <InputText class="p-col" type="text" v-model="DocumentName"   autoFocus/>
                            </div>

                            <div class="p-col-12 p-grid  p-field  p-as-start" style="flex-wrap: nowrap;">
                                <label class="p-col-fixed" style="width:140px;white-space:pre-wrap;">{{$t("draft.toDepartment")}}  
                                    <i  v-tooltip="ToDepartmentInfoTooltip" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col no_padding" style="min-width: 0px;" >
                                    <Dropdown panelClass="white-space-in-dropbox max-800-dropbox fixed-dropbox-panel" class="text-left text-ellipsis-dropbox white-space-in-dropbox editTemplateDropBox" v-model="DepartmentInfo" :options="DepartmentList" optionLabel="Name" v-bind:class="{'p-invalid': invalidViewMode && !DepartmentInfo}" v-tooltip="{value: DepartmentInfo ? DepartmentInfo.Name : ''}"  :emptyMessage="$t('inviteUserView.companyEmptyMessage')" showClear=true style="margin-bottom: -5px;" />
                                    <component :is="DepartmentInfo ? 'router-link' : 'span'" :class="{'p-disabled': !DepartmentInfo}" :to="{name: 'changeRegistrationInfo', query: {'activeDepartmentId': DepartmentInfo ? DepartmentInfo._id : '', 'activeCompanyId': DepartmentInfo ? DepartmentInfo.CompanyId : ''}}" style="font-size: 10px;" @click="toChangeRegistrationInfoLinkTextClick">{{$t("draft.toChangeRegistrationInfoLinkText")}}</component>
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field ">
                                <label class="p-col-fixed" style="width:140px" >{{$t("draft.publicPeriod")}}</label>
                                <span class="p-col text-left" >
                                    {{$t('draft.draftPublicPeriodTooltip')}}
                                </span>
                            </div>
                            <div class="p-col-12 p-grid">
                                <label class="p-col-fixed" style="width:140px" >{{$t('draft.publicRangeLabel')}}
                                    <i v-tooltip="$t('draft.publicRangeLabelInfo')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col text-left p-field  p-nogutter no_padding" style="">
                                    <Dropdown class="p-col text-left text-ellipsis-dropbox  editTemplateDropBox" 
                                        v-model="ScopeOfPublication" 
                                        :options="ScopeOfPublicationList" 
                                        optionValue="value" 
                                        optionLabel="label"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="p-col-4 ">
                            <div  class="p-col-12 p-field p-grid p-nogutter p-pt-0 p-mb-0" style="min-height: 120px;">
                                <editTemplateNoteInplace  :Type="'draft'" />
                            </div>
                            <div class="p-col p-grid text-left p-field p-nogutter p-pb-0" style="margin-bottom: 0px; height: 46px;">
                                <label  class="p-col-fixed no_margin" >{{$t('draft.controlTextLabel')}} </label>
                                <div class="p-col-fixed" style="width: 10px;height: 1px;" />
                                <InputText
                                        class="p-col "
                                        v-model="ControlText" 
                                        />
                            </div>
                            <div class="p-col p-grid text-left p-field p-nogutter p-pb-0" style="margin-bottom: 0px; ">
                                <label  class="p-col-fixed no_margin" >{{$t('draft.sortNumberLabel')}}
                                    <i v-tooltip="$t('draft.sortNumberInfoTooltip')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col-fixed" style="width: 10px;height: 1px;" />
                                <InputNumber 
                                    class="p-col text-right sortkey-input-number"
                                    style="width:50px" 
                                    mode="decimal" 
                                    :min="1"
                                    :disabled=true
                                    incrementButtonClass="p-button-secondary" 
                                    decrementButtonClass="p-button-secondary"
                                    showButtons
                                />
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <div class="p-formgroup-inline">
                            <label style="align-items: center;">{{$t("draft.type")}}
                                <i v-tooltip="$t('draft.typeNotion')" style="vertical-align: middle;" class="pi pi-info-circle"></i>:
                            </label>
                            <div v-for="type in AppTypeList" class="p-field-checkbox">
                                <Checkbox :id="type._id" name="type1" :value="type.AppTypeValue" v-model="type.IsActive" @change="onAppTypeCheckboxChange($event, type.IsActive, type.AppTypeValue, type)" :binary="true" />
                                <Inplace :active="type.IsEditActive" @open="type.IsEditActive=true" @click="onAppTypeInplaceClick(type)">
                                    <template #display>
                                        <label :for="type._id" v-if="type.IsActive" > {{ type.AppTypeLabel }}</label>
                                        <s v-else ><label :for="type._id" > {{ type.AppTypeLabel }}</label></s>
                                    </template>
                                    <template #content>
                                        <InputText style="width: 100px; min-width: 100px;" v-model="type.AppTypeLabel" @blur="onEditAppTypeLabelBlur(type.AppTypeLabel, type.AppTypeValue, type)" @input="onEditAppTypeLabelInput" :ref="'appTypeInplace_'+type._id" />
                                    </template>
                                </Inplace>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-grid  vertical-container" style="margin-bottom: 0px;">
                        <div class="p-field p-grid p-formgroup-inline no_margin">
                            <label style="margin-right: 4px;">{{$t("draft.selectAppType")}}</label>
                            <div class="p-field-checkbox">
                                <RadioButton value="kojin" v-model="userAttr" />
                                <label>{{$t("draft.kojin")}}</label>
                            </div>
                            <div class="p-field-checkbox">
                                <RadioButton value="houjin" v-model="userAttr" />
                                <label>{{$t("draft.houjin")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-grid  vertical-container">
                        <Button class="p-button-outlined p-button-info"  @click="copyAnotherUserAttrFormBtnClick" :disabled="isPreview" > {{CopyAnotherUserAttrForm}} </Button>
                        <label v-if="isPreview"> {{$t("draft.cantCopyInPreview")}} </label>
                    </div>
                </template>
                <template v-else-if="Status == 1 || Status == 2 ">
                    <div class="p-col-12 p-grid p-nogutter text-right">
                        <!-- <div class="p-col-12 text-right"> -->
                        <!--     <template v-if="Status == 1 "> -->
                        <!--         {{$t("draft.header.confirmedDate")}}{{$filters.formatDate(ConfirmDate, null, "HH:mm:ss" )}} -->
                        <!--     </template> -->
                        <!--     <template v-else> -->
                        <!--         {{$t("draft.header.garbageDate")}}{{$filters.formatDate(GarbageDate, null,  "HH:mm:ss" )}} -->
                        <!--     </template> -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 p-d-flex text-right align-item-end" style="align-items: center;"> -->
                        <!--     {{$t("draft.header.templateId")}} -->
                        <!--     <i  v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>: -->
                        <!--     {{TemplateId}} -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 p-d-flex text-right align-item-center align-item-end"> -->
                        <!--     {{$t("draft.header.formId")}} -->
                        <!-- </div> -->
                    </div>
                    <div class="p-col-12 p-grid p-nogutter">
                        <div class="p-col-8 p-fluid p-formgrid p-grid p-nogutter" style="    align-content: flex-start;">
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0" style="">
                                <label class="p-col-fixed" style="width:140px">{{$t("draft.documentName")}}</label>
                                <div class="p-col text-ellipsis ">
                                    <div class=" text-ellipsis " style="display: inline-block; max-width: 100%;" v-tooltip.top="DocumentName" >
                                        <span class=""  style="font-weight: bold;" > {{DocumentName}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0" style="">
                                <label class="p-col-fixed" style="width:140px">{{$t("draft.toDepartment")}}</label>
                                <div class="p-col text-ellipsis " >
                                    <div class=" text-ellipsis " style="display: inline-block; max-width: 100%;" v-tooltip.top="Department" >
                                        <span class=""   > {{Department}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0" style="">
                                <label class="p-col-fixed yellow-text" style="width:140px" >{{$t('draft.publicFormat')}}
                                    <i v-tooltip="$t('draft.publicFormatTooltip')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col text-left p-field  p-nogutter" style="" v-if="Status==1">
                                    <Dropdown class="p-col text-left text-ellipsis-dropbox editTemplateDropBox" 
                                        v-model="publicFormatType" 
                                        :options="PublicFormatList" 
                                        optionValue="value" 
                                        optionLabel="label"
                                    />
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0" style="">
                                <label class="p-col-fixed yellow-text" style="width:140px" >{{$t('draft.isVerification')}}
                                    <i v-tooltip="$t('draft.isVerificationTooltip')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col text-left p-field  p-nogutter" style="" v-if="Status==1">
                                    <Dropdown class="p-col text-left text-ellipsis-dropbox  editTemplateDropBox" 
                                        v-model="isVerification" 
                                        :options="VerificationList" 
                                        optionValue="value" 
                                        optionLabel="label"
                                    />
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0 " style="margin-bottom: 0.4rem;">
                                <label class="p-col-fixed yellow-text" style="width:140px" >{{$t("draft.publicPeriod")}}
                                    <i v-tooltip="$t('draft.canBeChangeAfterPublication')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col text-left p-field   p-nogutter" style="padding-left: 0px; align-items: center;" v-if="Status==1">
                                    <div class="p-grid p-nogutter" style="align-items: center;">
                                        <!-- <Calendar v-model="OpenBegin"  :dateFormat=dateFormat :showTime="true" :showSeconds="true" class="p-col" /> ~ <Calendar v-model="OpenEnd" :dateFormat=dateFormat :showTime="true" :showSeconds="true" class="p-col" /> -->
                                        <DatePicker v-model="OpenBegin" :locale=Locale class="p-col" style=""  :dateformat=dateFormat mode="dateTime" :timezone="timeZone" :popover="{ visibility: 'click' }" is24hr>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <InputText
                                                    readonly 
                                                    class="bg-white border px-2 py-1 rounded p-pt-1 p-pb-1"
                                                    :value="$filters.formatDate(OpenBegin, null, 'HH:mm')"
                                                    v-on="inputEvents"
                                                    />
                                        </template>
                                        <template #footer>
                                            <div style="padding-bottom: 10px;" class="text-center">{{timeZone}} {{$filters.formatDate(OpenBegin, 'Z')}}  </div>
                                        </template>

                                        </DatePicker>
                                        ~
                                        <DatePicker v-model="OpenEnd" :locale=Locale class="p-col" style="" :dateformat=dateFormat mode="dateTime" :timezone="timeZone" :popover="{ visibility: 'click' }"  :max-date="MaxOpenEnd" is24hr >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <InputText
                                                    readonly 
                                                    class="bg-white border px-2 py-1 rounded p-pt-1 p-pb-1"
                                                    :value="$filters.formatDate(OpenEnd, null, 'HH:mm')"
                                                    v-on="inputEvents"
                                                    />
                                        </template>
                                        <template #footer>
                                            <div style="padding-bottom: 10px;" class="text-center">{{timeZone}} {{$filters.formatDate(OpenBegin, 'Z')}}  </div>
                                        </template>
                                        </DatePicker>

                                        <!-- <Datepicker v-model="OpenBegin" :locale="Locale" class="p-col" style="" :format=dateFormat :timezone="timeZone" multi-calendars /> -->
                                        <!-- ~ -->
                                        <!-- <Datepicker v-model="OpenEnd" :locale="Locale" class="p-col" style="" :format=dateFormat :timezone="timeZone" :max-date="MaxOpenEnd" multi-calendars /> -->

                                        <div class="p-ml-4" v-if="MaxOpenEnd" >
                                            <UpgradeButton />
                                        </div>
                                    </div>
                                </div>

                                <div class="p-col text-left p-field  del-line-text" style=" align-self: center;"  v-else>
                                    {{ $filters.formatDate(OpenBegin, null, "HH:mm") }} ~ {{  $filters.formatDate(OpenEnd, null, "HH:mm")  }}
                                </div>
                            </div>
                            <div class="p-col-12 p-grid p-field  p-as-start p-mb-0" style="margin-bottom: 0.4rem;">
                                <label class="p-col-fixed yellow-text" style="width:140px" >{{$t('draft.publicRangeLabel')}}
                                    <i v-tooltip="$t('draft.publicRangeLabelInfo')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col text-left p-field  p-nogutter p-mb-1" style="" v-if="Status==1">
                                    <Dropdown class="p-col text-left text-ellipsis-dropbox  editTemplateDropBox" 
                                        :modelValue="ScopeOfPublication" 
                                        :options="ScopeOfPublicationList" 
                                        optionValue="value" 
                                        optionLabel="label"
                                        @change="changeScopeOfPublication"
                                    />
                                </div>
                                <div class="p-col text-left p-field p-nogutter" style=" align-self: center;"  v-else>
                                    {{ ScopeOfPublicationList.find(x => x.value==ScopeOfPublication) ? ScopeOfPublicationList.find(x => x.value==ScopeOfPublication).label : "" }}
                                </div>
                            </div>
                        </div>
                        <div class="p-col-4 ">
                            <div  class="p-col-12 p-field p-grid p-nogutter p-pt-0 p-mb-0" style="min-height: 120px;">
                                <editTemplateNoteInplace  :Type="'draft'" />
                            </div>
                            <div class="p-col p-grid text-left p-field p-nogutter p-pb-0" style="margin-bottom: 0px; height: 46px;">
                                <label  class="p-col-fixed no_margin" >{{$t('draft.controlTextLabel')}} </label>
                                <div class="p-col-fixed" style="width: 10px;height: 1px;" />
                                <InputText
                                        v-if="Status==1"
                                        class="p-col "
                                        v-model="ControlText" 
                                        @change="changeControlTextInPublic"
                                        />
                                <span v-if="Status==2" class="p-col vertical-align-middle align-item-center"> {{ControlText}} </span>
                            </div>
                            <div class="p-col p-grid text-left p-field p-nogutter p-pb-0" style="margin-bottom: 0px; ">
                                <label  class="p-col-fixed no_margin" >{{$t('draft.sortNumberLabel')}}
                                    <i v-tooltip="$t('draft.sortNumberInfoTooltip')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col-fixed" style="width: 10px;height: 1px;" />
                                <InputNumber 
                                    class="p-col text-right sortkey-input-number"
                                    style="width: 60px; !important;"
                                    v-model="SortNumberByTemplate" 
                                    mode="decimal" 
                                    :min="1"
                                    incrementButtonClass="p-button-secondary" 
                                    decrementButtonClass="p-button-secondary"
                                    showButtons
                                />
                            </div>
                            <div  class="p-col p-grid text-left p-field  p-nogutter p-pb-0">
                                <label  class="p-col-fixed" >{{$t('draft.defaultInbox')}}
                                    <i v-tooltip="$t('draft.defaultInboxTooltip')" class="pi pi-info-circle"></i>
                                </label>
                                <div class="p-col-fixed" style="width: 10px;height: 1px;" />
                                <Dropdown class="p-col text-left text-ellipsis-dropbox  editTemplateDropBox" 
                                          v-model="defaultInbox" 
                                          :disabled="Status==2 || $route.params.departmentId == $constants.AllDepartmentIdConstants"
                                          :options="defaultInboxList" 
                                          optionValue="_id" 
                                          optionLabel="Text"
                                          />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-grid p-nogutter">
                        <div class="p-col-8  " />
                        <div class="p-col-4  p-grid p-nogutter">
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <div class="p-formgroup-inline">
                            <label>{{$t("draft.type")}}</label>
                            <div v-for="type in AppTypeList" class="p-field-checkbox">
                                <Checkbox :id="type._id" name="type1" :value="type.AppTypeValue" v-model="type.IsActive" :disabled="true" :binary="true" />
                                <label :for="type._id" v-if="type.IsActive" > {{ type.AppTypeLabel }}</label>
                                <s v-else ><label :for="type._id" > {{ type.AppTypeLabel }}</label></s>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <div class="p-formgroup-inline">
                            <label>{{$t("draft.selectAppType")}}</label>
                            <div class="p-field-checkbox">
                                <RadioButton value="kojin" v-model="userAttr" />
                                <label>{{$t("draft.kojin")}}</label>
                            </div>
                            <div class="p-field-checkbox">
                                <RadioButton value="houjin" v-model="userAttr" />
                                <label>{{$t("draft.houjin")}}</label>
                            </div>
                        </div>
                    </div>
                </template>

                <transition-group name="in-out-translate-fade" mode="out-in" appear>
                    <div class="p-d-flex p-flex-column" v-if="isPreview">
                        <template v-for="(parts) in componentList" :key="parts._id" >
                            <previewPanel :item="parts" :edit="true" :required="requiredComponent(parts.Required, parts.Id)" >
                                <component 
                                   v-for="(item) in parts.items"
                                        v-bind:is="item.Xtype" 
                                        :preview=true
                                        :item="item" 
                                        :Id="parts.Id"
                                        :edit="true"
                                        :required="requiredComponent(parts.Required, parts.ItemId)"
                                        @clearRequirePreviewField="changeActiveRequiredField($event, parts.Id)"
                                       :key="item._id"
                                    > 
                                </component>
                            </previewPanel>
                        </template>
                    </div>

                    <div class="p-d-flex p-flex-column editTemplateComponentContainer" v-if="!isPreview">
                            <draggable 
                                v-model="componentList"
                                v-bind="dragOptions"
                                item-key="Id"
                                group="editTemplateComponent"
                                handle=".handle"
                                :key="Id"
                                :component-data="{ tag: 'div' }"
                            >
                                <template #item="{ element, index }">
                                    <div :key="element._id + index" style="">
                                        <templatePanel 
                                            @removeComponentButtonClick="removeComponentButtonClick($event, element)" 
                                            @addComponentButtonClick="addComponentButtonClick($event, element.No)"
                                            @copyComponentButtonClick="copyComponentButtonClick($event, element.No)"
                                            @upIconComponentButtonClick="upIconComponentButtonClick($event, element.No)"
                                            @downIconComponentButtonClick="downIconComponentButtonClick($event, element.No)"
                                            @setProp="setPropsToStore" 
                                            :item="element" 
                                            :Id="element.Id" 
                                            :readOnly="false" 
                                            :required="requiredComponent(element.Required, element.Id)"
                                            :disabled="Status == 1 || Status == 2"
                                            showComponentName=false
                                            :preview="isPreview" key="index" >
                                            <div class="p-col-12 align-item-center p-grid no_margin no_padding " v-bind:class="{'no_padding': element.ComponentType == 'Editor'}">
                                                <template v-for="(el) in element.items" :key="el._id" >
                                                    <component 
                                                        @setDataInItem="setDataToStore" 
                                                        :templateId="TemplateId" 
                                                        v-bind:is="el.Xtype" 
                                                        :item="el" 
                                                        :disabled="Status == 1 || Status == 2" 
                                                        :readOnly="false" 
                                                        :preview="isPreview" 
                                                        :Id="element.Id" 
                                                        :required="element.Required" >
                                                    </component>
                                                </template>
                                                <div v-if="element.HasExample" class="p-col p-grid no_margin">
                                                    <label class="p-col-3"> {{$t("draft.example")}} </label>
                                                    <InputText class="p-col-9" :placeholder="$t('xtype.blankText')" v-model="element.Example" v-on:input="editExample($event, element.Id)"/>
                                                </div>
                                            </div>
                                        </templatePanel>
                                    </div>
                                </template>
                            </draggable>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
    <Dialog header="" v-model:visible="displaySelectComponentDialog" style="width: 35%">
        <div class="p-grid">
            <div class="p-col-12 p-grid">
                <template v-for="formType in formTypeList">
                    <div class="" v-bind:class="formType.DialogClass">
                        <div class="p-field-radiobutton">
                            <label style="line-height: 20px;white-space: pre-line;">
                                <RadioButton  :value="formType" v-model="selectedFormType"/>
                                {{formType.label}}</label>
                        </div>
                    </div>
                </template>
            </div>

            <div class="p-col-12 p-grid  p-jc-between">
                <Button  class="p-button-outlined p-button-info" @click="displaySelectComponentDialog=false" style="margin-left: 1rem;">{{$t("utils.cancel")}}</Button>
                <Button  class="p-button-outlined p-button-info" @click="addFieldButtonClick" > {{$t("draft.add")}} </Button>
            </div>
        </div>
    </Dialog>
    
    <div class="p-field p-d-flex p-ai-center" style=" position: fixed; right: 30px; bottom: 80px;">
        <label class="" style="margin-bottom: 0px;">{{$t("draft.preview")}}</label>
        <InputSwitch v-model="isPreview" @change="changedPreviewSwitch" />
    </div>
    <div v-if="Status == 0" class="p-field p-d-flex p-flex-column p-ai-center" style=" position: fixed; right: 30px; bottom: 20px;align-items: flex-end;">
        <Button class="p-button-info" v-bind:class="{'p-button-outlined': !edited, 'p-button-danger': edited}"  @click="updateTemplateButtonClick" :disabled="isPreview"> {{$t("draft.temporarySaving")}} </Button>

        <label v-if="isPreview" class="warning-color"> {{$t("draft.previewing")}} </label>
    </div>

    <Dialog v-model:visible="displayLeastComponentDialog">
        {{$t("draft.minConponentText")}}
    </Dialog>
                        </div>
</template>

<script>

import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import textField from '@/components/templateFormParts/textField';
import textAreaField from '@/components/templateFormParts/textAreaField';
import checkBoxGroupField from '@/components/templateFormParts/checkBoxGroupField';
import fileField from '@/components/templateFormParts/fileField';
import editTemplateNoteInplace from '@/components/templateFormParts/editTemplateNoteInplace';
import radioBoxGroupField from '@/components/templateFormParts/radioBoxGroupField';
import dropDownField from '@/components/templateFormParts/dropDownField';
import multiSelectField from '@/components/templateFormParts/multiSelectField';
import dateField from '@/components/templateFormParts/dateField';
import timeField from '@/components/templateFormParts/timeField';
import datetimeField from '@/components/templateFormParts/datetimeField';
import simpleText from '@/components/templateFormParts/simpleText';
import fullEditor from '@/components/templateFormParts/fullEditor';
import fullEditor4Applicant from '@/components/templateFormParts/fullEditor4Applicant';
import templatePanel from '@/components/templateFormParts/templateForm';
import inviteUserDialog from '@/components/templateFormParts/inviteUserDialog';
import spaceField from "@/components/templateFormParts/spaceField"
import Dialog from 'primevue/dialog';
import draggable from 'vuedraggable';
import Button from 'primevue/button';
import UpgradeButton from "@/components/upgradeButton"
import { uuid } from "vue-uuid";
import store from '@/store';
import previewPanel from '@/components/previewFormParts/templateForm'
import operationButtonBar from '@/components/operationButtonBar'
import Tooltip from 'primevue/tooltip'
import { Calendar as VCalendar, DatePicker } from "v-calendar"

// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css';

import Inplace from 'primevue/inplace';

 
import filters from '@/mixins/filters'

import Scroll from "@/mixins/scroll"

import * as moment from 'moment-timezone';

export default {
    name: 'editTemplateForm',
    props: ['items'],
    emits: ['setScrollAmount'],
    components: {
        previewPanel,
        Dialog,
        InputText,
        InputSwitch,
        RadioButton,
        Checkbox,
        InputNumber,
        Dropdown,
        Calendar,
        Button,
        UpgradeButton,
        textField,
        textAreaField,
        checkBoxGroupField,
        fileField,
        dropDownField,
        multiSelectField,
        radioBoxGroupField,
        dateField,
        timeField,
        datetimeField,
        simpleText,
        fullEditor,
        fullEditor4Applicant,
        templatePanel,
        draggable,
        inviteUserDialog,
        spaceField,
        editTemplateNoteInplace,
        operationButtonBar,
        VCalendar,
        DatePicker,
        // Datepicker,
        Inplace,
    },
    data() {
        return {
            date: null,

            // invalidViewMode: false,
            // isPreview: this.isPreviewPanel,
            moment: moment,
            scrollAmount: {},
            selectedFormType: null,
            formTypeList: [
                {
                    label: this.$i18n.tc("xtype.textField.title"),
                    ComponentName: "textField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    // Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "textField",
                            ItemId: null,
                        },
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.textAreaField.title"),
                    ComponentName: "textAreaField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    // Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "textAreaField",
                            ItemId: null,
                        },
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.textField2.title"),
                    ComponentName: "textField2",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "textField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "textField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.textField3.title"),
                    ComponentName: "textField3",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "textField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "textField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "textField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.yearDateField.title"),
                    ComponentName: "yearDateField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "dateField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.periodDateField.title"),
                    ComponentName: "periodDateField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "dateField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "simpleText",
                            ItemId: null,
                            Label: "~"
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "dateField",
                            ItemId: null,
                        },
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.timeField.title"),
                    ComponentName: "timeField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "timeField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.timeRangeField.title"),
                    ComponentName: "timeRangeField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "timeField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "simpleText",
                            ItemId: null,
                            Label: "~"
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "timeField",
                            ItemId: null,
                        },
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.datetimeField.title"),
                    ComponentName: "datetimeField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "datetimeField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.datetimeRangeField.title"),
                    ComponentName: "datetimeRangeField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "datetimeField",
                            ItemId: null,
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "simpleText",
                            ItemId: null,
                            Label: "~"
                        },
                        {
                            Xtype: "spaceField",
                            ItemId: null,
                        },
                        {
                            Xtype: "datetimeField",
                            ItemId: null,
                        },
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.fileField.title"),
                    ComponentName: "fileField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-12",
                    Class: "p-mr-6 p-mb-6",
                    Grid: "6",
                    items: [
                        {
                            Xtype: "fileField",
                            ItemId: null,
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.dropDownField.title"),
                    ComponentName: "dropDownField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "dropDownField",
                            ItemId: null,
                            LabelList: [
                            {
                                label: this.$i18n.tc("xtype.dropDownField.optionLabel", {n: 1}),
                                value: "1",
                            },
                            {
                                label: this.$i18n.tc("xtype.dropDownField.optionLabel", {n: 2}),
                                value: "2",
                            },
                            {
                                label: this.$i18n.tc("xtype.dropDownField.optionLabel", {n: 3}),
                                value: "3",
                            },
                            ]
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.multiSelectField.title"),
                    ComponentName: "multiSelectField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-1 p-mb-1",
                    Grid: "1",
                    items: [
                        {
                            Xtype: "multiSelectField",
                            ItemId: null,
                            LabelList: [
                            {
                                label: this.$i18n.tc("xtype.multiSelectField.optionLabel", {n: 1}),
                                value: "1",
                            },
                            {
                                label: this.$i18n.tc("xtype.multiSelectField.optionLabel", {n: 2}),
                                value: "2",
                            },
                            {
                                label: this.$i18n.tc("xtype.multiSelectField.optionLabel", {n: 3}),
                                value: "3",
                            },
                            ]
                        }
                    ],
                    ComponentType: "",
                    HasExample: false,
                },
                {
                    label: this.$i18n.tc("xtype.radioBoxGroupField.title"),
                    ComponentName: "radioBoxGroupField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-6 p-mb-6",
                    Grid: "6",
                    ComponentType: "",
                    items: [
                        {
                            Xtype: "radioBoxGroupField",
                            ItemId: null,
                            LabelList: [
                                {label: "1 " + this.$i18n.tc("xtype.blankText"), value: uuid.v4()},
                                {label: "2", value: uuid.v4()},
                                {label: "3", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                            ]
                        },
                    ]
                },
                {
                    label: this.$i18n.tc("xtype.checkBoxGroupField.title"),
                    ComponentName: "checkBoxGroupField",
                    Id: uuid.v4(),
                    DialogClass: "p-col-6",
                    Class: "p-mr-6 p-mb-6",
                    Grid: "6",
                    ComponentType: "",
                    items: [
                        {
                            Xtype: "checkBoxGroupField",
                            ItemId: null,
                            LabelList: [
                                {label: "1 " + this.$i18n.tc("xtype.blankText"), value: uuid.v4()},
                                {label: "2", value: uuid.v4()},
                                {label: "3", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                                {label: "", value: uuid.v4()},
                            ]
                        },
                    ]
                },
                {
                    label: this.$i18n.tc("xtype.editor.title"),
                    ComponentType: "Editor",
                    Id: uuid.v4(),
                    DialogClass: "p-col-12",
                    HasExample: false,
                    items: [
                        {
                            Xtype: "fullEditor",
                            ItemId: null,
                        },
                    ],
                },
                {
                    label: this.$i18n.tc("xtype.editor4Applicant.title"),
                    ComponentType: "Editor",
                    Id: uuid.v4(),
                    DialogClass: "p-col-12",
                    HasExample: false,
                    items: [
                        {
                            Xtype: "fullEditor4Applicant",
                            ItemId: null,
                        },
                    ],
                },
            ],
            displayUrlDialog: false,
            dragging : false,
            displaySelectComponentDialog: false,
            nextComponentIndex: null,
            displayLeastComponentDialog: false,
            activeRequiredFieldIdList: {},
            ToDepartmentInfoTooltip: this.$i18n.tc("draft.toDepartmentInfoTooltip"),
        }
    },
    watch: {
    },
    computed: {
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                console.log(userInfo && userInfo.dateformat ? userInfo.dateformat.replace("YYYY", "yyyy").replace("DD", "dd") + " HH:mm" : "yyyy/MM/dd HH:mm")
                return userInfo && userInfo.dateformat ? userInfo.dateformat.replace("YYYY", "yyyy").replace("DD", "dd") + " HH:mm" : "yyyy/MM/dd HH:mm";
            }
        },
        timeZone: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return userInfo ? userInfo.timezone.split('-')[0].split('+')[0] : "Asia/Tokyo";
            }
        },
        invalidViewMode: {
            get: function() {
                return this.$store.getters["editTemplate/getIsInvalidViewMode"]
            }
        },
        CanStamp: {
            get: function() {
                return this.$store.getters["editTemplate/getCanStamp"]
            }
        },
        Stamped: {
            get: function() {
                return this.$store.getters["editTemplate/getStamped"]
            }
        },
        CopyAnotherUserAttrForm: {
            get: function() {
                const userAttr = this.userAttr
                if(userAttr == "kojin") {
                    return this.$i18n.tc("draft.copyHoujinTemplate");
                } else if(userAttr == "houjin") {
                    return this.$i18n.tc("draft.copyKojinTemplate");
                }
            }
        },
        DepartmentInfo: {
            get: function() {
                const DepartmentId = store.getters["editTemplate/getDepartmentInfo"]
                return this.$store.getters["userBranchInfo/getDepartmentList"].find(x => x._id == DepartmentId)
            },
            set: function(value) {
                const CreatedUserId = store.getters["editTemplate/getCreatedUserId"]
                const userInfo = this.$store.getters["auth/getUserInfo"]
                const UserId = userInfo._id;
                if(UserId == CreatedUserId) {
                    store.commit("editTemplate/setDepartmentId", value ? value._id : null)
                } else {
                    this.$toast.add({severity: 'error', summary: this.$i18n.tc("draft.failChangeToDepartment"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                    return
                }
            }
        },
        Status: {
            get: function() {
                return store.getters["editTemplate/getStatus"]
            },
        },
        DocumentName:  {
            get: function() {
                return store.getters["editTemplate/getDocumentName"]
            },
            set: function(value) {
                store.commit('editTemplate/setDocumentName', value)
            }
        },
        ScopeOfPublication:  {
            get: function() {
                return store.getters["editTemplate/getScopeOfPublication"]
            },
            set: function(value) {
                store.commit('editTemplate/setScopeOfPublication', value)
            }
        },
        ScopeOfPublicationList: {
            get: function() {
                return store.getters["editTemplate/getScopeOfPublicationList"]
            },
        },
        publicFormatType: {
            get: function() {
                return this.$store.getters["urlStore/getPublicFormatType"]
            },
            set: async function(value) {
                const templateId = this.$store.getters["getSelectRowId"];
                const res = await this.$store.dispatch("urlStore/setPublicFormatType", {templateId: templateId, publicFormatType: value})
                if(res.Success) {
                    // this.$store.commit("setIsSkipEmailAuth", !value)
                } else {
                }
            }
        },
        PublicFormatList: {
            get: function() {
                const publicFormatList = this.$i18n.tm("draft.publicFormatList")
                return publicFormatList
            },
        },
        isVerification: {
            get: function() {
                return !this.$store.getters["urlStore/getIsSkipEmailAuth"]
            },
            set: async function(value) {
                const oldValue = this.isVerification
                const templateId = this.$store.getters["getSelectRowId"];
                const res = await this.$store.dispatch("urlStore/setIsSkipEmailAuth", {templateId: templateId, skipEmailAuth: !value})
                if(res.Success) {
                    // this.$store.commit("setIsSkipEmailAuth", !value)
                } else {
                    this.$store.commit("urlStore/setIsSkipEmailAuth", !oldValue)
                }
            }
        },
        VerificationList: {
            get: function() {
                const verificationList = this.$i18n.tm("draft.verificationList")
                return verificationList
            },
        },
        Department: {
            get: function() {
                const DepartmentName = store.getters["editTemplate/getDepartmentName"]
                const CompanyName = store.getters["editTemplate/getCompanyName"]
                return (DepartmentName ? DepartmentName : "") + " (" + (CompanyName ? CompanyName : "") + ")"
            },
            set: function(value) {
                store.commit('editTemplate/setDepartmentName', value)
            }
        },
        DepartmentList: {
            get: function() {
                const userInfo = this.$store.getters["auth/getUserInfo"]
                const Email = userInfo.Email;
                const AllDepartmentList = store.getters["userBranchInfo/getDepartmentList"]
                const MemberList = store.getters["userBranchInfo/getMemberList"]
                const DepartmentIdList = MemberList.filter(member => member.Email == Email).map(member => member.DepartmentId)
                const DepartmentList = AllDepartmentList.filter(department => DepartmentIdList.includes(department._id))
                const CompanyList = store.getters["userBranchInfo/getCompanyList"]
                for(let i = 0; i < DepartmentList.length; i++) {
                    const Company = CompanyList.find(x => x._id == DepartmentList[i].CompanyId)
                    const CompanyName = Company ? " (" + (Company.Name ? Company.Name : this.$i18n.tc('formDataTable.blankCompanyName')) + ")" : " ()"
                    DepartmentList[i].Name = (DepartmentList[i].Name ? DepartmentList[i].Name : this.$i18n.tc('formDataTable.blankDepartmentName')) + CompanyName
                }
                if(DepartmentList.length) {
                    DepartmentList.push({
                        Name: "(" + this.$i18n.tc("utils.blank") + ")",
                        _id: "",
                    })
                }
                return DepartmentList
            },
        },
        userAttr: {
            get: function() {
                return store.getters["editTemplate/getUserAttr"]
            },
            set: function(value) {
                store.commit('editTemplate/setUserAttr', value)
            }
        },
        AppTypeList: {
            get: function() {
                const AppType = store.getters["editTemplate/getAppType"]
                return AppType
            },
        },
        GarbageDate: {
            get: function() {
                return this.$store.getters["editTemplate/getGarbageDate"]
            }
        },
        ConfirmDate: {
            get: function() {
                return this.$store.getters["editTemplate/getConfirmDate"]
            }
        },
        CreatedUserEmail: {
            get: function() {
                return this.$store.getters["editTemplate/getCreatedUserEmail"]
            }
        },
        UpdatedDate: {
            get: function() {
                return this.$store.getters["editTemplate/getUpdatedDate"]
            }
        },
        TemplateId: {
            get: function() {
                return this.$store.getters["getSelectRowId"]
            }
        },
        Locale: {
            get: function() {
                return this.$store.getters["auth/getUserLanguage"]
            }
        },
        OpenBegin: {
            get: function() {
                return this.$store.getters["editTemplate/getOpenBegin"]
                return new Date(this.$store.getters["editTemplate/getOpenBegin"])
            },
            set: async function(value) {
                const originalValue = value
                if(value) {
                    // value = filters.startOf(value)
                    // value = filters.formatDate(value, 'YYYY/MM/DD')
                    value = filters.changeTZ(value, "Europe/London")
                    // value = filters.changeTZ(value)
                } else {
                    value = null;
                }

                const id = this.$store.getters["getSelectRowId"]
                await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "OpenBegin", value: value})


                // value = filters.changeTZ(value)
                this.$store.commit('editTemplate/setOpenBegin', originalValue)
                await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
                this.$store.commit('editTemplate/setEdited', false)

            }
        },
        OpenEnd: {
            get: function() {
                return store.getters["editTemplate/getOpenEnd"]
            },
            set: async function(value) {
                const originalValue = value
                if(value) {
                    value = filters.changeTZ(value, "Europe/London")
                    // value = filters.changeTZ(value)
                } else {
                    value = null;
                }
                const oldValue = this.OpenEnd
                
                const id = this.$store.getters["getSelectRowId"]
                const res = await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "OpenEnd", value: value})
                if(res.Success) {
                    // value = filters.changeTZ(value, "", true)
                    // console.log(value)
                    this.$store.commit('editTemplate/setOpenEnd', originalValue)
                } else {
                    this.$store.commit('editTemplate/setOpenEnd', oldValue)
                }
                await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
                this.$store.commit('editTemplate/setEdited', false)

            }
        },
        SortNumberByTemplate: {
            get: function() {
                return store.getters["editTemplate/getSortNumberByTemplate"]
            },
            set: async function(value) {
                const id = this.$store.getters["getSelectRowId"]
                await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "SortNumberByTemplate", value: value})
                this.$store.commit('editTemplate/setSortNumberByTemplate', value)
                await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
                this.$store.commit('editTemplate/setEdited', false)
            }
        },
        ControlText: {
            get: function() {
                return this.$store.getters["editTemplate/getControlText"]
            },
            set: async function(value) {
                this.$store.commit('editTemplate/setControlText', value)
            }
        },
        componentList: {
            get: function() {
                const components = store.getters["editTemplate/getComponents"]

                return components;
            },
            set: function(list) {
                for(let i = 0; i < list.length; i++) {
                    list[i].No = i + 1
                }
                store.commit("editTemplate/setComponents", list)
            },
        },
        dragOptions: {
            get: function() {
                return {
                    animation: 200,
                    // group: "description",
                    group: {
                        name: "g1"
                    },
                    scrollSensitivity: 100,
                    forceFallback: true,
                    disabled: false,
                    ghostClass: "ghost"
                }
            }
        },
        TemplateNote: {
            get: function() {
                return this.$store.getters["editTemplate/getTemplateNote"]
            },
            set: function(value) {
                this.TemplateNote_tmp = value
            },
        },
        requiredComponent: {
            get: function() {
                const self = this;
                return function(required, Id) {
                    if(required == "true") {
                        if(self.activeRequiredFieldIdList[Id] === "false") {
                            return "false"
                        }
                        return "true"
                    }
                    return "false"
                }
            }
        },

        isPreview: {
            get: function() {
                return this.$store.getters["editTemplate/getIsPreview"]
            },
            set: function(value) {
                this.$store.commit("editTemplate/setIsPreview", value)
            },
        },

        edited: {
            get: function() {
                return this.$store.getters["editTemplate/getEdited"]
            }
        },

        defaultInboxList: {
            get: function() {
                const detailFormStatusList = this.$store.getters["editTemplate/getDetailFormStatusList"].filter(x => x.IsVisible)
                detailFormStatusList.unshift({
                    _id: "",
                    Text: this.$i18n.tc("draft.defaultInboxListBox"),
                })
                return detailFormStatusList
            }
        },
        defaultInbox: {
            get: function() {
                const _id = this.$store.getters["editTemplate/getDefaultDetailFormStatusId"]
                return _id ? _id : ""
            },
            set: async function(value) {
                const id = this.$store.getters["getSelectRowId"]
                this.$store.commit('editTemplate/setDefaultDetailFormStatusId', value)
                await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "DefaultDetailFormStatusId", value: value})
                this.$store.commit('editTemplate/setEdited', false)
            },
        },

        MaxOpenEnd: {
            get: function() {
                const validList = this.$store.getters["planedStore/getValidList"] || []
                const max = validList.includes(this.$constants.ValidFunctionList.MaxOpenEnd) ? null : moment().add(1, 'month').toDate() 
                return max
            },
        },
    },
    methods: {
        addFieldButtonClick: function() {
            if(this.selectedFormType) {
                let No = store.getters["editTemplate/getNextComponentNo"]
                if(this.displaySelectComponentDialog) {
                    No = this.nextComponentIndex
                    this.nextComponentIndex = null;
                }
                const userAttr = store.getters["editTemplate/getUserAttr"]
                const nextComponentId = uuid.v4()
                const Id = uuid.v4()
                const PanelId = "panel-" + Id.split("-")[0]
                const com = {
                    Id: Id,
                    UserAttrValue: userAttr,
                    Class: this.selectedFormType.Class,
                    items: JSON.parse(JSON.stringify(this.selectedFormType.items)),
                    No: No,
                    HasExample: this.selectedFormType.HasExample,
                    ComponentName: this.selectedFormType.ComponentName,
                    ComponentType: this.selectedFormType.ComponentType,
                    Required: "false",
                    PanelId: PanelId,
                }
                store.commit('editTemplate/addComponent', com)
                this.displaySelectComponentDialog = false
                this.$nextTick(function() {
                    Scroll.To(PanelId, "formView")
                })
            }
        },
        setPropsToStore: function(data) {
            store.commit('editTemplate/setComponentPropsByItemId', data)
        },
        removeComponentButtonClick: function(e, element) {
            const Id = element.Id
            const self = this
            const components = this.$store.getters["editTemplate/getComponents"]
            if(components.length == 1) { 
                this.displayLeastComponentDialog = true
            } else {
                this.$confirm.require({
                    message: this.$i18n.tc("draft.deleteComponentDialogMessage"),
                    acceptLabel: "OK",
                    accept: function() {
                        self.$store.commit('editTemplate/removeComponentById', Id)
                    },
                    reject: () => {
                    }
                })
            }
        },

        addComponentButtonClick: function(e, index){ 
            this.displaySelectComponentDialog = true
            this.nextComponentIndex = Number(index) + 1
        },

        upIconComponentButtonClick: function(e, index) {
            store.commit('editTemplate/upComponent', index)
        },

        downIconComponentButtonClick: function(e, index) {
            store.commit('editTemplate/downComponent', index)
        },

        copyComponentButtonClick: function(e, index){ 
            const self = this;
            this.$confirm.require({
                message: this.$i18n.tc("draft.copyComponentDialogMessage"),
                accept: function() {
                    self.onCopyComponent(e, index)
                },
                reject: () => {
                }
            })
        },

        onCopyComponent: function(e, index) {
            const self = this;
            const components = self.$store.getters["editTemplate/getComponents"]
            const copyComponent = components.find(x => x.No == index)
            let items = copyComponent.items.map(x => {
                x.Id = uuid.v4()

                return x;
            })
            const userAttr = self.$store.getters["editTemplate/getUserAttr"]
            const No = e.copyToBottom ? components.length + 1 : Number(index) + 1
            const Id = uuid.v4()
            const PanelId = "panel-" + Id.split("-")[0]
            const com = {
                Id: Id,
                UserAttrValue: userAttr,
                items: JSON.parse(JSON.stringify(items)),
                Label: copyComponent.Label,
                Label1: copyComponent.Label1,
                Label2: copyComponent.Label2,
                Label3: copyComponent.Label3,
                No: No,
                HasExample: copyComponent.HasExample,
                ComponentType: copyComponent.ComponentType,
                ComponentName: copyComponent.ComponentName,
                Required: copyComponent.Required,
                PanelId: PanelId
            }
            store.commit('editTemplate/addComponent', com)
            self.$nextTick(function() {
                Scroll.To(PanelId, "formView")
            })
        },

        setDataToStore: function(data) {
            store.commit('editTemplate/setComponentByItemId', data)
        },

        checkUpdateTemplate: async function() {
            const res = await store.dispatch('editTemplate/checkUpdateTemplateForm')

            return res
        },

        updateTemplate: async function() {
            const res = await store.dispatch('editTemplate/updateTemplateForm')
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: this.$i18n.tc("draft.saved"), detail: this.$i18n.tc("draft.saved_detail"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                const id = store.getters['editTemplate/getTemplateId']
                const updateRes = await store.dispatch('gridDataStore/updateTemplateRow', {id: id})
                if(updateRes.needLoad) {
                    this.$router.push({name: this.$route.meta.name, params: {departmentId: this.$route.params.departmentId, detailFormStatusId: this.$route.params.detailFormStatusId}})
                }
                
                this.$store.commit('editTemplate/resetAppTypeEditActive')
            } else {
                this.$toast.add({severity: 'error', summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),  life: this.$constants.errorHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.$store.commit('gridDataStore/setShowSpinner', false)
        },

        updateTemplateButtonClick: async function() {
            this.$store.commit('gridDataStore/setShowSpinner', true)
            const res = await this.checkUpdateTemplate()
            if(res.Success) {
                if(res.IsCurrentVersion) {
                    await this.updateTemplate()
                } else {
                    const self = this;
                    this.$confirm.require({
                        message: this.$i18n.tc("draft.overWriteConfirm"),
                        accept: async function() {
                            await self.updateTemplate()
                        },
                        reject: () => {
                            self.$store.commit('gridDataStore/setShowSpinner', false)
                        }
                    })
                }
            } else {
                this.$toast.add({severity: 'error', summary: this.$i18n.tc("utils.failed"), detail: res.Message ? res.Message : "",  life: this.$constants.errorHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('gridDataStore/setShowSpinner', false)
            }
        },



        editExample: function(e, Id) {
            store.commit('editTemplate/setComponentPropsByItemId', {
                Id: Id,
                label: "Example",
                value: e.target.value
            })
        },
        editFieldButtonClick: function(e) {

        },
        inviteUserInTemplateForm: function(e) {
            store.dispatch('editTemplate/inviteUserInTemplateForm')
        },
        clearRequirePreviewField: function() {

        },
        copyAnotherUserAttrFormBtnClick: function() {
            const self = this;
            this.$confirm.require({
                message: this.$i18n.tc("draft.copyAndOverWrite"),
                accept: function() {
                    self.$store.commit('editTemplate/copyFormTemplateByInactiveUserattrs')
                },
                reject: () => {
                }
            })
        },
        changedPreviewSwitch: function(value) {
            this.$emit("setScrollAmount", value)
        },
        changeActiveRequiredField: function(e, Id) {
            this.activeRequiredFieldIdList[Id] = e
        },
        changeScopeOfPublication: async function(e) {
            const id = this.$store.getters["getSelectRowId"]
            await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "ScopeOfPublication", value: e.value})
            this.$store.commit("editTemplate/setScopeOfPublication", e.value)
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
            this.$store.commit('editTemplate/setEdited', false)
        },

        onAppTypeCheckboxChange: function(e, IsActive, AppTypeValue, type) {
            store.commit('editTemplate/setAppType', {AppTypeValue: AppTypeValue, IsActive: IsActive})
            this.$store.commit('editTemplate/setEdited', true)
            if (IsActive) {
                type.IsEditActive = true
                this.onAppTypeInplaceClick(type)
            }
        },

        onEditAppTypeLabelBlur: function(AppTypeLabel, AppTypeValue, type) {
            store.commit('editTemplate/setAppTypeLabelAndValue', {AppTypeValue: AppTypeValue, AppTypeLabel: AppTypeLabel})
            type.IsEditActive = false
        },

        onEditAppTypeLabelInput: function() {
            this.$store.commit('editTemplate/setEdited', true)
        },

        onAppTypeInplaceClick: function(type) {
            const self = this
            this.$nextTick(function() {
                if(type.IsEditActive) {
                    self.$refs['appTypeInplace_'+type._id][0].$el.focus()
                }
            })
        },

        toChangeRegistrationInfoLinkTextClick: function() {
            this.$toast.add({severity: 'success', summary: this.$i18n.tc("draft.toChangeRegistrationInfoLinkTextClick"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
        },

        changeControlTextInPublic: async function(e) {
            const target = e.target
            const value = target.value
            const id = this.$store.getters["getSelectRowId"]
            await this.$store.dispatch('editTemplate/updateTemplateFormInfo', {Status: this.Status, id: id, key: "ControlText", value: value})
            this.$store.commit('editTemplate/setControlText', value)
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
            this.$store.commit('editTemplate/setEdited', false)
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
    opacity: 0.3;
    box-shadow: 2px 2px 4px 2px  rgba(0, 0, 0, .5);

}
</style>
