<template>
    <Panel v-if="item.ComponentType == 'Editor'" class="tempateFormEditorComponent " v-bind:class="{'noheader_panel': readOnly && !item.LabelTEXT,'hasheader_panel': !(readOnly && !item.LabelTEXT), 'add_plus_field': !readOnly, 'topComponentPanel': item.No == 1, 'tempateFormEditorComponentEdit': (!readOnly && !preview) }" :id="item.PanelId"  >
        <template #header>
            <template v-if="readOnly">
                <div class="p-d-flex  align-item-center" style="" >
                    <!-- <div class=" align-item-center panel-header-label" style="">  -->
                    <!-- {{ item.Label }} -->
                    <!-- </div> -->
                    <LabelEditor class="readonly_template_form_label" style="flex: 1" :content="item.Label" :disabled="true" />
                </div>
            </template>
            <template v-else>
                <div class="p-ai-center " style="width: 90%; display: flex;">
                    <!-- <Textarea class="p-d-flex p-ac-stretch p-fluid" style="width: auto; flex: 1;" rows="1" :autoResize="true" :placeholder="$t('xtype.blankText')" @input="inputHeader" v-model="item.Label" v-bind:class="{empty_field_panel_header: !item.Label}" :disabled="disabled"/> -->
                    <LabelEditor class="" style="flex: 1" :content="item.Label" :disabled="disabled" @onBlurContent="inputHeader($event)" :placeholder="$t('xtype.blankText')" />
                    <div class="text-center"  v-bind:class="{'handle': !disabled, 'disabled_icon': disabled}">
                        <i class="pi pi-sort-alt " style="fontSize: 1rem;" v-bind:class="{'handle': !disabled, 'disabled_icon': disabled}" v-tooltip="$t('xtype.sortIconTooltip')" ></i>
                    </div>
                </div>
                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text"  @click="removeComponentButtonClick" :disabled="disabled"  v-tooltip="$t('xtype.removeComponentButtonTooltip')" />
                <Button icon="pi pi-exclamation-circle" class="p-button-rounded p-button-secondary p-button-text"   :disabled="true"/>
            </template>
        </template>
        <div class="p-grid no_margin"  
            v-if="(!preview && !readOnly) || ((item.items[0].Xtype == 'fullEditor' && item.items[0].Placeholder) || (item.items[0].Xtype == 'fullEditor4Applicant' && item.items[0].Value)) && (preview || readOnly)" >
            <div class="p-col-12 no_padding">
                <slot></slot>
            </div>
        </div>

        <Button  v-if="!readOnly" icon="pi pi-arrow-up" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 128px; bottom: -16px;" :disabled="disabled" @click="upIconComponentButtonClick($event)"  aria-haspopup="true" v-tooltip="$t('xtype.upComponentButtonTooltip')"/>
        <Button  v-if="!readOnly" icon="pi pi-arrow-down" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 80px; bottom: -16px;" :disabled="disabled" @click="downIconComponentButtonClick($event)"  aria-haspopup="true" v-tooltip="$t('xtype.downComponentButtonTooltip')"/>
        <Button v-if="!readOnly" icon="pi pi-clone" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 32px; bottom: -16px;" :disabled="disabled" @click="copyIconComponentButtonClick($event)"  aria-haspopup="true" aria-controls="overlay_menu" v-tooltip="$t('xtype.copyButtonTooltip')" />
        <Menu  id="overlay_menu" ref="menu" :model="copyList" :popup="true" />

        <Button v-if="!readOnly" icon="pi pi-plus" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: -16px; bottom: -16px;" @click="addComponentButtonClick"  :disabled="disabled" v-tooltip="$t('xtype.addButtonTooltip')" />
    </Panel>
    <Panel v-else class="templateForm" v-bind:class="{'noheader_panel': readOnly && !item.LabelTEXT, 'hasheader_panel': !(readOnly && !item.LabelTEXT), 'add_plus_field': !readOnly, 'topComponentPanel': item.No == 1 }" style="position: relative;" :id="item.PanelId">
        <template #header>
            <template v-if="readOnly">
                <div class="p-d-flex  align-item-center" style="" >
                    <LabelEditor class="readonly_template_form_label" style="flex: 1" :content="item.Label" :disabled="true"/>
                <!--     <div class=" align-item-center panel-header-label" style="word-break: break-word; text-align: left;">  -->
                <!--     {{ item.Label }} -->
                <!-- </div> -->
            </div>
            </template>
            <template v-else>
                <div class="p-ai-center " style="width: 90%; display: flex;">
                    <!-- <Textarea class="p-d-flex p-ac-stretch p-fluid template_form_label_font" style="width: auto; flex: 1;" rows="1" :autoResize="true" :placeholder="$t('xtype.blankText')" @input="inputHeader" v-model="item.Label" v-bind:class="{empty_field_panel_header: !item.Label}"  :disabled="disabled"/> -->
                    <LabelEditor class="" style="flex: 1" :content="item.Label" :disabled="disabled" @onBlurContent="inputHeader($event)" :placeholder="$t('xtype.blankText')" />
                    <div class="text-center"  v-bind:class="{'handle': !disabled, 'disabled_icon': disabled}" >
                        <i class="pi pi-sort-alt " style="fontSize: 1rem;" v-bind:class="{'handle': !disabled, 'disabled_icon': disabled}" v-tooltip="$t('xtype.sortIconTooltip')"  ></i>
                    </div>
                </div>
                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text" @click="removeComponentButtonClick" :disabled="disabled" v-tooltip="$t('xtype.removeComponentButtonTooltip')"/>
                <Button v-if="!readOnly" icon="pi pi-exclamation-circle" class="p-button-rounded p-button-secondary p-button-text"   :disabled="true"/>
            </template>
        </template>
        <div class="p-grid no_margin">
            <div class="p-col-12 p-md-4 p-lg-4 no_margin align-item-center p-as-center" v-if="(!readOnly && !preview) || item.Label1TEXT" style="align-self: center;">
                <div class="p-grid  p-jc-between no_margin">
                    <div class="p-col p-grid no_margin">
                        <template v-if="readOnly">
                            <!-- <div class=" p-d-flex"> -->
                                <!-- <label class="itemLabel" v-bind:class="{empty_field: !item.Label1}" style="max-width: 100%; word-break:break-word;"> -->
                                <!--     <span > {{ item.Label1 }} </span> -->
                                <!-- </label> -->
                            <!-- </div> -->
                            <LabelEditor class="p-col-12 no_padding readonly_template_form_label1" style="flex: 1;" :content="item.Label1" :disabled="true"/>
                        </template>
                        <template v-else>
                            <!-- <Textarea class="p-col-12 template_form_label1_font" rows="1"  :autoResize="true" :placeholder="$t('xtype.item1Text')" @input="inputLabel" v-model="item.Label1"  v-bind:class="{empty_field: !item.Label1}"  :disabled="disabled"/> -->
                            <LabelEditor class="p-col-12 no_padding" :content="item.Label1" :disabled="disabled" @onBlurContent="inputLabel($event)" :placeholder="$t('xtype.item1Text')" />
                        </template>
                    </div>
                    <div class="p-col-fixed" style="width: 85px;align-self: center;">
                        <template v-if="readOnly">
                            <div class=" p-grid p-col " style="float: right; margin: 0 0 0 auto; padding-right: 0px;">
                                <template v-if="item.Required == 'true'">
                                    <span class="no_padding tag_option_required" >{{$t('inputForm.required')}}</span>
                                </template>
                                <template v-else-if="item.Required == 'false'">
                                    <span class=" no_padding tag_option_notrequired" >{{$t('inputForm.optional')}}</span>
                                </template>
                                <template v-else>
                                    <span class="" ></span>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class=" p-grid">
                                <div class="p-col-12 no_padding no_margin align-item-start p-grid" style="text-align: right">
                                    <div class=" no_padding " style="text-align: left; padding-top: 0.5rem !important; margin-bottom: 0.5rem;">
                                        <RadioButton value="true" v-model="item.Required" @change="changeAllowField" :disabled="disabled" />
                                        <label class="tag_option_required" :disabled="disabled">{{$t('inputForm.required')}}</label>
                                    </div>
                                    <div class="no_padding" style="text-align: left; margin-bottom: 0.5rem;">
                                        <RadioButton value="false" v-model="item.Required"  @change="changeAllowField" :disabled="disabled"/>
                                        <label class="tag_option_notrequired" :disabled="disabled">{{$t('inputForm.optional')}}</label>
                                    </div>
                                    <div class=" no_padding" style="text-align: left;">
                                        <RadioButton value="blank" v-model="item.Required"  @change="changeAllowField" :disabled="disabled"/>
                                        <label class="tag_option_notrequired" :disabled="disabled">{{$t('inputForm.blank')}}</label>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div style=""  
                class="p-col no_margin align-item-center p-grid no_padding_top no_padding_bottom"  
                v-bind:class="{'required_field': required == 'true' && item.items.findIndex(x => $constants.notFillRequireFieldArea.includes(x.Xtype)) == -1}">
                <div class="p-col p-grid no_margin">
                    <template v-if="readOnly">
                        <!-- <span class="gray-font p-col-12 no_padding_top no_padding_bottom template_form_label3_font"  v-bind:class="{empty_field: !item.Label2}" style="text-align: left; "> {{ item.Label2 }} </span> -->
                        <LabelEditor v-if="item.Label2TEXT" class="p-col-12 readonly_template_form_label2" :content="item.Label2" :disabled="true" @onBlurContent="inputLabel($event)"/>
                    </template>
                    <template v-else>
                        <!-- <Textarea class="p-col-12 no_padding template_form_label2_font" rows="2" style="margin-bottom: 6px;" :autoResize="true" :placeholder="$t('xtype.blankText')" @input="inputLabel2" v-model="item.Label2" v-bind:class="{empty_field: !item.Label2}" :disabled="disabled" /> -->
                        <LabelEditor class="p-col-12 no_padding_side " :content="item.Label2" :disabled="disabled" @onBlurContent="inputLabel2($event)" :placeholder="$t('xtype.blankText')" />
                    </template>

                    <slot></slot>

                    <template v-if="readOnly">
                        <!-- <span class="gray-font p-col-12 no_padding_top no_padding_bottom template_form_label3_font"  v-bind:class="{empty_field: !item.Label3}" style="text-align: left;" > {{ item.Label3 }} </span> -->
                        <LabelEditor v-if="item.Label3TEXT" class="p-col-12 readonly_template_form_label3" :content="item.Label3" :disabled="true" @onBlurContent="inputLabel($event)"/>
                    </template>
                    <template v-else>
                        <!-- <Textarea class="p-col-12 no_padding template_form_label3_font"  rows="2" style="margin-top: 6px;" :autoResize="true" :placeholder="$t('xtype.blankText')" @input="inputLabel3" v-model="item.Label3" v-bind:class="{empty_field: !item.Label3}" :disabled="disabled" /> -->
                        <LabelEditor class="p-col-12 no_padding_side" :content="item.Label3" :disabled="disabled" @onBlurContent="inputLabel3($event)" :placeholder="$t('xtype.blankText')" />
                    </template>
                </div>
            </div>
        </div>
        <i v-if="showComponentName" style="position: absolute; left: 4px; bottom: 4px; font-size: 10px;color: rgb(187, 187, 187);"> {{item.No+ " "  + $t("xtype." + item.ComponentName + ".title")}} </i>
        <!-- <i style="position: absolute; left: 4px; bottom: 4px; font-size: 10px;"> {{item.No}} </i> -->
        <Button  v-if="!readOnly" icon="pi pi-arrow-up" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 128px; bottom: -16px;" :disabled="disabled" @click="upIconComponentButtonClick($event)"  aria-haspopup="true" v-tooltip="$t('xtype.upComponentButtonTooltip')"/>
        <Button  v-if="!readOnly" icon="pi pi-arrow-down" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 80px; bottom: -16px;" :disabled="disabled" @click="downIconComponentButtonClick($event)"  aria-haspopup="true" v-tooltip="$t('xtype.downComponentButtonTooltip')"/>
         
        <Button  v-if="!readOnly" icon="pi pi-clone" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: 32px; bottom: -16px;" :disabled="disabled" @click="copyIconComponentButtonClick($event)"  aria-haspopup="true" aria-controls="overlay_menu" v-tooltip="$t('xtype.copyButtonTooltip')"/>
        <Menu  id="overlay_menu" ref="menu" :model="copyList" :popup="true" />

        <Button v-if="!readOnly" icon="pi pi-plus" class="p-button-rounded p-button-secondary p-button-outlined" style="position: absolute; right: -16px; bottom: -16px;" @click="addComponentButtonClick" :disabled="disabled" v-tooltip="$t('xtype.addButtonTooltip')" />
    </Panel>
</template>

<script>
import RadioButton from 'primevue/radiobutton';
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Menu from 'primevue/menu';
import Tooltip from 'primevue/tooltip'

import LabelEditor from '@/components/templateFormParts/LabelEditor';



export default {
    name: 'templatePanel',
    props: ['item', 'Id', 'required', 'readOnly', 'preview', 'disabled', 'showComponentName'],
    emits: ['setProp', 'addComponentButtonClick', 'removeComponentButtonClick', 'upIconComponentButtonClick', 'downIconComponentButtonClick', 'copyComponentButtonClick'],
    components: {
        RadioButton,
        Panel,
        Textarea,
        Button,
        Menu,
        LabelEditor,
    },
    data() {
        return {
            allowField: null,
            copyList: [
                {
                    label: this.$i18n.tc("inputForm.copy"),
                    command: (e) => {
                        this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
                        this.copyComponentButtonClick({copyToBottom: false})
                    }
                },
                {
                    label: this.$i18n.tc("inputForm.copyToBottom"),
                    command: (e) => {
                        this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
                        this.copyComponentButtonClick({copyToBottom: true})
                    }
                },
            ],
            label1Editor: null,
            label2Editor: null,
            label3Editor: null,
        }
    },
    created() {
    },
    computed: {
    },
    // watch: {
    //     item: {
    //         immediate: true,
    //         handler(newValue, oldValue) {
    //             this.label1Editor = new Editor({
    //                 extensions: [
    //                     StarterKit
    //                 ],
    //                 content: newValue.Label1
    //             })
    //             console.log(this.label1Editor)
    //         }
    //     },
    // },
    methods: {
        removeComponentButtonClick: function(e={copyToBottom: false}) {
            this.$emit('removeComponentButtonClick', e)
        },
        copyIconComponentButtonClick: function(e) {
            this.$refs.menu.toggle(e)
        },
        upIconComponentButtonClick: function(e) {
            this.$emit('upIconComponentButtonClick', e)
        },
        downIconComponentButtonClick: function(e) {
            this.$emit('downIconComponentButtonClick', e)
        },
        copyComponentButtonClick: function(e) {
            this.$emit('copyComponentButtonClick', e)
        },
        addComponentButtonClick: function(e) {
            this.$emit('addComponentButtonClick', e)
        },
        inputHeader: function({html, text}) {
            this.item.Label = html
            const data = {
                Id: this.Id,
                label: "Label",
                value: html
            }
            this.$emit('setProp', data)
            this.$emit('setProp', {
                Id: this.Id,
                label: "LabelTEXT",
                value: text
            })
        },
        inputLabel: function({html, text}) {
            this.item.Label1 = html
            const data = {
                Id: this.Id,
                label: "Label1",
                value: html
            }
            this.$emit('setProp', data)
            this.$emit('setProp', {
                Id: this.Id,
                label: "Label1TEXT",
                value: text
            })
        },
        inputLabel2: function({html, text}) {
            this.item.Label2 = html
            const data = {
                Id: this.Id,
                label: "Label2",
                value: html
            }
            this.$emit('setProp', data)
            this.$emit('setProp', {
                Id: this.Id,
                label: "Label2TEXT",
                value: text
            })
        },
        inputLabel3: function({html, text}) {
            this.item.Label3 = html
            const data = {
                Id: this.Id,
                label: "Label3",
                value: html
            }
            this.$emit('setProp', data)
            this.$emit('setProp', {
                Id: this.Id,
                label: "Label3TEXT",
                value: text
            })
        },
        changeAllowField: function(e) {
            const data = {
                Id: this.Id,
                label: "Required",
                value: this.item.Required
            }
            this.$emit('setProp', data)
        },
        drag: function(e) {
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.handle {
  width: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.handle:hover {
    cursor: grab;
}
</style>
