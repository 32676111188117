<template>
    <DataTable
            :scrollable="true"
            scrollHeight="flex"
            v-model:value="GridData" 
            v-model:selection="selectedData" 
            selectionMode="single" 
            dataKey="_id" 
            @row-select="selectedRow"
            :beforeRowClick="beforeRowClick"
            class="p-datatable-sm draftFormDataTable datatable-width-100"
            v-model:filters="filters1" 
            filterDisplay="menu"
            :lazy="true" :paginator="true"
            :rows="rows"
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
            :globalFilterFields="['DocumentName', 'DepartmentName', 'FromEmail', 'CompanyName', 'CreatedUserName', 'UpdatedDate', 'TemplateNote']" 
            :rowsPerPageOptions="$constants.rowsPerPageOptions"
            ref="draftTemplateformDataTableRef"
            removableSort
            :key="draftDataTableKey"
            :sortField="dynamicSortField"
            :sortOrder="dynamicSortOrder"
            >
                <template #empty>
                    {{$t('formDataTable.ForgetFilter')}}
                </template>
                <template #loading>
                    Please wait.
                </template>

            <Column filterField="DocumentName" bodyClass="text-ellipsis" :style="defaultMaxPixel" :showFilterMatchModes="false" headerStyle="flex: 2" style="flex: 2;" >
            <template #header="slotProps">
                {{$t('formDataTable.DocumentName')}} <span class='gridControlText'> ({{$t('formDataTable.ControlText')}}) </span>
            </template>
            <template #body="slotProps">
                <span class="text-ellipsis">
                    {{slotProps.data.DocumentName }}
                    <span class="gridControlText" v-if="slotProps.data.ControlText ">({{slotProps.data.ControlText }})</span>
                </span>
            </template>
            <template #filter="{filterModel}">
                <InputText  v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
            </template>
            <template #filterapply="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DocumentName', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
            </template>
            <template #filterclear="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DocumentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
            </template>
            </Column>
            <Column :showFilterMatchModes="false" :style="defaultMaxPixel"
                        :header="$t('formDataTable.ToDepartment')" 
                        filterField="DepartmentName">
            <template #body="slotProps">
                <span class="text-ellipsis underline"
                      @click.stop="linkToDocumentList($event, slotProps.data)"
                      >
                      {{
                      slotProps.data.DepartmentId
                      ? slotProps.data.DepartmentName
                      ? slotProps.data.DepartmentName
                      : $t('formDataTable.blankDepartmentName') 
                      : '' 
                      }}
                </span>
            </template>
            <template #filter="{filterModel}">
                <InputText  v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
            </template>
            <template #filterapply="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
            </template>
            <template #filterclear="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
            </template>
            </Column>
            <Column :header="$t('formDataTable.ToCompanyName')" filterField="CompanyName" :style="defaultMaxPixel"  :showFilterMatchModes="false">
            <template #body="slotProps">
                <span class="text-ellipsis underline"
                      @click.stop="linkToDocumentList($event, slotProps.data, true)"
                      >
                      {{
                      slotProps.data.CompanyId
                      ? slotProps.data.CompanyName
                      ? slotProps.data.CompanyName
                      : $t('formDataTable.blankCompanyName') 
                      : '' 
                      }}
                </span>
            </template>
            <template #filter="{filterModel}">
                <InputText  v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
            </template>
            <template #filterapply="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
            </template>
            <template #filterclear="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
            </template>
            </Column>
            <Column filterField="CreatedUserName" :style="creatorNameMaxPixel"  :showFilterMatchModes="false">
            <template #header>
                <div class="p-d-flex">
                    <i class="pi pi-info-circle" v-tooltip="$t('formDataTable.isCreatorName')"  />
                    <span class="p-ml-1" >{{$t('formDataTable.CreatorName')}}</span>
                </div>
            </template>

            <template #body="slotProps">
                <span class="text-ellipsis" >
                      {{
                      slotProps.data.DepartmentId 
                      ? slotProps.data.CreatedUserName 
                      ? slotProps.data.CreatedUserName 
                      : $t('formDataTable.blankCreatorName') 
                      : ''}}
                </span>
            </template>
            <template #filter="{filterModel}">
                <InputText  v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
            </template>
            <template #filterapply="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
            </template>
            <template #filterclear="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
            </template>
            </Column>
            <Column :headerStyle="datePixel" :style="dateMaxPixel" :header="$t('formDataTable.UpdatedDate')" dataType="date" filterField="UpdatedDate" :showFilterOperator=false  >
                <template #body="slotProps">
                    <span class="text-ellipsis">
                        {{$filters.formatDate(slotProps.data.UpdatedDate, null, "HH:mm:ss")}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <Calendar ref="filterInputRef"  v-model="filterModel.value" :dateFormat="dateFormat" :placeholder="dateFormat"  :class="{'p-invalid': filterModel.invalid}"/>
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>
            </Column>
            <Column  :header="$t('formDataTable.TemplateNote')" filterField="TemplateNote" sortField="TemplateNote" :sortable="true" class="" :showFilterMatchModes="false"  headerStyle="width: 120px;" style="max-width: 120px;" >
            <template #body="slotProps">
                <span class="text-ellipsis" v-tooltip="{ value: slotProps.data.TemplateNote}" > {{slotProps.data.TemplateNote}} </span>
            </template>
            <template #filter="{filterModel}">
                <InputText  v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
            </template>
            <template #filterapply="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('TemplateNote', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
            </template>
            <template #filterclear="{filterModel, filterCallback}">
                <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('TemplateNote', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
            </template>
            </Column>
        <template #paginatorend>
            <div class="page-bottom-form-info">
                {{$t("draft.header.createdUserEmail")}} <i v-tooltip="$t('draft.header.createdUserEmailTooltip')" class="pi pi-info-circle" />: {{CreatedUserEmail}}
                {{$t("draft.header.createdAndUpdatedDate")}}{{$filters.formatDate(UpdatedDate, null, "HH:mm:ss" )}}
                {{$t("draft.header.templateId")}} <i v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>: {{TemplateId}}
            </div>
        </template>
        <template #additionTable>
            <template v-if="GridData && GridData.length" >
                <Button class="p-button-text focus-box-shadow-none" @click="onNextPageButtonClick($event, 'draftTemplateformDataTableRef')" v-if="!isLastPage" > {{$t('utils.next')}} > </Button>
                <template v-else >
                    {{$t('formDataTable.rowEnd')}}
                </template>
            </template>
        </template>
    </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple';
import InputText from 'primevue/inputtext';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import isMobile from '@/mixins/device'


export default {
    name: 'FormDataTableDraft',
    props: ["GridData", "totalRecords", "Status"],
    emits: [ "selectedRow", "linkToDocumentList", "onPage", "onSort", "onFilter", "rows"],
    components: {
        DataTable,
        Column,
        Button,
        Calendar,
        InputText,
    },
    data: function() {
        return {
            isMobile,
            delay: 200,
            clicks: 0,
            timer: null,
            editForm: false,
            displayConfirmPopupDialog: false,
            filters1: null,
            defaultMaxPixel: "max-width: 400px",
            creatorNamePixel: "width: 100px",
            creatorNameMaxPixel: "max-width: 100px",
            datePixel: "width: 110px",
            dateMaxPixel: "max-width: 180px",
            loading: false,
            dynamicSortField: "",
            dynamicSortOrder: 0,
            isLastPage: false,
        }
    },
    watch: {
        GridData: function(value, oldValue)  {
            const self = this;
            this.$nextTick(function() {
                if(self.$refs && self.$refs['draftTemplateformDataTableRef'] && self.$refs['draftTemplateformDataTableRef'].$refs)
                    self.isLastPage = self.$refs['draftTemplateformDataTableRef'].$refs.dtPaginator.isLastPage
                else
                    self.isLastPage = false
            })
        },
    },
    created() {
        this.initFilters()
        this.initSorts()
    },
    computed: {
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return userInfo && userInfo.dateformat ? userInfo.dateformat.toLowerCase().replace("yyyy", "yy") : "yy/mm/dd";
            }
        },
        selectedData: {
            get: function() {
                const data = this.$store.getters["gridDataStore/getSelectedData"]
                return data
            },
        },
        draftDataTableKey: {
            get: function() {
                const keyCount = this.$store.getters["auth/getKeyCount"]
                return "draftDataTableKey" + "_" + keyCount
            }
        },
        CreatedUserEmail: {
            get: function() {
                return this.$store.getters["editTemplate/getCreatedUserEmail"]
            }
        },
        UpdatedDate: {
            get: function() {
                return this.$store.getters["editTemplate/getUpdatedDate"]
            }
        },
        TemplateId: {
            get: function() {
                return this.$store.getters["editTemplate/getTemplateId"]
            }
        },
    },

    methods: {

        filterOn: function() {
            const self = this
            this.$nextTick(function() {
                if(self.$refs.filterInputRef.$el.children && self.$refs.filterInputRef.$el.children.length)
                    self.$refs.filterInputRef.$el.children[0].focus()
                else
                    self.$refs.filterInputRef.$el.focus()
            })
        },

        initSorts() {
            this.dynamicSortField = ""
            this.dynamicSortOrder = 0
            const sortData = this.$store.getters["gridDataStore/getGridSortData"] || {}
            if(sortData["Template"] && sortData["Template"][this.Status]) {
                this.dynamicSortField = Object.keys(sortData["Template"][this.Status])[0];
                this.dynamicSortOrder = sortData["Template"][this.Status][this.dynamicSortField];
                return;
            }
        },

        initFilters() {
            const filterData = this.$store.getters["gridDataStore/getGridFilterData"] || {}
            if(filterData["Template"] && filterData["Template"][this.Status]) {
                this.filters1 = filterData["Template"][this.Status];
                return;
            }
            const DateFilterList = [
                {value: null, matchMode: FilterMatchMode.DATE_IS},
            ]
            // const DateFilter = {operator: FilterOperator.OR, constraints: DateFilterList};
            const DateFilter = {operator: FilterOperator.AND, constraints: DateFilterList};
            this.filters1 = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SortNumberByTemplate': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DocumentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DepartmentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FromEmail': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CompanyName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CreatedUserName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'UpdatedDate': DateFilter,
                'SendDate': DateFilter,
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'FormStatus': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SenderFormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                '_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateId': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },


        selectedRow(event) {
            this.$emit("selectedRow", event)
        },

        async linkToDocumentList(e, record, isCompany=false) {
            this.$emit("linkToDocumentList", {record, isCompany})
        },

        async beforeRowClick(e) {
            const edited = this.$store.getters["editTemplate/getEdited"]
            if(edited) {
                const self = this;
                const callback = new Promise(res => {
                    this.$confirm.require({
                        message: this.$i18n.tc("formDataTable.isNotSaveClickRow"),
                        acceptLabel: this.$i18n.tc("formDataTable.isNotSaveAccept"),
                        rejectLabel: this.$i18n.tc("formDataTable.isNotSaveBack"),
                        isFlexFooter: true,
                        accept: function() {
                            self.$store.commit("editTemplate/setEdited", false)
                            res(true)
                        },
                        reject: () => {
                            res(false)
                        }
                    })
                })

                return await callback
            } else {
                return true
            }
        },

        onFilter(e) {
            this.$emit("onFilter", e)
        },

        onSort(e) {
            this.$emit("onSort", e)
        },

        onPage(e) {
            this.$emit("onPage", e)
        },

        gridFilterCallback(type, filterModel, filterCallback) {
            if(filterModel.constraints) {
                let clear = true
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    if(!filterModel.constraints[i].value) {
                        filterModel.constraints[i].invalid = true
                        clear = false
                    } else {
                        filterModel.constraints[i].invalid = false
                    }
                }
                if(clear) {
                    filterCallback()
                }
            } else if(filterModel.value) {
                filterModel.invalid = false
                filterModel.target = true
                filterCallback()
            } else {
                filterModel.invalid = true
            }
        },

        gridFilterClearCallback(type, filterModel, filterCallback) { 
            if(filterModel.constraints) {
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    filterModel.constraints[i].invalid = false
                }
            } else {
                filterModel.invalid = false
            }
            filterModel.target = false
            filterCallback()
        },

        onNextPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToNext(event)
        },

        onPrevPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToPrev(event)
        }
    },
    directives: {
        'tooltip': Tooltip,
        'ripple': Ripple
    },
}
</script>

<style scoped>
</style>
