<template>
    <div class="p-field p-col no_margin no_padding">
        <template v-if="readOnly">
            <template v-for="(x, index) in item.LabelList">
                <div class="p-field-radiobutton p-col p-nogutter no_padding p-fluid " v-if="x.label" v-bind:class="{no_margin_bottom: index == (item.LabelList.length - 1)}">
                    <Checkbox :value="x.value" v-model="item.ValueList" :disabled="true"/>
                    <label> {{x.label}} </label>
                </div>
            </template>
            <div v-if="item.ValueList.find(x => x == 'other')" class="p-col-12 no_padding_side p-grid no_margin" >
                <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
                <InputText
                    v-bind:class="{'fill_textfield': item.LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield clear_disabled" type="text" v-model="item.LabelList.find(x => x.value == 'other').otherValue"   
                    disabled/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </template>
        <template v-else-if="preview">
            <template v-for="(x, index) in previewLabelList" >
                <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid " v-bind:class="{no_margin_bottom: index == (item.LabelList.length - 1)}" >
                    <Checkbox :value="x.value" v-model="previewValueList" @change="changePreviewValue"/>
                    <label> {{x.label}} </label>
                </div>
            </template>
            <div v-if="previewValueList.findIndex(x => x == 'other') != -1" class="p-col-12 no_padding_side p-grid" >
                <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
                <InputText
                    v-bind:class="{'fill_textfield': previewOtherValue }"
                    class="p-col textfield " type="text" v-model="previewOtherValue"   
                    autoFocus/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </template>
        <template v-else>
            <template v-for="(x, index) in item.LabelList" >
                <div class="p-field-radiobutton p-col p-nogutter no_padding p-fluid " v-bind:class="{no_margin_bottom: index == (item.LabelList.length - 1)}">
                    <Checkbox :disabled="true"/>
                    <InputText class="clear_disabled" v-model="x.label" @input="input" v-bind:class="{empty_field: !x.label}"  :disabled="disabled"/>
                </div>
            </template>
            <div class=" p-mt-1 p-ml-0 p-mr-0 p-col-12 no_padding p-grid align-item-center ">
                <Checkbox class="no_padding p-mr-1" v-model="item.AddOtherInSelectionField" :binary="true" @change="onAddOtherInSelectionFieldChange" /> <label> {{$t('xtype.checkBoxGroupField.otherLabel')}} </label>
                <div v-if="!item.AddOtherInSelectionField" class="p-col" />
                <InputText class="p-col" v-if="item.AddOtherInSelectionField" v-model="item.Placeholder" @input="input" :disabled="disabled" :placeholder="$t('xtype.checkBoxGroupField.otherInputPlaceholder')" />
            </div>
        </template>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox'



export default {
    name: 'checkBoxGroupField',
    props: ['item', 'Id', 'readOnly', 'preview', 'disabled'],
    emits: ["clearRequirePreviewField"],
    components: {
        InputText,
        Checkbox,
    },
    data() {
        return {
            previewValueList: [],
            previewOtherValue: "",
        }
    },
    created() {
        if(!this.item.Placeholder) {
            this.item.Placeholder = ""
        }
    },
    computed: {
        previewLabelList: {
            get() {
                const LabelList = this.item.LabelList
                console.log(LabelList.findIndex(x => x.value == "other"))
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.radioBoxGroupField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
    },
    watch: {
        'item.AddOtherInSelectionField': function(AddOtherInSelectionField) {
            if (AddOtherInSelectionField) {
                if (this.item.Placeholder == "") {
                    this.item.Placeholder = this.$i18n.tc('xtype.checkBoxGroupField.otherInputPlaceholder')
                }
            }
        }
    },
    methods: {
        input: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "checkBoxGroupField",
                ItemId: this.item.ItemId,
                LabelList: this.item.LabelList,
                Placeholder: this.item.Placeholder ? this.item.Placeholder : "",
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            console.log(String(Object.keys(this.previewValueList).length != 0))
            this.$emit("clearRequirePreviewField", String(Object.keys(this.previewValueList).length == 0))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
