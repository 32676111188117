<template>
    <Dialog v-model:visible="displayDialog"  class="mailDialog" contentStyle="position: relative;"  :style="{width: '40vw'}">
        <template #header>
            <span class="p-dialog-title">
                {{$t("messageAfterSendingFormDialog.title")}}
            </span>
        </template>
            <div class="p-grid">
                <div v-if="publicFormatType" class="p-col-12 p-nogutter " style="">
                    <InlineMessage style=" width: 100%; justify-content: flex-start; " severity="error">{{$t("messageAfterSendingFormDialog.notworkingByPublic")}}</InlineMessage>
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <span>
                        {{$t("messageAfterSendingFormDialog.info")}}
                    </span>
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <!-- <Textarea type="text" v-model="Message"  :autoResize="true" rows="5" :placeholder="$t('messageAfterSendingFormDialog.placeholder')" :disabled="!editable" /> -->
                    <FormMessageEditor class="formMessageEditorAfterMessageInfo" :content="Message" :placeholder="$t('messageAfterSendingFormDialog.placeholder')" @onBlurContent="Message=$event.html" :disabled="!editable" />
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <div class="p-col" />
                    <SelectButton v-model="isONMessage" :options="off_on" optionLabel="name" optionValue="value" :unselectable=false :disabled="!editable"  />
                </div>
            </div>
        <template #footer>
            <div class="p-d-flex">
                <div class="p-col" />
                <Button :label="$t('utils.close')" icon="pi pi-times" @click="onClose" class="p-button-text" />
                <Button :label="$t('utils.save')" @click="updateShowMessage" :disabled="!editable" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip'
import SelectButton from 'primevue/selectbutton';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import FormMessageEditor from '@/components/formMessageEditor';

export default {
    name: 'MessageAfterSendingFormDialog',
    props: ["displayDialog", "editable"],
    emits: ["closeDialog"],
    components: {
        Dialog,
        Button,
        Textarea,
        SelectButton,
        InlineMessage,
        Message,
        FormMessageEditor,
    },
    data: function() {
        return {
            off_on: [
                {
                    name: "OFF",
                    value: false,
                },
                {
                    name: "ON",
                    value: true,
                }
            ],
        }
    },
    created() {
    },
    computed: {
        publicFormatType: {
            get: function() {
                return this.$store.getters["urlStore/getPublicFormatType"] == "webpage"
            },
        },
        isONMessage: {
            get: function() {
                return this.$store.getters["messageAfterSendingFormStore/getIsONMessage"]
            },
            set: function(value) {
                return this.$store.commit("messageAfterSendingFormStore/setIsONMessage", value)
            },
        },
        Message: {
            get: function() {
                return this.$store.getters["messageAfterSendingFormStore/getMessage"]
            },
            set: function(value) {
                this.$store.commit("messageAfterSendingFormStore/setMessage", value)
            },
        }
    },
    watch: {
        displayDialog: function(value){
            if(!value) {
                this.$emit("closeDialog", false)
            }
        },
    },
    methods: {
        onClose() {
            this.$emit("closeDialog", false)
        },

        async updateMessage() {
            await this.$store.dispatch('messageAfterSendingFormStore/updateMessageAfterSendingForm', {})
        },

        async updateShowMessage() {
            await this.$store.dispatch('messageAfterSendingFormStore/updateMessageAfterSendingForm', {})
            this.onClose()
        },

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>

