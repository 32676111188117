<template>
    <Dialog :header="$t('EditURLDialog.header')" v-model:visible="displayEdittingURLDialog" class="editURLCardDialog" style="width: 60vw;" :modal=true  >
        <div class="p-grid no_margin">
            <div class="p-col-12 p-mt-0 p-inputgroup p-grid no_margin " style="text-align: left"  >
                <div class="editURLContainer">
                    <div class="editURLPreContainer">
                        <p  class="editURLP"> {{Domain + DepartmentUUID + "/"}} </p>
                    </div>
                    <input class="editURLInput" v-model="LinkUUID" ref="LinkUUIDInputRef" @focus="$event.target.select()"/>
                </div>
                <span class="p-col-12 p-pt-0 p-pl-0" style="font-size: small;">{{$t("EditURLDialog.urlEditInfo")}} </span>
            </div>
            <div class="p-col-12 p-mt-1 " style="text-align: left"  >
                <span style="word-break: break-word; white-space: pre-line;"> {{$t("EditURLDialog.bottomText")}} </span>
            </div>
        </div>
        <template #footer>
            <Button icon="pi pi-trash" style="" class="p-button-rounded p-button-danger p-button-text" @click="onUpdateURLButtonClick" > {{$t("EditURLDialog.Publish")}} </Button>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip';
import InputText from 'primevue/inputtext';

import http from '../mixins/api'

export default {
    name: 'EditURLDialog',
    props: ["isSkipAuth", "displayEdittingURLDialog"],
    emits: ['updateDisplayEdittingURLDialog'],
    components: {
        Dialog,
        Button,
        InputText,
    },
    watch: {
        displayEdittingURLDialog: function(value) {
            if(value) {
                this.setData()
            }
            this.$emit("updateDisplayEdittingURLDialog", value)
        }
    },
    data: function() {
        return {
            Domain: "",
            DepartmentUUID: "",
            LinkUUID: "",
        }
    },
    computed: {
    },
    methods: {
        async setData() {

            const templateId = this.$store.getters["urlStore/getTemplateId"]
            const res = await http.post("/form/GetFormURLData", {templateId: templateId, isSkipAuth: this.isSkipAuth})
            if(res.Success) {
                this.Domain = res.Domain
                this.DepartmentUUID = res.DepartmentUUID
                this.LinkUUID = res.LinkUUID
                const self = this;
                this.$nextTick(function() {
                    self.$refs.LinkUUIDInputRef.focus()
                })
            } else {
                this.$emit("updateDisplayEdittingURLDialog", false)
            }
        },

        async onUpdateURLButtonClick() {
            const templateId = this.$store.getters["urlStore/getTemplateId"]
            const res = await http.post("/form/CheckOverwriteFormURLByTemplateId", {templateId: templateId, isSkipAuth: this.isSkipAuth, DepartmentUUID: this.DepartmentUUID, LinkUUID: this.LinkUUID})
            if(res.Success) {
                this.onUpdateURL()
            } else {
                const self = this;
                this.$confirm.require({
                    group: "break",
                    defaultFocus: "reject",
                    message: self.$i18n.tc('EditURLDialog.confirmPopupText', {DOCUMENTNAME: res.DocumentName}),
                    acceptLabel: self.$i18n.tc('EditURLDialog.overwrite'),
                    rejectLabel: self.$i18n.tc('utils.cancel'),
                    accept: async function() {
                        self.onUpdateURL()
                    },
                    reject: () => {
                    }
                })
            }
        },


        async onUpdateURL() {
            const templateId = this.$store.getters["urlStore/getTemplateId"]
            const res = await http.post("/form/EditFormURLByTemplateId", {templateId: templateId, isSkipAuth: this.isSkipAuth, DepartmentUUID: this.DepartmentUUID, LinkUUID: this.LinkUUID})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.dispatch("urlStore/getFormURLs", {templateId: templateId})
                if(res.IsUpsert) {
                    this.$store.dispatch("gridDataStore/reloadGridDataList")
                }
                this.$emit("updateDisplayEdittingURLDialog", false)
            } else {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        }
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
.editURLContainer {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border: 1px solid #ced4da;
    width: 100%;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-left: 8px;
}
.editURLPreContainer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    opacity: 0.6;
}
.editURLP {
    margin-top: 6px;
    margin-bottom: 6px;

}
.editURLInput {
    letter-spacing: inherit;
    border: none;
    color: #495057;
    font-size: 14px;
    outline: none;
    box-sizing: content-box;
    background: none;
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 6px 14px 6px 0px;
}
</style>

