<template>
    <Dialog :header="$t('PassURLDialog.header')" v-model:visible="displayPassURLDialog"  style="width: 40vw;" :modal=true  >
        <div class="p-grid no_margin">
            URL: {{URL}}
        </div>
        <div style="height: 10px; width: 1px;" />
        <div class="p-grid no_margin">
            {{$t('PassURLDialog.info')}}
        </div>
        <br />
        <div class="p-grid no_padding no_margin">
            <div class="p-col p-grid no_padding no_margin"  style="border: 0.4px solid #cfcfcf; margin: -4px; " >
                <div class="p-col-12 no_margin p-pl-1 p-pr-1 p-pb-1" style="font-weight: bold; background: #f1f3f4; padding-top: 0.3rem;" > {{$t('PassURLDialog.passingTemplate')}}: </div>
                <div class="p-col-12" style="padding: 4px 4px 4px 4px !important;" > 
                    <span> {{passingTemplate.DocumentName ? passingTemplate.DocumentName : ""}} </span> <span class="gridControlText" v-if="passingTemplate.ControlText " >({{passingTemplate.ControlText }})</span>
                </div>
            </div>
            <div class="p-col-fixed no_padding text-center cursor_pointer " style="width: 40px; align-self: center;" ><span class="material-symbols-outlined fa-rotate-90 " @click="onSwapTemplateInfoButtonClick" > swap_vert </span> </div>
            <div class="p-col p-grid no_padding no_margin" style="border: 0.4px solid #cfcfcf; margin: -4px; " >
                <div class="p-col-12 no_margin p-pl-1 p-pr-1 p-pb-1" style="font-weight: bold;background: #f1f3f4; padding-top: 0.3rem;" > {{$t('PassURLDialog.passedTemplate')}}: </div>
                <div class="p-col-12 " style="padding: 0 4px 4px 4px !important;" > <span > {{passedTemplateDocumentName}} </span> <span class="gridControlText" v-if="passedControlText" >({{passedControlText}})</span></div>
            </div>
        </div>
        <br />
        <div class="p-grid no_margin" style="height: 400px;">
            <FormDataTableCreated 
                  v-model:selection="selectedData" 
                  :addRadioColumn="true"
                  selectionMode="single" 
                  :GridData="gridData" 
                  :showGlobalFilter="false"
                  :totalRecords="totalRecords" 
                  @row-select="selectedRow"
                  @linkToDocumentList="linkToDocumentList({}, $event.record, $event.isCompany)"  
                  @showFormMemberListDialogClick="showFormMemberListDialogClick($event.e, $event.record, $event.Type)"
                  @page="onPage($event)"
                  @sort="onSort($event)"
                  @filter="onFilter($event)"
                  :showColumn="['DocumentName', 'DepartmentName']"
              />
        </div>
        <template #footer>
            <Button icon="pi pi-trash" style="" v-bind:disabled="isProcessing()" class="p-button-rounded p-button-danger p-button-text" @click="onPublishButtonClick" >{{$t('PassURLDialog.PublishButtonText')}} </Button>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip';
import FormDataTableCreated from '@/components/FormDataTableRow/Created.vue'
import Processing from '@/mixins/processing'

import http from '../mixins/api'

export default {
    name: 'PassURLDialog',
    props: ["displayPassURLDialog", "URL"],
    mixins: [Processing],
    emits: ['updateDisplayPassURLDialog', 'reloadTable'],
    components: {
        Dialog,
        Button,
        FormDataTableCreated,
    },
    watch: {
        displayPassURLDialog: async function(value) {
            if(value) {
                this.gridData = []
                this.selectedData = {}
                this.passedData = {}
                this.passedTemplateDocumentName = ""
                this.passedControlText = ""
                this.passingTemplate = {}

                await this.setData()
                this.selectedRow({data: this.selectedData})
            }
            this.$emit("updateDisplayPassURLDialog", value)
        }
    },
    data: function() {
        return {
            gridData: [],
            selectedData: {},
            passedData: {},
            passedTemplateDocumentName: "",
            passedControlText: "",
            passingTemplate: {},
        }
    },
    computed: {
    },
    methods: {
        selectedRow(event) {
            this.passedData = event.data
            this.passedTemplateDocumentName = event.data.DocumentName
            this.passedControlText = event.data.ControlText

        },

        async setData() {
            const _id = this.$store.getters["getSelectRowId"]
            const publicGridDataList = this.$store.getters["gridDataStore/getPublicGridDataList"]
            const see = publicGridDataList.find(x => x._id == _id)
            const res = await this.$store.dispatch("editTemplate/getTemplateById", {id: _id})
            if(res.Success) {
                this.passingTemplate = res.Data
                await this.loadGrid()
            }
        },

        async loadGrid(page={}, filter={}, sort={}) {
            const res = await this.loadGridData(page, filter, sort);
            console.log(res)
            if(res.Success) {
                this.gridData = res.Data 
                this.totalRecords = res.TotalRecords
                this.selectedData = this.gridData.length ? this.gridData[0] : {}
                this.passedTemplateDocumentName = this.selectedData.DocumentName
                this.passedControlText = this.selectedData.ControlText
                
            }
        },

        async loadGridData(page={}, filter={}, sort={}) {
            const DepartmentId = this.passingTemplate.DepartmentId
            const res = await this.$store.dispatch("gridDataStore/getGridDataList", {
                "Status": this.$constants.Status.Public,
                "Type": "Template", 
                "DepartmentId": DepartmentId,
                page: page,
                filter: filter,
                sort: sort,
            })

            return res
        },

        async onPublishButtonClick() {
            const self = this;
            this.$confirm.require({
                group: "break2",
                acceptLabel: "OK",
                rejectLabel: this.$i18n.tc('utils.cancel'),
                message: self.$i18n.tc("PassURLDialog.beforePublishingConfirm"),
                accept: function() {
                    self.onPublish()
                },
                reject: () => {
                }
            })
        },

        async onPublish() {
            this.startProcessing()
            if(this.selectedData) {
                // const passingTemplateId = this.$store.getters["getSelectRowId"]
                const passingTemplateId = this.passingTemplate._id
                const passedTemplateId = this.selectedData._id
                const res = await http.post("/form/PassFormURLInTemplate", {"passingTemplateId": passingTemplateId, "passedTemplateId": passedTemplateId})
                if(res.Success) {
                    const me = this;
                    this.$confirm.require({
                        group: "break2",
                        isShowRejectButton: false,
                        acceptLabel: "OK",
                        message: me.$i18n.tc("PassURLDialog.SuccessPublish"),
                        accept: function() {
                        },
                        reject: () => {
                        }
                    })
                    const params = {
                        ...this.$route.params,
                    }
                    delete params.templateFormId
                    this.$router.push({name: this.$route.meta.name, params: params})
                    this.reloadTable()
                    this.$emit("updateDisplayPassURLDialog", false)
                } else {
                    this.$toast.add({severity:'error', summary: res.Message ? res.Message : "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
            }
            this.endProcessing()
        },

        reloadTable: function() {
            this.$store.commit('editTemplate/clear')
            this.$store.commit("gridDataStore/setEditableForm", false)
            this.$emit("reloadTable", true)
        },

        async onFilter(e) {
            await this.loadGrid(e.page, e.filters, {sortField: e.sortField, sortOrder: e.sortOrder})
            this.selectedRow({data: this.selectedData})
        },

        async onSort(e) {
            await this.loadGrid(e.page, e.filters, {sortField: e.sortField, sortOrder: e.sortOrder})
            this.selectedRow({data: this.selectedData})
        },

        async onPage(e) {
            await this.loadGrid(e.page, e.filters, {sortField: e.sortField, sortOrder: e.sortOrder})
            this.selectedRow({data: this.selectedData})
        },

        async onSwapTemplateInfoButtonClick() {
            const tmp = Object.assign({}, this.passedData)
            const res = await this.$store.dispatch("gridDataStore/getPageOfTemplateForm", {templateId: this.passingTemplate._id})
            if (res.Success) {
                const loadRes = await this.loadGridData(res.Page - 1)
                if(loadRes.Success) {
                    this.gridData = loadRes.Data 
                    this.totalRecords = loadRes.TotalRecords

                    this.selectedData = this.passingTemplate
                    this.selectedRow({data: this.selectedData})
                    this.passingTemplate = tmp
                }
            }
        }
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
</style>


