<template>
    <template v-if="readOnly">
        <span class=" p-col p-nogutter p-fluid p-float-label" >
            <!-- <label style="padding&#45;left: 0.5rem;">{{ item.Value }}</label> -->
            <Textarea class="  textfield clear_disabled p-filled"   type="text" v-model="item.Value" disabled autoResize rows="5" />
            <label style="margin-left: 0.5rem;top: 0.7rem; ">{{ item.Placeholder }}</label>
        </span>
    </template>
    <template v-else-if="preview">
        <span class="align-item-center p-col p-nogutter p-fluid p-float-label float-nowrap-label" >
            <Textarea class="  textfield" v-bind:class="{'required_field': required == 'true', 'p-filled': item.Value, 'fill_textfield': previewText.length != 0}"  type="text" v-model="previewText" @input="changePreviewValue"  autoResize rows="5" />
            <label style="margin-left: 0.5rem; margin-top: 0rem;">{{ item.Placeholder }}</label>
        </span>
    </template>
    <template v-else>
        <Textarea v-if="disabled" class="p-col p-nogutter  textfield" style="height: 103px;" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}"  disabled/>
        <Textarea v-else class="p-col p-nogutter  textfield" style="height: 103px;" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}" />
    </template>
</template>

<script>
import http from '../../mixins/api'
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { uuid } from "vue-uuid";
import store from '@/store'



export default {
    name: 'textAreaField',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: ["setDataInItem", "clearRequirePreviewField"],
    components: {
        InputText,
        Textarea,
    },
    data() {
        return {
            allowField: null,
            previewText: "",
        }
    },
    methods: {
        input: function() {
            const data = {
                Id: this.Id,
                Xtype: "textAreaField",
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewText)))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
