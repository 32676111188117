<template>
    <div>
        <button @click="submit" data-tid="elements.form.pay_button" v-bind:class="{'p-disabled': !activePaymentMethodId}" >{{subscriptioned ? 'Update' : 'Pay'}}</button>
        <RadioButton class="display_none" style="width: 50px;"  id="paymentMethod"  v-model="activePaymentMethodId" value="paymentMethod" />
        <label class=" cursor_pointer" for="paymentMethod" >
            <div class="cell stripeFormContainer" id="form-2"  v-bind:class="{'paymentMethodActiveBox': activePaymentMethodId == 'paymentMethod'}">
                <form>
                    <div data-locale-reversible>
                        <div class="row">
                            <div class="field">
                                <input id="stripeFormParts-address" data-tid="elements.form.address_placeholder" class="input empty" type="text" placeholder="185 Berry St" required="" autocomplete="address-line1">
                                <label for="stripeFormParts-address" data-tid="elements.form.address_label">Address</label>
                                <div class="baseline"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="field">
                                <input id="stripeFormParts-email" data-tid="elements.form.email_placeholder" class="input empty" type="text" placeholder="aaa@example.com" required="" autocomplete="email-line1" v-model="Email" >
                                <label for="stripeFormParts-email" data-tid="elements.form.address_label">Email</label>
                                <div class="baseline"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field">
                            <div id="stripeFormParts-card-number" class="input empty"></div>
                            <label for="stripeFormParts-card-number" data-tid="elements.form.card_number_label">Card number</label>
                            <div class="baseline"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field half-width">
                            <div id="stripeFormParts-card-expiry" class="input empty"></div>
                            <label for="stripeFormParts-card-expiry" data-tid="elements.form.card_expiry_label">Expiration</label>
                            <div class="baseline"></div>
                        </div>
                        <div class="field half-width">
                            <div id="stripeFormParts-card-cvc" class="input empty"></div>
                            <label for="stripeFormParts-card-cvc" data-tid="elements.form.card_cvc_label">CVC</label>
                            <div class="baseline"></div>
                        </div>
                    </div>
                </form>
            </div>
        </label>
        <PaymentMethodList :departmentId="departmentId" :activePaymentMethodId="activePaymentMethodId" @onActivePaymentMethodIdEmit="activePaymentMethodId=$event" />
  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import http from '../mixins/api';
import InputText from 'primevue/inputtext';
import PaymentMethodList from "@/components/plans/paymentMethodList"
import RadioButton from 'primevue/radiobutton';


export default {
    name: 'stripe',
    props: ["subscriptioned" ,"departmentId", "priceId", "Email", "departmentName"],
    emits: ['showSpinnerEmit'],
    components: {
        InputText,
        PaymentMethodList,
        RadioButton,
    },
    data() {
        return {
            publishableKey: "",
            // Email: "uotnnizum12@gmail.com",
            // Email: `uotnnizum12+${Math.floor(Math.random() * 1211)}@gmail.com`,
            stripe: null,
            card: null,
            cardNumber: null,
            activePaymentMethodId: "paymentMethod",
        };
    },
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
            this.stripe = await loadStripe(this.publishableKey);
            var elements = this.stripe.elements();
            var iconStyle = "solid"


            var elements = this.stripe.elements({
                fonts: [
                    {
                        cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
                    },
                ],
                // Stripe's examples are localized to specific languages, but if
                // you wish to have Elements automatically detect your user's locale,
                // use `locale: 'auto'` instead.
                // locale: window.__exampleLocale 
                locale: "locale" 
            });

            // Floating labels
            var inputs = document.querySelectorAll('.cell.stripeFormContainer .input');
            const self = this;
            Array.prototype.forEach.call(inputs, function(input) {
                input.addEventListener('focus', function() {
                    input.classList.add('focused');
                    self.activePaymentMethodId = "paymentMethod"
                });
                input.addEventListener('blur', function() {
                    input.classList.remove('focused');
                    self.activePaymentMethodId = "paymentMethod"
                });
                input.addEventListener('keyup', function() {
                    if (input.value.length === 0) {
                        input.classList.add('empty');
                    } else {
                        input.classList.remove('empty');
                    }
                    self.activePaymentMethodId = "paymentMethod"
                });
            });

            var elementStyles = {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: '#CFD7DF',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                },
                invalid: {
                    color: '#E25950',

                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            var elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            this.cardNumber = elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses,
            });
            this.cardNumber.mount('#stripeFormParts-card-number');

            var cardExpiry = elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardExpiry.mount('#stripeFormParts-card-expiry');

            var cardCvc = elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardCvc.mount('#stripeFormParts-card-cvc');
            // document.getElementById('stripeFormParts-card-cvc').addEventListener("click", function() {
            //     self.activePaymentMethodId = "paymentMethod"
            // })
        },

        async submit (e) {
            // You will be redirected to Stripe's secure checkout page
            e.preventDefault();

            this.$emit("showSpinnerEmit", true)

            const createCustomer = await http.post("/customer/CreateCustomer", {Email: this.Email, DepartmentId: this.departmentId})
            console.log(createCustomer)
            let paymentMethod = null
            if(createCustomer.Success) {
                paymentMethod = await this.createPaymentMethod({customerId: createCustomer.customer.id})
                let res = {}
                if(createCustomer.Update) {
                    res = await http.post("/customer/CreateSubscription", {PriceId: this.priceId, DepartmentId: this.departmentId, Email: this.Email, paymentMethod: paymentMethod})
                    // res = await http.post("/customer/UpdateSubscription", {PriceId: this.priceId, DepartmentId: this.departmentId, Email: this.Email, paymentMethod: paymentMethod})
                } else {
                    res = await http.post("/customer/CreateSubscription", {PriceId: this.priceId, DepartmentId: this.departmentId, Email: this.Email, paymentMethod: paymentMethod})
                }
                console.log(res)

                const self = this;

                if(res.Success) {
                    console.log(self.cardNumber)
                    this.stripe.confirmCardPayment(res.clientSecret,{
                        payment_method: {
                            // card: self.card,
                            card: self.cardNumber,
                            billing_details: {
                                email: this.Email,
                                name: this.departmentName,
                            },
                        }
                    }).then(function(result) {
                        console.log(result)
                        if (result.error) {
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = result.error.message;
                            self.$emit("showSpinnerEmit", false)
                            self.$toast.add({severity: 'error', summary: "error", life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        } else{
                            self.$emit("showSpinnerEmit", false)
                            self.$toast.add({severity: 'success', summary: "success", life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                            // self.stripeTokenHandler(result.token);
                        }
                    })
                } else {
                    this.$emit("showSpinnerEmit", false)
                    this.$toast.add({severity: 'error', summary: "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
            } else {
                this.$emit("showSpinnerEmit", false)
                this.$toast.add({severity: 'error', summary: "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },

        async stripeTokenHandler(token) {
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);
            const res = await http.post("/customer/UpgradeAccount", {token: token})

            // form.submit()
        },

        async createPaymentMethod({ customerId }) {
            // Set up payment method for recurring usage
            // let billingName = document.querySelector('#name').value;


            if(this.activePaymentMethodId == "paymentMethod") {
                const result = await this.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.cardNumber,
                    // billing_details: {
                    //     name: billingName,
                    // },
                })
                if(result){
                    console.log(result)
                    if (result.error) {
                        console.log(result.error)
                        // displayError(result.error);
                    }
                }
                return result.paymentMethod
            } else {
                return {id: this.activePaymentMethodId}
            }

        },
    },
};
</script>

<style scoped>
.stripeFormContainer {
  background-color: #fff;
}

.stripeFormContainer * {
  font-family: Source Code Pro, Consolas, Menlo, monospace;
  font-size: 16px;
  font-weight: 500;
}

.stripeFormContainer .row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.stripeFormContainer .field {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 10px;
}

.stripeFormContainer .field.half-width {
  width: 50%;
}

.stripeFormContainer .field.quarter-width {
  width: calc(25% - 10px);
}

.stripeFormContainer .baseline {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #cfd7df;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripeFormContainer label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  color: #cfd7df;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform-origin: 0 50%;
  cursor: text;
  pointer-events: none;
  transition-property: color, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripeFormContainer .input {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-bottom: 7px;
  color: #32325d;
  background-color: transparent;
}

.stripeFormContainer .input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripeFormContainer .input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripeFormContainer .input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripeFormContainer .input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.stripeFormContainer .input.focused,
.stripeFormContainer .input:not(.empty) {
  opacity: 1;
}

.stripeFormContainer .input.focused::-webkit-input-placeholder,
.stripeFormContainer .input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.stripeFormContainer .input.focused::-moz-placeholder,
.stripeFormContainer .input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.stripeFormContainer .input.focused:-ms-input-placeholder,
.stripeFormContainer .input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.stripeFormContainer .input.focused + label,
.stripeFormContainer .input:not(.empty) + label {
  color: #aab7c4;
  transform: scale(0.85) translateY(-25px);
  cursor: default;
}

.stripeFormContainer .input.focused + label {
  color: #24b47e;
}

.stripeFormContainer .input.invalid + label {
  color: #ffa27b;
}

.stripeFormContainer .input.focused + label + .baseline {
  background-color: #24b47e;
}

.stripeFormContainer .input.focused.invalid + label + .baseline {
  background-color: #e25950;
}

.stripeFormContainer input, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.stripeFormContainer input:-webkit-autofill {
  -webkit-text-fill-color: #e39f48;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
}

.stripeFormContainer .StripeElement--webkit-autofill {
  background: transparent !important;
}

.stripeFormContainer input, button {
  -webkit-animation: 1ms void-animation-out;
}

button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #24b47e;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.stripeFormContainer .error svg {
  margin-top: 0 !important;
}

.stripeFormContainer .error svg .base {
  fill: #e25950;
}

.stripeFormContainer .error svg .glyph {
  fill: #fff;
}

.stripeFormContainer .error .message {
  color: #e25950;
}

.stripeFormContainer .success .icon .border {
  stroke: #abe9d2;
}

.stripeFormContainer .success .icon .checkmark {
  stroke: #24b47e;
}

.stripeFormContainer .success .title {
  color: #32325d;
  font-size: 16px !important;
}

.stripeFormContainer .success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.stripeFormContainer .success .reset path {
  fill: #24b47e;
}

</style>

