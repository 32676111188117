<template>
    <template v-if="Type == 'Template' && ActiveStatus == 0">
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview}"></div>
        <div class="p-col-9 p-field p-grid p-nogutter p-p-2 p-mb-0" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}" style="" >
            <div class="p-col" style="text-align: left">
                <Button class="p-mr-2 p-button-outlined p-button-info" v-bind:disabled="isProcessing()" @click="garbageTemplateButtonClick" > {{$t("operationButtonBar.garbageBox")}} </Button>
                <Button class="p-button-outlined p-button-info" v-bind:disabled="isProcessing()" @click="copyTemplateButtonClick" > {{$t("operationButtonBar.copy")}} </Button>
            </div>
            <template v-if="isPreview">
                <div class="p-col text-center">
                    <label class="warning-color" > {{$t("draft.previewing")}} </label>
                </div>
            </template>
            <div class="p-col"  style="text-align: right">
                <Button class="p-button-outlined p-button-info" :label="$t('operationButtonBar.previewURL')" @click="onPreviewUrlButtonClick" icon="pi pi-info-circle" iconPos="right" v-tooltip='$t("operationButtonBar.previewURLInfo")' > </Button>
                <Button class=" p-ml-2" v-bind:disabled="isProcessing()"  @click="confirmTemplateButtonClick" >{{$t("operationButtonBar.confirm")}} </Button>
            </div>
        </div>
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview}"></div>
    </template>
    <template  v-else-if="Type == 'Template' && ActiveStatus == 1">
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}"></div>
        <InputText type="text" v-model="formUrl2" style="display:none" readOnly />  <!-- こいつがいるから，formUrlModel が動く -->
        <div class="p-col-9 p-field p-grid p-nogutter p-p-2 p-mb-0" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}" style="">
            <div class="p-col" style="padding: 0px; text-align: left;">
                <Button class="p-mr-2 p-button-outlined p-button-info" v-bind:disabled="isProcessing()" @click="garbageTemplateButtonClick" > {{$t("operationButtonBar.garbageBox")}} </Button>
                <Button class="p-mr-2 p-button-outlined p-button-info"  v-bind:disabled="isProcessing()" @click="copyTemplateButtonClick" > {{$t("operationButtonBar.copy")}} </Button>
                <Button class="p-button-warning"  @click="onPassURLButtonClickFromFormView" style="vertical-align: top;" > {{$t("operationButtonBar.replaceURL")}} </Button>
                <inviteUserDialog ref="inviteDialog" :displayProp="false" />

            </div>
            <div class="p-col" style="padding: 0px; text-align: center;">
                <Button icon="pi pi-share-alt" class="p-button-outlined p-button-info p-button-help p-mr-2"  @click="openInviteUserDialogClick" :disabled="!CanStampInEdit" :label="$t('operationButtonBar.invite')" />
                <Button class=" p-button-outlined p-button-help p-mr-2" @click="onStampFormTemplateButtonClick" :disabled="!CanStampInEdit || StampedInEdit" > {{$t("operationButtonBar.confirmedUserDisplayInCreated")}} </Button>
                <Button class=" p-button-outlined p-button-help" @click="onRemoveStampFormTemplateButtonClick" :disabled="!CanStampInEdit || !StampedInEdit" > {{$t("operationButtonBar.cancelConfirmed")}} </Button>
            </div>
            <div class="p-col p-grid p-nogutter">
                <div class="p-col" style="padding: 0px; text-align: right;">
                    <Button icon="pi pi-key" class="p-button-rounded p-button-info p-button-outlined p-mr-1"  @click="onEditFormPasswordDialogButtonClick" v-tooltip="$t('operationButtonBar.addPasswordTooltip')" style="height: 2rem; width: 2rem;" />
                    <EditFormPasswordDialog :displayEditFormPasswordDialog="displayEditFormPasswordDialog" @updateEditFormPasswordDialog="updateEditFormPasswordDialog($event)" :editable="true" />
                    <Button icon="pi pi-at" class="p-button-rounded p-button-info p-button-outlined p-mr-1" @click="getMessageBeforeShowFormDialog"  v-tooltip="$t('operationButtonBar.messageBeforeShowFormDialog')" style="height: 2rem; width: 2rem;"/>
                    <Button icon="pi pi-comment" class="p-button-rounded p-button-info p-button-outlined p-mr-1" @click="getMessageAfterSendingDialog"  v-tooltip="$t('operationButtonBar.messageAfterFormSubmission')" style="height: 2rem; width: 2rem;"/>
                    <Button icon="pi pi-bell" class="p-button-rounded p-button-info p-button-outlined p-mr-1" @click="displayFormNotificationDialog=true"  v-tooltip="$t('operationButtonBar.notifications')" style="height: 2rem; width: 2rem;" />
                    <Button class=""  @click="createFormUrlButtonClick" style="vertical-align: top;" > {{$t("operationButtonBar.getURL")}} </Button>

                    <FormNotificationDialog  :displayFormNotificationDialog="displayFormNotificationDialog"  @updateDisplayFormNotificationDialog="updateDisplayFormNotificationDialog" />
                    <MessageAfterSendingDialog  :displayDialog="displayMessageAfterSendingDialog"  @closeDialog="updateMessageAfterSendingDialog" :editable="true" />
                    <MessageBeforeShowFormDialog  :displayDialog="displayMessageBeforeShowFormDialog"  @closeDialog="updateMessageBeforeShowFormDialog" :editable="true" />
                    

                </div>
            </div>
        </div>
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}"></div>
    </template>
    <template  v-else-if="Type == 'Template' && ActiveStatus == 2">
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}"></div>
        <InputText type="text" v-model="formUrl2" style="display:none" readOnly />  <!-- こいつがいるから，formUrlModel が動く -->
        <div class="p-col-9 p-field p-grid p-nogutter p-p-2 p-mb-0" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}" style="">
            <div class="p-col" style="padding: 0px; text-align: left;">
                <Button class="p-mr-2 p-button-outlined p-button-info"  @click="copyTemplateButtonClick" > {{$t("operationButtonBar.copy")}} </Button>
            </div>
            <div class="p-col" style="padding: 0px; text-align: right;">
                <Button icon="pi pi-key" class="p-button-rounded p-button-info p-button-outlined p-mr-1"  @click="onEditFormPasswordDialogButtonClick"  style="height: 2rem; width: 2rem;" />
                <EditFormPasswordDialog :displayEditFormPasswordDialog="displayEditFormPasswordDialog" @updateEditFormPasswordDialog="updateEditFormPasswordDialog($event)" :editable="false" />
                <Button icon="pi pi-at" class="p-button-rounded p-button-info p-button-outlined p-mr-1" @click="getMessageBeforeShowFormDialog"  v-tooltip="$t('operationButtonBar.messageBeforeShowFormDialog')" style="height: 2rem; width: 2rem;"/>
                <MessageBeforeShowFormDialog  :displayDialog="displayMessageBeforeShowFormDialog"  @closeDialog="updateMessageBeforeShowFormDialog" :editable="true" />
                <Button icon="pi pi-comment" class="p-button-rounded p-button-info p-button-outlined p-mr-1" @click="getMessageAfterSendingDialog"  v-tooltip="$t('operationButtonBar.messageAfterFormSubmission')" style="height: 2rem; width: 2rem;"/>
                <MessageAfterSendingDialog  :displayDialog="displayMessageAfterSendingDialog"  @closeDialog="updateMessageAfterSendingDialog" :editable="false" />
                <Button class="p-button-outlined p-button-info"  @click="createFormUrlButtonClick" style="vertical-align: top;" > {{$t("operationButtonBar.getURL")}} </Button>
            </div>
        </div>
        <div class="p-col" v-bind:class="{onlyEditingTemplateFormView: !isPreview, 'p-disabled': !canOPE}"></div>
    </template>
    <template v-else-if="Type == 'Form'">
        <!-- <Button class="p-button-outlined p-button-info"  v-if="isMobile.isMobile()" @click=""> {{$t("operationButtonBar.ope")}} </Button> -->
        <div class="p-col" :class="{'p-disabled': !canOPE}"></div>
        <div class="p-col-9 p-field p-grid p-nogutter p-p-2 p-mb-0" :class="{'p-disabled': !canOPE}" style="">
            <div class="p-col " style="text-align: left">
                <Button class="p-button-outlined p-button-secondary" v-bind:disabled="isProcessing()" v-if="isAcceptedStatus && visibleRejectBtn" @click="onRejectFormButtonClick"> {{$t("operationButtonBar.toReject")}} </Button>
                <Button class="p-button-outlined " v-bind:disabled="isProcessing()" v-if="!visibleRejectBtn && visibleAcceptBtn" @click="onAcceptFormButtonClick"> {{$t("operationButtonBar.toAccept")}} </Button>
            </div>
            <div class="p-col" style=" padding: 0px; text-align: center;">
                <Button
                    v-show="isShowFormStampButton"
                    class=" p-button-outlined p-button-help p-mr-2" 
                    @click="onStampFormButtonClick" 
                    :disabled="!CanStampInView || StampedInView" > 
                    {{$t("operationButtonBar.confirmedUserDisplayInReceived")}} 
                </Button>
                <Button v-show="isShowFormRemoveStampButton"
                    class=" p-button-outlined p-button-help" 
                    @click="onRemoveStampFormButtonClick" 
                    :disabled="!CanStampInView || !StampedInView" > 
                    {{$t("operationButtonBar.cancelConfirmedInReceived")}} 
                </Button>
            </div>
            <div class="p-col" style=" text-align: right" :class="{'flex-basis-auto': visibleDoneRequestMailBtn}" >
                <div style="">
                    <template v-if="visibleAcceptMailBtn">
                        <Button class="p-button-outlined p-button-info"   @click="sendAcceptedMailBtnClick"> 
                            <SentPaperIcon />
                            {{$t("operationButtonBar.sendAcceptedEmail")}}
                        </Button>
                        <i v-bind:class="{opacityIcon: !isSendAcceptedMail}" class="pi pi-check-circle p-mr-2 p-ml-2" style="fontSize: 1.2rem"></i>
                    </template>
                    <template v-if="visibleRejectMailBtn">
                        <Button class="p-button-outlined p-button-info"  @click="sendRejectedMailBtnClick">
                            <RejectPaperIcon />
                            {{$t("operationButtonBar.sendRejectedEmail")}} 
                        </Button>
                        <i v-bind:class="{opacityIcon: !isSendRejectedMail}" class="pi pi-check-circle p-mr-2 p-ml-2" style="fontSize: 1.2rem"></i>
                    </template>
                    <template v-if="visibleDoneRequestMailBtn">
                        <Button class="p-button-outlined p-button-info"  v-if="visibleDoneRequestMailBtn" @click="sendDoneRequiestMailBtnClick"> 
                            <DonePaperIcon />
                            {{$t("operationButtonBar.sendDonedEmail")}} 
                        </Button>
                        <i v-bind:class="{opacityIcon: !isSendDoneMail}" class="pi pi-check-circle p-mr-2 p-ml-2" style="fontSize: 1.2rem"></i>
                    </template>
                    <Button class="p-button-outlined p-button-info p-mr-2" v-bind:disabled="isProcessing()" v-if="visibleMoveDetailStatusButton" @click="onMoveDetailStatusButtonClick"> {{$t('operationButtonBar.moveDetailStatusForm')}} </Button>
                    <Button class="p-button-outlined p-button-info" v-bind:disabled="isProcessing()" v-if="visibleDeleteBtn" @click="onDeleteButtonClick"> {{$t("operationButtonBar.toDeleted")}} </Button>
                    <Button class="" v-bind:disabled="isProcessing()" v-if="visibleDoneRequestBtn" @click="onDoneRequestButtonClick"> {{$t("operationButtonBar.toDone")}} </Button>
                    <Button class="p-button-secondary p-mr-2 " v-bind:disabled="isProcessing()" v-if="isReceivedStatus && visibleRejectBtn" @click="onRejectFormButtonClick"> {{$t("operationButtonBar.reject")}} </Button>
                    <Button class="" v-bind:disabled="isProcessing()" v-if="visibleRejectBtn && visibleAcceptBtn" @click="onAcceptFormButtonClick"> {{$t("operationButtonBar.accept")}} </Button>
                </div>
            </div>
            <SelectMovedDetailFormStatusDialog @updateSelectMovedDetailFormStatusDialog="updateSelectMovedDetailFormStatusDialog($event)" :displaySelectMovedDetailFormStatusDialog="displaySelectMovedDetailFormStatusDialog" 
            :targetRouteId="NextRouteDetailFormStatusId"
            :targetRouteCode="NextRouteDetailFormStatusCode"
            :nextStatusAction="NextStatusAction"
            :isBulk="false"
            />
        </div>
        <div class="p-col" :class="{'p-disabled': !canOPE}"></div>
        <mailDialog :displayDialog="showMailDialog" @closeDialog="closeMailDialog($event)" :beforeSendView=true :OPE="OPE" />
    </template>
    <Dialog :header="$t('operationButtonBar.getURL')" v-model:visible="displayUrlDialog" style="width: 640px;" :modal=true >
        <div class="p-grid">
            <div class="p-col-12 " style="text-align: left" v-if="ActiveStatus == 2">
                <span>
                    <s>{{$t("operationButtonBar.garbageTemplateLinkText")}}</s>
                </span>
            </div>
            <div class="p-col-12 word-break-pre" style="text-align: left;" v-else-if="!isPreviewUrl">
                <span> {{$t("operationButtonBar.toTemplateListText")}} </span>
            </div>
            <div class="p-col-12 " style="text-align: left" v-else>
                <span> {{$t("operationButtonBar.toPreviewWindowLinkText")}} </span>
            </div>
            
            <div class="p-col-12 " style="text-align: left" v-if="ActiveStatus == 2">
                <span > {{$t("operationButtonBar.garbagedFormURLTextInfo")}} </span>
            </div>
            <div class="p-col-12 p-pt-1 p-pb-0 p-inputgroup p-grid" style="text-align: left"  >
                <span class="p-input-icon-right p-col p-pb-0 " style="align-self: center;">
                    <i class="pi pi-spin pi-spinner" v-show="ShortInputTextDisabled2" />
                    <InputText type="text" class="width-100" v-model="formUrlModel2" ref="formUrlInputText2" readOnly @click="onFormUrlInputTextClick2"  :disabled="ShortInputTextDisabled2"/>
                    <InputText type="text" v-model="formUrl2" style="display: none;" readOnly />  <!-- こいつがいるから，formUrlModel が動く -->
                </span>
                <VueQrcode v-if="formUrlModel2" :value="formUrlModel2" tag="img" class="cursor_pointer papererQrcode" :options="qrOption" ref="qrcode" @click="onQrcodeClick" v-tooltip="$t('operationButtonBar.qrcodeTooltip')"  />
            </div>

            <div class="p-col-12 p-nogutter p-field-checkbox no_margin p-jc-between" style="padding-top: 0px; padding-bottom: 0px; margin-top: -10px !important;"  v-if="!isPreviewUrl">
                <div class="cursor_pointer">
                    <Checkbox id="shortEmailAuth"  v-model="shortEmailAuth"  :binary=true />  
                    <label for="shortEmailAuth" class=" p-ml-1" >{{$t("operationButtonBar.shortUrl")}}</label>
                </div>
            </div>

            <div class="p-col-12 p-mt-0 p-grid  p-jc-between" style="padding-top: 0px;" >
                <div class="p-col-12 p-nogutter p-grid p-jc-between" style="padding-top: 0px;" v-if="!isPreviewUrl" > </div>
                <div class="p-d-flex p-nogutter width-100">
                    <Button 
                       v-if="(!isPreviewUrl && !shortEmailAuth && ActiveStatus == 1)" 
                       icon="pi pi-pencil"
                       class="p-button-secondary p-button-outlined p-mr-3"
                       iconPos="right"
                       @click="onEditURLButtonClick" 
                       :label="$t('operationButtonBar.overwriteURL')"
                       >
                    </Button>
                    <EditURLDialog :isSkipAuth="!addEmailAuth" :displayEdittingURLDialog="displayEdittingURLDialog" @updateDisplayEdittingURLDialog="displayEdittingURLDialog=$event" />
                    <PassURLDialog :displayPassURLDialog="displayPassURLDialog" :URL="formUrlModel2" @updateDisplayPassURLDialog="displayPassURLDialog=$event" @reloadTable="reloadTable" />
                    <div style="flex: 1" />
                    <Button class="p-mr-1 p-button-outlined p-button-info" @click="displayUrlDialog=false">{{$t("utils.cancel")}}</Button>
                    <Button class="p-button-outlined p-button-info" @click="copyUrlButtonClick">{{$t("operationButtonBar.copy")}}</Button>
                </div>
            </div>
        </div>
    </Dialog>
    <PassURLDialog :displayPassURLDialog="displayPassURLDialog" :URL="formUrlModel2" @updateDisplayPassURLDialog="displayPassURLDialog=$event" @reloadTable="reloadTable" />
    <Dialog  v-model:visible="displayConfirmDialog" style="width: 480px;" :modal=true >
        <div class="p-grid">
            <div class="p-col-12 " style="text-align: left">
                <span >
                    {{$t("draft.overWriteConfirm")}}
                    </span>
            </div>
            <div class="p-col-12 p-mt-1 p-grid  text-right" style="justify-content:flex-end;">
                <div>
                    <Button class="p-mr-1 p-button-outlined p-button-info" @click="displayConfirmDialog=false">{{$t("utils.No")}}</Button>
                    <Button class="p-button-info" v-bind:disabled="isProcessing()" @click="confirmTemplate">{{$t("utils.Yes")}}</Button>
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import http from '../mixins/api'
import inviteUserDialog from '@/components/templateFormParts/inviteUserDialog';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import mailDialog from '@/components/mailDialog'
import helper from '@/mixins/helper';

import SentPaperIcon from "@/components/Icons/sentPaperIcon";
import RejectPaperIcon from "@/components/Icons/rejectPaperIcon";
import DonePaperIcon from "@/components/Icons/donePaperIcon";

import UpgradeButton from "@/components/upgradeButton"
import Checkbox from "primevue/checkbox";
import Stripe from '@/components/stripe'
import EditURLDialog from "@/components/EditURLDialog";
import PassURLDialog from "@/components/PassURLDialog";
import FormNotificationDialog from "@/components/FormNotificationDialog";
import SelectMovedDetailFormStatusDialog from "@/components/SelectMovedDetailFormStatusDialog";
import isMobile from '@/mixins/device'
import MessageAfterSendingDialog from "@/components/MessageAfterSendingDialog";
import MessageBeforeShowFormDialog from "@/components/MessageBeforeShowFormDialog";
import Processing from '@/mixins/processing'
import Tooltip from 'primevue/tooltip'
import EditFormPasswordDialog from "@/components/EditFormPasswordDialog";
import VueQrcode from "@chenfengyuan/vue-qrcode";



export default {
    name: 'operationButtonBar',
    props: ['Type', 'canOPE'],
    mixins: [Processing],
    emits: ['previewMode', 'reloadTable'],
    components: {
        Button,
        inviteUserDialog,
        Dialog,
        InputSwitch,
        InputText,
        mailDialog,
        SentPaperIcon,
        RejectPaperIcon,
        DonePaperIcon,
        Checkbox,
        Stripe,
        EditURLDialog,
        PassURLDialog,
        FormNotificationDialog,
        SelectMovedDetailFormStatusDialog,
        UpgradeButton,
        MessageAfterSendingDialog,
        MessageBeforeShowFormDialog,
        EditFormPasswordDialog,
        VueQrcode,
    },
    data: function() { 
        return {
            displayUrlDialog: false,
            showMailDialog: false,
            displayEdittingURLDialog: false,
            displayPassURLDialog: false,
            shortEmailAuth: false,
            displayConfirmDialog: false,
            // ShortInputTextDisabled: false,
            OPE: "",
            isPreviewUrl: false,
            displayFormNotificationDialog: false,
            displayMessageBeforeShowFormDialog: false,
            displayMessageAfterSendingDialog: false,
            displayEditFormPasswordDialog: false,
            mailFormId: "",
            displaySelectMovedDetailFormStatusDialog: false,
            NextRouteDetailFormStatusId: "",
            NextRouteDetailFormStatusCode: "",
            NextStatusAction: "",
            isMobile,
            qrOption: {
                width: 70,
            }
        }
    },
    created() {
    },
    computed: {
        disabledSkipEmailAuth: {
            get: function() {
                const ValidList = this.$store.getters["planedStore/getValidList"]
                const valid = ValidList.includes(this.$constants.ValidFunctionList.EmailFormValid)
                if(!valid) {
                    this.addEmailAuth = false
                }
                return !valid
            },
        },

        isPreview: {
            get: function() {
                return this.$store.getters["editTemplate/getIsPreview"]
            },
        },
        CanStampInEdit: {
            get: function() {
                return this.$store.getters["editTemplate/getCanStamp"]
            }
        },
        StampedInEdit: {
            get: function() {
                return this.$store.getters["editTemplate/getStamped"]
            }
        },
        CanStampInView: {
            get: function() {
                return this.$store.getters["viewPostedForm/getCanStamp"]
            }
        },
        StampedInView: {
            get: function() {
                return this.$store.getters["viewPostedForm/getStamped"]
            }
        },
        isSendAcceptedMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendAcceptedMail"]
            },
        },
        isSendRejectedMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendRejectedMail"]
            },
        },
        isSendDoneMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendDoneMail"]
            },
        },
        visibleAcceptBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject || Status == this.$constants.FormStatus.Received
            }
        },
        isAcceptedStatus: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept
            }
        },
        isReceivedStatus: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Received
            }
        },
        visibleRejectBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Received
            }
        },

        visibleAcceptMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Storage
            }
        },

        visibleRejectMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject || Status == this.$constants.FormStatus.Deleted
            }
        },

        visibleDoneRequestMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Storage
            }
        },

        visibleDoneRequestBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept
            }
        },

        visibleDeleteBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject
            }
        },

        visibleMoveDetailStatusButton: {
            get: function() {
                return this.$route.params.departmentId != this.$constants.AllDepartmentIdConstants
            }
        },

        FormStatus: {
            get: function() {
                return this.$store.getters["viewPostedForm/getFormStatus"]
            },
        },

        Status: {
            get: function() {
                return this.$store.getters["editTemplate/getStatus"]
            },
        },

        ActiveStatus: {
            get: function() {
                const activeStatusInfo = this.$store.getters["gridDataStore/getActiveStatus"]
                if(activeStatusInfo) {
                    return activeStatusInfo.Status
                }
            },
        },

        formUrlModel: {
            get: function() {
                const self = this
                setTimeout(function() {
                    if(self.$refs.formUrlInputText)
                        self.$refs.formUrlInputText.$el.select()
                }, 100)
                return this.$store.getters["urlStore/getFormUrl"]
            }
        },

        ShortInputTextDisabled2: {
            get: function() {
                return this.$store.getters["urlStore/ShortInputTextDisabled2"]
            }
        },

        formUrlModel2: {
            get: function() {
                if(this.isPreviewUrl) {
                    return this.$constants.local + "/previewWindow?id=" + this.$store.getters["getSelectRowId"]
                } else  {
                    const URLs = this.$store.getters["urlStore/getFormURLs"]
                    if(URLs) {
                        return this.shortEmailAuth ? URLs.shortForm : URLs.form
                    }

                    return ""
                }
            },
        },

        formUrl2: {
            get: function() {
                const templateId = this.$store.getters["getSelectRowId"];
                this.$store.dispatch("urlStore/getFormURLs", {templateId: templateId})
            },
        },


        addEmailAuth: {
            get: function() {
                return !this.$store.getters["urlStore/getIsSkipEmailAuth"]
            },
            set: async function(value) {
                const oldValue = this.addEmailAuth
                const templateId = this.$store.getters["getSelectRowId"];
                const res = await this.$store.dispatch("urlStore/setIsSkipEmailAuth", {templateId: templateId, skipEmailAuth: !value})
                if(res.Success) {
                    // this.$store.commit("setIsSkipEmailAuth", !value)
                } else {
                    this.$store.commit("urlStore/setIsSkipEmailAuth", !oldValue)
                }
            }

        },


        ShortInputTextDisabled: {
            get: function() {
                return this.$store.getters["urlStore/getShortInputTextDisabled"]
            },
        },

        isShowFormRemoveStampButton: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Received || Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Reject
            },
        },

        isShowFormStampButton: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Received || Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Reject
            },
        },
    },

    watch: {
    },

    methods: {
        confirmTemplateButtonClick: async function() {
            const isInitTemplate = this.$store.getters['editTemplate/isInitTemplate']
            if(isInitTemplate) {
                const self = this;
                this.$confirm.require({
                    defaultFocus: "reject",
                    message: self.$i18n.tc('operationButtonBar.cautionConfirmTemplateForm'),
                    accept: async function() {
                        await self.checkUpdateTemplate()
                        // self.confirmTemplate()
                    },
                    reject: () => {
                    }
                })
            } else {
                await this.checkUpdateTemplate()
                // this.confirmTemplate()
            }
        },

        checkUpdateTemplate: async function() {
            this.$store.commit('setShowGlobalAdminSpinner', true)
            const res = await this.$store.dispatch('editTemplate/checkUpdateTemplateForm')
            if(res.Success) {
                if(res.IsCurrentVersion) {
                    await this.confirmTemplate()
                } else {
                    this.displayConfirmDialog = true
                    this.$store.commit('setShowGlobalAdminSpinner', false)
                }
            } else {
                this.$toast.add({severity:'error', summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('setShowGlobalAdminSpinner', false)
            }
        },


        confirmTemplate: async function() {
            this.$store.commit('setShowGlobalAdminSpinner', true)
            this.displayConfirmDialog = false
            this.startProcessing()
            const res = await this.$store.dispatch('editTemplate/confirmTemplateForm')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("draft.confirmed"), detail: this.$i18n.tc("draft.confirmed_detail"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('editTemplate/clear')
                this.$store.commit("gridDataStore/setEditableForm", false)
                this.$store.dispatch('gridDataStore/reloadGridDataList')
                this.$confirm.require({
                    message: this.$i18n.tc('operationButtonBar.cautionConfirmTemplateForm2'),
                    group: "break2",
                    isShowRejectButton: false,
                    acceptLabel: this.$i18n.tc('utils.ok'),
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else if(res.Success === false) {
                this.$store.commit('editTemplate/invalidViewMode', true)
                this.$confirm.require({
                    message: res.Message ? res.Message : this.$i18n.tc("draft.requiredFieldError"),
                    group: "break2",
                    isShowRejectButton: false,
                    acceptLabel: "OK",
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            }
            this.endProcessing()
            this.$store.commit('setShowGlobalAdminSpinner', false)
        },

        copyTemplateButtonClick: async function() {
            this.startProcessing()
            const res = await this.$store.dispatch('editTemplate/copyTemplateForm', {activeDepartmentId: this.$route.params.departmentId})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("draft.copied"), life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
                // this.$store.commit('editTemplate/clear')
                await this.$store.dispatch('gridDataStore/reloadGridDataList')
            }
            this.endProcessing()
        },

        garbageTemplateButtonClick: async function() {
            const self = this;
            this.$confirm.require({
                defaultFocus: "reject",
                message: this.$i18n.tc('operationButtonBar.moveToGarbege'),
                accept: function() {
                    self.garbageTemplate()
                },
                reject: () => {
                }
            })
        },

        checkConfirmRequire: async function(message) {
            const self = this
            return new Promise(async function(resolve, reject) {
                self.$confirm.require({
                    message: message,
                    accept: function() {
                        resolve(true)
                    },
                    reject: () => {
                        resolve(false)
                    },
                })
            })
        },

        garbageTemplate: async function() {
            this.startProcessing()
            const res = await this.$store.dispatch('editTemplate/garbageTemplateForm')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("draft.garbage"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('editTemplate/clear')
                this.$store.commit("gridDataStore/setEditableForm", false)
                await this.$store.dispatch('gridDataStore/reloadGridDataList')
            }
            this.endProcessing()
        },

        async createFormUrlButtonClick() {
            this.isPreviewUrl = false
            this.displayUrlDialog = true
        },

        copyUrlButtonClick: function() {
            const formUrl = this.formUrlModel2
            helper.copyTextToClipboard(formUrl)
            this.$refs.formUrlInputText2.$el.select()
            this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.copied"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            this.displayUrlDialog = false
        },
        onStampFormTemplateButtonClick: async function() {
            const res = await this.$store.dispatch('editTemplate/stampFormTemplate')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.confirmed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else if(res.AlreadyStamped) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.alreadyConfirmed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.notSharedAndCantConfirm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
        },

        onRemoveStampFormTemplateButtonClick: async function() {
            const res = await this.$store.dispatch('editTemplate/removeStampFormTemplate')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.cancellation"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else if(res.NotStamped) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.alreadyCancellation"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary:this.$i18n.tc("postedFormScreen.notSharedAndCantConfirm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
        },

        async onAcceptFormButtonClick(e) {
            // this.startProcessing()
            // const id = this.$store.getters['getSelectRowId']
            // const res = await this.$store.dispatch("viewPostedForm/AcceptForm", id)
            // if(res.Success) {
            //     this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.AcceptForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            // } else {
            //     this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedMoveStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            // }
            // this.endProcessing()
            // await this.clearAndReload()

            this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.AcceptBox
            this.NextStatusAction = "Accept"
            this.onMoveDetailStatus()

        },

        async onAcceptForm(detailFormStatusId) {
            this.startProcessing()
            const id = this.$store.getters['getSelectRowId']
            const res = await this.$store.dispatch("viewPostedForm/AcceptForm", {id, detailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.AcceptForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedMoveStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
            await this.clearAndReload()
        },

        async onRejectFormButtonClick(e) {
            this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.RejectedBox
            this.NextStatusAction = "Reject"
            this.onMoveDetailStatus()

        },

        async onRejectForm(detailFormStatusId) {
            const id = this.$store.getters['getSelectRowId']
            this.startProcessing()
            const res = await this.$store.dispatch("viewPostedForm/RejectForm", {id, detailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.RejectForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedMoveStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
            await this.clearAndReload()

        },

        async onDoneRequestButtonClick(e) {
            if (await this.checkConfirmRequire(this.$i18n.tc("postedFormScreen.BeforeDoneForm"))) {
                this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.StorageBox
                this.NextStatusAction = "Storage"
                this.onMoveDetailStatus()
            }
        },

        async onDoneRequestForm(detailFormStatusId) {
            const id = this.$store.getters['getSelectRowId']
            this.startProcessing()
            const res = await this.$store.dispatch("viewPostedForm/DoneForm", {id, detailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.DoneForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedMoveStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
            await this.clearAndReload()
        },
        async onDeleteButtonClick(e) {
            if (await this.checkConfirmRequire(this.$i18n.tc("postedFormScreen.BeforeDeleteForm"))) {
                this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.Deleted
                this.NextStatusAction = "Deleted"
                this.onMoveDetailStatus()
            }
        },
        async onDeleteForm(detailFormStatusId) {
            const id = this.$store.getters['getSelectRowId']
            this.startProcessing()
            const res = await this.$store.dispatch("viewPostedForm/DeleteForm", {id, detailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.DeleteForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedMoveStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
            await this.clearAndReload()
        },
        async clearAndReload() {
            this.$store.commit('viewPostedForm/clear')
            this.$store.commit("gridDataStore/setEditableForm", false)
            if(isMobile.isMobile()) {
                this.$router.push({name: this.$route.meta.name})
            }
            await this.$store.dispatch('gridDataStore/reloadGridDataList')
        },

        async sendAcceptedMailBtnClick(e) {
            const isSent = this.$store.getters["viewPostedForm/getSendAcceptedMail"]
            const self = this
            const id = this.$store.getters['getSelectRowId']
            if(isSent) {
                this.$confirm.require({
                    defaultFocus: "reject",
                    message: this.$i18n.tc('operationButtonBar.alreadySent_once'),
                    accept: function() {
                        // self.sendAcceptedMail()
                        self.onShowMailDialog("Accept", id)
                    },
                    reject: () => {
                    }
                })
            } else {
                // self.sendAcceptedMail()
                self.onShowMailDialog("Accept", id)
            }
        },

        async sendAcceptedMail(params) {
            const id = this.mailFormId
            const subjectText = params.subjectText
            const defaultText = params.defaultText
            const CCGroupUserIds = params.CCGroupUserIds
            const BCCGroupUserIds = params.BCCGroupUserIds
            const FromCompanyName = params.FromCompanyName
            const ReplyToAddress = params.ReplyToAddress
            const res = await http.post("/form/SendFormStatusMail", {formId: id, OPE: "Accept", SubjectText: subjectText, DefaultText: defaultText, CCGroupUserIds: CCGroupUserIds, BCCGroupUserIds: BCCGroupUserIds, FromCompanyName: FromCompanyName, ReplyToAddress: ReplyToAddress })
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.sendMailSuccessPostStore"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('viewPostedForm/setSendAcceptedMail', true)
                this.showMailDialog = false
            } else if(res.ConfirmMessage) {
                this.$confirm.require({
                    group: "break",
                    isShowRejectButton: false,
                    header: this.$i18n.tc("utils.sendError"),
                    message: res.ConfirmMessage,
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.sendMailFailed"), detail: this.$i18n.tc("utils.sendMailFailedByForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            }
            await this.$store.dispatch('gridDataStore/updateFormRow', {id: id})
        },

        async sendRejectedMailBtnClick(e) {
            const isSent = this.$store.getters["viewPostedForm/getSendRejectedMail"]
            const self = this
            const id = this.$store.getters['getSelectRowId']
            if(isSent) {
                this.$confirm.require({
                    message: this.$i18n.tc('operationButtonBar.alreadySent_once'),
                    defaultFocus: "reject",
                    accept: function() {
                        self.onShowMailDialog("Reject", id)
                    },
                    reject: () => {
                    }
                })
            } else {
                self.onShowMailDialog("Reject", id)
            }

        },


        async onShowMailDialog(OPE, id) {
            this.mailFormId = id
            // const id = this.$store.getters['getSelectRowId']
            const res = await this.$store.dispatch("sentMailStore/getMailTemplateByFormId", {FormId: id, OPE: OPE})
            if(res.Success) {
                this.OPE = OPE
                this.showMailDialog = true
            } else {
                this.$toast.add({
                    severity:'error', 
                    summary: this.$i18n.tc("utils.sendMailFailed"),
                    detail: this.$i18n.tc("utils.sendMailFailedByForm"), 
                    life: this.$constants.successHelperLife,
                    group: this.$constants.defaultToastGroup
                });
            }
        },

        async sendRejectedMail(params) {
            const isSent = this.$store.getters["viewPostedForm/getSendRejectedMail"]
            const id = this.mailFormId
            const subjectText = params.subjectText
            const defaultText = params.defaultText
            const CCGroupUserIds = params.CCGroupUserIds
            const BCCGroupUserIds = params.BCCGroupUserIds
            const FromCompanyName = params.FromCompanyName
            const ReplyToAddress = params.ReplyToAddress
            const res = await http.post("/form/SendFormStatusMail", {formId: id, OPE: "Reject", SubjectText: subjectText, DefaultText: defaultText, CCGroupUserIds: CCGroupUserIds, BCCGroupUserIds: BCCGroupUserIds, FromCompanyName: FromCompanyName, ReplyToAddress: ReplyToAddress  })
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.sendMailSuccessPostStore"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('viewPostedForm/setSendRejectedMail', true)
                this.showMailDialog = false
            } else if(res.ConfirmMessage) {
                this.$confirm.require({
                    group: "break",
                    isShowRejectButton: false,
                    header: this.$i18n.tc("utils.sendError"),
                    message: res.ConfirmMessage,
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.sendMailFailed"), detail: this.$i18n.tc("utils.sendMailFailedByForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            }
            await this.$store.dispatch('gridDataStore/updateFormRow', {id: id})
        },

        async sendDoneRequiestMailBtnClick(e) {
            const isSent = this.$store.getters["viewPostedForm/getSendDoneMail"]
            const self = this
            const id = this.$store.getters['getSelectRowId']
            if(isSent) {
                this.$confirm.require({
                    message: this.$i18n.tc('operationButtonBar.alreadySent_once'),
                    defaultFocus: "reject",
                    accept: function () {
                        // self.sendDoneRequiestMail()
                        self.onShowMailDialog("Done", id)
                    },
                    reject: () => {
                    }
                })
            } else {
                // self.sendDoneRequiestMail()
                self.onShowMailDialog("Done", id)
            }
        },

        async sendDoneRequiestMail(params) {
            const id = this.mailFormId
            const subjectText = params.subjectText
            const defaultText = params.defaultText
            const CCGroupUserIds = params.CCGroupUserIds
            const BCCGroupUserIds = params.BCCGroupUserIds
            const FromCompanyName = params.FromCompanyName
            const ReplyToAddress = params.ReplyToAddress
            const res = await http.post("/form/SendFormStatusMail", {formId: id, OPE: "Done", SubjectText: subjectText, DefaultText: defaultText, CCGroupUserIds: CCGroupUserIds, BCCGroupUserIds: BCCGroupUserIds, FromCompanyName: FromCompanyName, ReplyToAddress: ReplyToAddress  })
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.sendMailSuccessPostStore"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit('viewPostedForm/setSendDoneMail', true)
                this.showMailDialog = false
            } else if(res.ConfirmMessage) {
                this.$confirm.require({
                    group: "break",
                    isShowRejectButton: false,
                    header: this.$i18n.tc("utils.sendError"),
                    message: res.ConfirmMessage,
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.sendMailFailed"), detail: this.$i18n.tc("utils.sendMailFailedByForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            }
            await this.$store.dispatch('gridDataStore/updateFormRow', {id: id})
        },

        async onStampFormButtonClick() {
            const res = await this.$store.dispatch('viewPostedForm/stampForm')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.confirmed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            const id = this.$store.getters['viewPostedForm/getFormId']
            await this.$store.dispatch('gridDataStore/updateFormRow', {id: id})
        },

        async onRemoveStampFormButtonClick() {
            const res = await this.$store.dispatch('viewPostedForm/removeStampForm')
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.cancellation"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            const id = this.$store.getters['viewPostedForm/getFormId']
            await this.$store.dispatch('gridDataStore/updateFormRow', {id: id})
        },

        openInviteUserDialogClick: function(event) {
            this.$refs.inviteDialog.toggle(event);
        },

        async closeMailDialog(e={}) {
            if(e.sent) {
                // const id = this.$store.getters['getSelectRowId']
                // const res = await this.$store.dispatch("sentMailStore/setMailTemplateTextByFormId", {FormId: id, OPE: this.OPE})
                const subjectText = this.$store.getters['sentMailStore/getSubject']
                const defaultText = this.$store.getters['sentMailStore/getDefaultText']
                const CCGroupUserIds = this.$store.getters["sentMailStore/getCCGroupUserIds"]
                const BCCGroupUserIds = this.$store.getters["sentMailStore/getBCCGroupUserIds"]
                const FromCompanyName = this.$store.getters["sentMailStore/getFromCompanyName"]
                const ReplyToAddress = this.$store.getters["sentMailStore/getReplyToAddress"]
                const params = {
                    subjectText,
                    defaultText,
                    CCGroupUserIds,
                    BCCGroupUserIds,
                    FromCompanyName,
                    ReplyToAddress,
                }

                // if(res.Success) {
                    if(this.OPE == "Accept") {
                        this.sendAcceptedMail(params)
                    } else if(this.OPE == "Reject") {
                        this.sendRejectedMail(params)
                    } else if(this.OPE == "Done") {
                        this.sendDoneRequiestMail(params)
                    }
                // }
            } else {
                this.showMailDialog = false
            }
        },

        onFormUrlInputTextClick() {
            this.$refs.formUrlInputText2.$el.select()
        },

        onFormUrlInputTextClick2() {
            this.$refs.formUrlInputText2.$el.select()
        },

        async onPreviewUrlButtonClick() {
            this.isPreviewUrl = true
            this.displayUrlDialog = true
            const res = await this.$store.dispatch('editTemplate/updateTemplateForm')
            if(!res.Success) {
                this.$toast.add({severity: 'error', summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),  life: this.$constants.errorHelperLife, group: this.$constants.defaultToastGroup});
            }

        },

        onEditURLButtonClick() {
            if(!(this.isPreviewUrl && this.shortEmailAuth)) {
                this.displayEdittingURLDialog = !this.displayEdittingURLDialog
            }
        },

        toUpgradeButtonClick: function() {
            const DepartmentList = this.$store.getters["userBranchInfo/getBelongDepartmentList"]
            if(DepartmentList.length) {
                const departmentId = DepartmentList[0]._id
                this.$router.push({ path: `/plans/info/${departmentId}`});
            }
            this.displayDialog = false
        },
        onPassURLButtonClick() {
            if(!(this.isPreviewUrl && this.shortEmailAuth)) {
                this.displayPassURLDialog = !this.displayPassURLDialog
            }
        },

        onPassURLButtonClickFromFormView() {
            if(!this.isPreviewUrl) {
                this.displayPassURLDialog = !this.displayPassURLDialog
            }
        },

        async updateDisplayFormNotificationDialog(event) {
            this.displayFormNotificationDialog=event
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
        },

        async getMessageBeforeShowFormDialog () {
            const id = this.$store.getters['editTemplate/getTemplateId']
            this.$store.commit('formTemplateShowMessageBeforeStore/setTemplateId', id)
            await this.$store.dispatch('formTemplateShowMessageBeforeStore/getFormTemplateShowBefore', {})
            this.displayMessageBeforeShowFormDialog = true
        },

        async getMessageAfterSendingDialog() {
            const id = this.$store.getters['editTemplate/getTemplateId']
            this.$store.commit('messageAfterSendingFormStore/setTemplateId', id)
            await this.$store.dispatch('messageAfterSendingFormStore/getMessageAfterSendingForm', {})
            this.displayMessageAfterSendingDialog = true
        },

        async updateMessageAfterSendingDialog(event) {
            this.displayMessageAfterSendingDialog = event
            await this.$store.commit('messageAfterSendingFormStore/clear')
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
            // await this.$store.dispatch('messageAfterSendingFormStore/updateMessageAfterSendingForm', {})
        },

        async onEditFormPasswordDialogButtonClick() {
            this.displayEditFormPasswordDialog = true
        },

        async updateEditFormPasswordDialog(value) {
            this.displayEditFormPasswordDialog = value
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
        },

        reloadTable: function() {
            this.$emit("reloadTable", true)
        },

        async onMoveDetailStatusButtonClick() {
            const routeList = this.$store.getters["routeStore/getRouteList"]
            const activeRouteCode = this.$store.getters["routeStore/getActiveRouteCode"]
            const route = routeList.find(x => x.index == activeRouteCode)
            await this.$store.dispatch("detailFormStatus/setDetailFormStatusList", {DepartmentId: this.$route.params.departmentId, route: route})
            const detailFormStatusList = this.$store.getters["detailFormStatus/getDetailFormStatusList"].filter(x => x.IsVisible)
            if(detailFormStatusList.length == 0) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("operationButtonBar.cantMoveDetailStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});

                return ;
            }

            this.onMoveDetailStatus()

        },

        onMoveDetailStatus() {
            this.displaySelectMovedDetailFormStatusDialog = true

        },

        updateSelectMovedDetailFormStatusDialog(e) {
            this.displaySelectMovedDetailFormStatusDialog = false
            if(e !== false) {
                this.moveDetailStatus({DetailFormStatusId: e})
            } else {
                this.NextStatusAction = ""
                this.NextRouteDetailFormStatusId = ""
                this.NextRouteDetailFormStatusCode = ""
            }
        },

        async moveDetailStatus(params) {
            const DetailFormStatusId = params.DetailFormStatusId
            this.$store.commit('detailFormStatus/setLastOperationDetailFormIdInfo', {departmentId: this.$route.params.departmentId, detailFormStatusId: DetailFormStatusId, "status": this.NextStatusAction})
            if(this.NextStatusAction == "Accept") {
                await this.onAcceptForm(DetailFormStatusId)
            } else if(this.NextStatusAction == "Reject") {
                await this.onRejectForm(DetailFormStatusId)
            } else if(this.NextStatusAction == "Storage") {
                await this.onDoneRequestForm(DetailFormStatusId)
            } else if(this.NextStatusAction == "Deleted") {
                await this.onDeleteForm(DetailFormStatusId)
            } else {
                const res = await this.$store.dispatch('viewPostedForm/moveDetailStatusForm', {DetailFormStatusId: DetailFormStatusId})
                if(res.Success) {
                    this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
                this.$emit("reloadTable", true)
            }
            this.NextStatusAction = ""
            this.NextRouteDetailFormStatusId = ""
            this.NextRouteDetailFormStatusCode = ""
        },

        onQrcodeClick: function() {
            const src = this.$refs.qrcode.$el.src
            helper.copyImage64ToClipboard(src)
            this.$toast.add({severity:'success', summary: this.$i18n.tc("operationButtonBar.copiedQrcode"), life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
            this.displayUrlDialog = false
        },

        updateMessageBeforeShowFormDialog: async function() {
            this.displayMessageBeforeShowFormDialog = false;
            const id = this.$store.getters['editTemplate/getTemplateId']
            await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: id})
        },
    },

    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
