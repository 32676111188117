<template>
    <Dialog :header='$t("formNotificationDialog.title")' v-model:visible="displayFormNotificationDialog" style="width: 720px;" :modal=true :closable="false" >
        <div class="p-grid">
            <div v-if="publicFormatType" class="p-col-12 p-nogutter " style="">
                <InlineMessage style=" width: 100%; justify-content: flex-start; " severity="error">{{$t("formNotificationDialog.notworkingByPublic")}}</InlineMessage>
            </div>
            <div class="p-col-12 " style="text-align: left; white-space: pre-line;">
                <span> {{$t("formNotificationDialog.emailFormText")}} 
                    <i class="pi pi-info-circle" v-tooltip="$t('formNotificationDialog.emailFormTextInfo')"  />
                </span>
            </div>

            <div class="p-col-12 text-right" style="">
                <Button class="p-button-info p-button-text" @click="onAddMemberButtonClick">{{$t("formNotificationDialog.addMember")}}</Button>
            </div>

            <div class="p-col-12 no_padding no_margin" >
                <template v-for="groupUser in groupUserList">
                    <div class="p-col-12  no_margin p-field-checkbox" style="text-align: left; padding-left: 0px;">
                        <Checkbox :id="groupUser.WorkUserId" class="" v-model="checkedGroupUserIdList" :value="groupUser.WorkUserId" style="align-self: self-start;" />
                        <label :for="groupUser.WorkUserId" class="text-ellipsis" style="line-height: 16px;" v-tooltip="groupUser.Name +  '\n' + groupUser.Email " >
                            <b>{{groupUser.Name ? groupUser.Name : $t("formNotificationDialog.blankCreatorName")}}</b>
                            <br />
                            {{groupUser.Email}}
                        </label>
                    </div>
                </template>
            </div>
            <br />
            <span style="height: 10px; width: 100%; border-bottom: solid 1px rgba(100, 121, 143, 0.12)" ></span>
            <div class="p-col-12 " style="text-align: left">
                <span> {{$t("formNotificationDialog.createWebhook")}} </span>
            </div>
            <template v-for="hook in webHookList">
                <div class="p-col-12  p-grid no_margin" style="text-align: left">
                    <InputText class="p-col-12" type="text"  v-model="hook.WebhookURL" placeHolder="URL" />
                </div>
            </template>
        </div>
        <template #footer>
            <div class="p-col-12 p-mt-1 " style="">
                <div class="text-right">
                    <Button class="p-mr-2 p-button-outlined p-button-info" @click="onCloseDialog">{{$t("formNotificationDialog.cancel")}}</Button>
                    <Button class="p-button-info" @click="submit" style="text-transform: capitalize;">{{$t("utils.save")}}</Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
// import ScrollPanel from 'primevue/scrollpanel';
import Tooltip from 'primevue/tooltip'
import InlineMessage from 'primevue/inlinemessage';
import http from '../mixins/api'

export default {
    name: 'FormNotificationDialog',
    props: ['displayFormNotificationDialog'],
    emits: ['updateDisplayFormNotificationDialog'],
    components: {
        Dialog,
        ProgressSpinner,
        Button,
        InputText,
        Checkbox,
        InlineMessage,
        // ScrollPanel,
    },
    data: function() {
        return {
            groupUserList: [{
                Email: "",
                Name: ""
            }],
            webHookList: [{
                WebhookURL: ""
            }],
            checkedGroupUserIdList: [],
        }
    },
    created() {
    },
    computed: {
        publicFormatType: {
            get: function() {
                return this.$store.getters["urlStore/getPublicFormatType"] == "webpage"
            },
        },
    },
    watch: {
        displayFormNotificationDialog: function(value) {
            if(value) {
                this.setData()
            }
            this.$emit("updateDisplayFormNotificationDialog", value)
        }
    },
    methods: {
        async setData() {
            const templateId = this.$store.getters["getSelectRowId"]
            const res = await http.post("/form/GetFormNotifications", {templateId: templateId})
            if(res.Success) {
                this.checkedGroupUserIdList = res.EmailFormNotificationList.map(x => x.WorkUserId)
                this.groupUserList = res.GroupUsers
                // this.groupUserList.push({
                //     WorkUserId: "bb",
                //     Name: "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb",
                //     Email: "jjfosajf;sdjaf;japsoidfjpaosijdfjfosajf;sdjaf;japsoidfjpaosijdfjfosajf;sdjaf;japsoidfjpaosijdfjfosajf;sdjaf;japsoidfjpaosijdffosajf;sdjaf;japsoidfjpaosijdf"
                // })
                if(res.WebhookFormNotificationList.length)
                    this.webHookList = res.WebhookFormNotificationList
            }
        },

        onCloseDialog() {
            this.$emit("updateDisplayFormNotificationDialog", false)
        },
        
        async submit() {
            const templateId = this.$store.getters["getSelectRowId"]
            const workUserIdList = this.checkedGroupUserIdList
            const hookList = this.webHookList.map(x => x.WebhookURL)
            const res = await http.post("/form/SetFormNotifications", {workUserIdList: workUserIdList, hookList: hookList, templateId: templateId})
            console.log(res)
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: this.$i18n.tc("utils.update"), life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
                this.$emit("updateDisplayFormNotificationDialog", false)
            } else {
                this.$toast.add({severity: 'error', summary: res.Message, life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
            }

        },

        onAddMemberButtonClick() {
            const department = this.$store.getters["editTemplate/getDepartmentInfo"]
            if(department) {
                this.$router.push({ name: `inviteUser`, params: {departmentId: department}, query: {d: department}});
            }
        }

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
