<template>
    <Dialog v-model:visible="displayDownloadCsvOptionDialog" :modal="true" contentStyle="position: relative;"  :style="{width: '24vw'}">
        <template #header>
            {{$t('downloadCsvDialog.header')}}
        </template>
        <div style="text-align: left; margin-bottom: 1rem;font-size: 12px;" v-html="$t('downloadCsvDialog.infoText')">
        </div>
        <div style="text-align: left; margin-bottom: 1rem;">
            {{$t('downloadCsvDialog.selectCharacterCode')}}
        </div>
        <div class="p-field-radiobutton">
            <label style="line-height: 20px;">
                <RadioButton value="utf8" v-model="csvCharset"/> utf-8
            </label>
        </div>
        <div class="p-field-radiobutton">
            <label style="line-height: 20px;">
                <RadioButton value="Shift_JIS" v-model="csvCharset"/> shift-jis
            </label>
        </div>
        <div class="p-grid">
            <div class="p-col text-left">
                <Button class="p-button-outlined p-button-info"  @click="displayDownloadCsvOptionDialog=false">{{$t('utils.cancel')}}</Button>
            </div>
            <div class="p-col text-right">
                <Button class="p-button-outlined p-button-info"  @click="onDownloadCsvButtonClick">{{$t('downloadCsvDialog.download')}}</Button>
            </div>
        </div>
        <template v-if="displayProgressSpinner">
            <div class="progressSpinnerContainer">
                <ProgressSpinner  />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import http from '../mixins/api'

export default {
    name: 'DownloadCsvDialog',
    props: ['displayDownloadCsvOptionDialog', 'BulkCheckFormList', 'isSelectedAll', 'formStatus'],
    emits: ['updateDisplayDownloadCsvOptionDialog'],
    components: {
        Dialog,
        RadioButton,
        ProgressSpinner,
        Button,
    },
    data: function() {
        return {
            csvCharset: "Shift_JIS",
            displayProgressSpinner: false,
        }
    },
    created() {
    },
    computed: {
    },
    watch: {
        displayDownloadCsvOptionDialog: function(value) {
            this.displayProgressSpinner = false
            this.$emit("updateDisplayDownloadCsvOptionDialog", value)
        }
    },
    methods: {
        async onDownloadCsvButtonClick() {
            this.downloadCsv()
        },

        async downloadCsv() {
            if(!this.BulkCheckFormList.length && !this.isSelectedAll) return;
            this.displayProgressSpinner = true
            const FormIdList = this.BulkCheckFormList.map(x => x._id)
            const res = await http.post("/form/DownloadFormDataCsv", 
                { FormIdList: FormIdList, csvCharset: this.csvCharset, isSelectedAll: this.isSelectedAll, formStatus: this.formStatus, detailFormStatusId: this.$route.params.detailFormStatusId },
                { responseType: 'blob' }
            )

            var alink = document.createElement('a');
            alink.target = "_blank"; // これがないとsocketがdead
            // alink.crossorigin="anonymous"
            let binData = [res]

            // url = window.URL.createObjectURL(new Blob(binData, {type: "application/zip"}))
            let url = window.URL.createObjectURL(new Blob(binData, {type: "application/zip"}))
            alink.href = url;
            if (window.navigator.msSaveBlob) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", alink.href, true);
                xhr.responseType = "blob";
                xhr.onload = function (e) {
                    var blob = xhr.response;
                    window.navigator.msSaveBlob(blob, fileName);
                }
                xhr.send();
            } else {
                const e = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
                alink.dispatchEvent(e);
            }
            URL.revokeObjectURL(url);
            alink.remove();
            this.displayProgressSpinner = false;
        },
    },
}
</script>

<style scoped>
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
</style>
