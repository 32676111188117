<template>
    <Dialog v-model:visible="displayAndVisibleSelectMovedDetailFormStatusDialog" style="width: 480px;" :modal=true >
        <template #header>
            {{$t('selectMovedDetailFormStatusDialog.title')}}
        </template>
        <div class="p-grid">
            <div class="p-col-12  p-grid" style="text-align: left">
                <template v-for="DetailFormStatus in DetailFormStatusList" >
                    <div class="p-col-12">
                    <RadioButton :id="DetailFormStatus._id" :name="DetailFormStatus._id" :value="DetailFormStatus._id" v-model="detailFormStatusId" />
                    <label :for="DetailFormStatus._id">{{DetailFormStatus.Text}}</label>
                    </div>
                </template>
            </div>
            <div class="p-col-12 p-mt-1 p-grid  text-right" style="justify-content:flex-end;">
                <div>
                    <Button class="p-mr-1 p-button-outlined p-button-info" @click="displaySelectMovedDetailFormStatusDialog=false">{{$t("utils.No")}}</Button>
                    <Button class="p-button-info"  @click="setDetailFormStatusDialog">{{$t("utils.Yes")}}</Button>
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
import isMobile from '@/mixins/device'
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import http from '../mixins/api'


export default {
    name: 'SelectMovedDetailFormStatusDialog',
    props: ['displaySelectMovedDetailFormStatusDialog', 'targetRouteId', 'targetRouteCode', 'nextStatusAction', 'isBulk'],
    emits: ['updateSelectMovedDetailFormStatusDialog'],
    components: {
        Dialog,
        RadioButton,
        Button,
    },
    data: function(){
        return {
            isMobile,
            DetailFormStatusList: [],
            detailFormStatusId: {},
            displayAndVisibleSelectMovedDetailFormStatusDialog: false,
        }
    },
    watch: {
        displaySelectMovedDetailFormStatusDialog: async function(value) {
            let visible = value

            if(value) {
                visible = await this.getData()
                const lastOperationDetailFormIdInfo = this.isBulk ? this.$store.getters['detailFormStatus/getLastBulkOperationDetailFormIdInfo'] : this.$store.getters['detailFormStatus/getLastOperationDetailFormIdInfo']
                const lastOpe = lastOperationDetailFormIdInfo[this.$route.params.departmentId]
                if(lastOpe) {
                    this.detailFormStatusId = lastOpe[this.nextStatusAction] ? lastOpe[this.nextStatusAction] : this.$constants.DetailFormStatusIdConstants
                } else {
                    this.detailFormStatusId = this.$constants.DetailFormStatusIdConstants
                }
            }
            this.displayAndVisibleSelectMovedDetailFormStatusDialog = visible
        },
        displayAndVisibleSelectMovedDetailFormStatusDialog: function(value) {
            if(!value) {
                this.$emit("updateSelectMovedDetailFormStatusDialog", value)
            }
        }
    },
    computed: {
    },
    methods: {
        async getData() {
            const routeList = this.$store.getters["routeStore/getRouteList"]
            const activeRouteCode = this.$store.getters["routeStore/getActiveRouteCode"]
            const targetRouteId = this.targetRouteId
            const targetRouteCode = this.targetRouteCode
            let route = {}
            if(targetRouteId) {
                route = routeList.find(x => x._id == targetRouteId)
             }else if(targetRouteCode) {
                route = routeList.find(x => x.index == targetRouteCode)
            } else {
                route = routeList.find(x => x.index == activeRouteCode)
            }
            await this.$store.dispatch("detailFormStatus/setDetailFormStatusList", {DepartmentId: this.$route.params.departmentId, route: route})

            this.DetailFormStatusList = []
            const detailFormStatusList = []
            detailFormStatusList.push({
                Text: route.label,
                _id: this.$constants.DetailFormStatusIdConstants,
            })
            detailFormStatusList.push(...this.$store.getters["detailFormStatus/getDetailFormStatusList"].filter(x => x.IsVisible))
            this.DetailFormStatusList.push(...detailFormStatusList)
            if(this.DetailFormStatusList.length == 1) {
                await (this.detailFormStatusId = this.$constants.DetailFormStatusIdConstants)
                await this.setDetailFormStatusDialog()

                return false
            }
            const lastSelectedFormStatusList = this.$store.getters["detailFormStatus/getLastSelectedFormStatusList"]
            const index = lastSelectedFormStatusList.findIndex(x => x.departmentId  == this.$route.params.departmentId && x.activeRouteCode == activeRouteCode)
            console.log(lastSelectedFormStatusList)
            console.log(index)
            if(index != -1) {
                this.detailFormStatusId = lastSelectedFormStatusList[index].selectedDetailFormStatusId
                console.log(this.detailFormStatusId)
            } else {
                this.detailFormStatusId = this.$route.params.detailFormStatusId ? this.$route.params.detailFormStatusId : this.$constants.DetailFormStatusIdConstants
            }

            return true
        },

        async setDetailFormStatusDialog() {
            const activeRouteCode = this.$store.getters["routeStore/getActiveRouteCode"]
            this.$store.commit("detailFormStatus/setLastSelectedFormStatusList", {departmentId: this.$route.params.departmentId, activeRouteCode: activeRouteCode, selectedDetailFormStatusId: this.detailFormStatusId})
            const detailFormStatusId = this.detailFormStatusId != this.$constants.DetailFormStatusIdConstants ? this.detailFormStatusId : ""
            this.$emit("updateSelectMovedDetailFormStatusDialog", detailFormStatusId)
        },
    },
}
</script>

<style scoped>
</style>

