<template>
    <Dialog v-model:visible="displayDialog"  class="mailDialog" contentStyle="position: relative;"  :style="{width: '40vw'}" >
        <template #header>
            <span class="p-dialog-title">
                {{$t("MessageBeforeShowFormDialog.title")}}
            </span>
        </template>
            <div class="p-grid">
                {{$t("MessageBeforeShowFormDialog.messageInfo")}}
                <template v-for="(MessageInfo, MessageInfoIndex) in MessageList">
                    <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                        <template v-if="MessageInfoIndex == 0">
                            <!-- <Textarea type="text" v-model="MessageInfo.Message"  :autoResize="true" rows="5" :placeholder="$t('MessageBeforeShowFormDialog.message1Placeholder')" /> -->
                            <FormMessageEditor class="formMessageEditorMessageInfo" :content="MessageInfo.Message" :placeholder="$t('MessageBeforeShowFormDialog.message1Placeholder')" @onBlurContent="MessageInfo.Message=$event.html" />
                        </template>
                        <template v-else-if="MessageInfoIndex == 1">
                            <!-- <Textarea type="text" v-model="MessageInfo.Message"  :autoResize="true" rows="5" :placeholder="$t('MessageBeforeShowFormDialog.message2Placeholder')" /> -->
                            <FormMessageEditor class="formMessageEditorMessageInfo" :content="MessageInfo.Message" :placeholder="$t('MessageBeforeShowFormDialog.message1Placeholder')" @onBlurContent="MessageInfo.Message=$event.html" />
                        </template>
                        <template v-else>
                            <!-- <Textarea type="text" v-model="MessageInfo.Message"  :autoResize="true" rows="5" /> -->
                            <FormMessageEditor class="formMessageEditorMessageInfo" :content="MessageInfo.Message" :placeholder="$t('MessageBeforeShowFormDialog.message1Placeholder')" @onBlurContent="MessageInfo.Message=$event.html" />
                        </template>
                    </div>
                    <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                        <div class="p-col" />
                        <SelectButton v-model="MessageInfo.IsActive" :options="off_on" optionLabel="name" optionValue="value" :unselectable=false  />
                    </div>
                </template>
                <div class="p-col-12 p-nogutter p-fluid p-grid" style="">
                    <span style="white-space: pre-wrap;">
                        {{$t("MessageBeforeShowFormDialog.checkboxInfo")}}
                    </span>
                    <template v-for="(SelectBox, selectBoxIndex) in SelectBoxList" :key="SelectBox._id">
                        <div class="p-field-radiobutton p-col-12 p-nogutter no_padding p-fluid " >
                            <Checkbox :disabled="true" />
                            <template v-if="selectBoxIndex == 0">
                                <!-- <InputText v-model="SelectBox.SelectBoxText" :placeholder="$t('MessageBeforeShowFormDialog.checkbox1Placeholder')" /> -->
                                <FormMessageEditor :disabledFunctionList="['bulletList', 'image']" :disableEnterKey="true" class="formMessageEditorSelectBoxEditor" :content="SelectBox.SelectBoxHTML" :placeholder="$t('MessageBeforeShowFormDialog.checkbox1Placeholder')" @onBlurContent="SelectBox.SelectBoxHTML=$event.html; SelectBox.SelectBoxText=$event.text;" />
                            </template>
                            <template v-else-if="selectBoxIndex == 1">
                                <!-- <InputText v-model="SelectBox.SelectBoxText" :placeholder="$t('MessageBeforeShowFormDialog.checkbox2Placeholder')" /> -->
                                <FormMessageEditor :disabledFunctionList="['bulletList', 'image']" :disableEnterKey="true" class="formMessageEditorSelectBoxEditor" :content="SelectBox.SelectBoxHTML" :placeholder="$t('MessageBeforeShowFormDialog.checkbox2Placeholder')" @onBlurContent="SelectBox.SelectBoxHTML=$event.html; SelectBox.SelectBoxText=$event.text;" />
                            </template>
                            <template v-else-if="selectBoxIndex == 2">
                                <!-- <InputText v-model="SelectBox.SelectBoxText" :placeholder="$t('MessageBeforeShowFormDialog.checkbox3Placeholder')"  /> -->
                                <FormMessageEditor :disabledFunctionList="['bulletList', 'image']" :disableEnterKey="true" class="formMessageEditorSelectBoxEditor" :content="SelectBox.SelectBoxHTML" :placeholder="$t('MessageBeforeShowFormDialog.checkbox3Placeholder')" @onBlurContent="SelectBox.SelectBoxHTML=$event.html; SelectBox.SelectBoxText=$event.text;" />
                            </template>
                            <template v-else>
                                <!-- <InputText v-model="SelectBox.SelectBoxText"  /> -->
                                <FormMessageEditor :disabledFunctionList="['bulletList']" :disableEnterKey="true" class="formMessageEditorSelectBoxEditor" :content="SelectBox.SelectBoxHTML" @onBlurContent="SelectBox.SelectBoxHTML=$event.html; SelectBox.SelectBoxText=$event.text;" />
                            </template>
                        </div>
                    </template>
                    <div class="p-col-12 p-nogutter p-fluid p-grid" style="">
                        <i class="pi pi-minus-circle text-right p-ml-2 p-pt-1 cursor_pointer" @click="onMinusSelectBoxIconClick($event)"  />
                        <i class="pi pi-plus-circle text-right p-ml-2 p-pt-1 cursor_pointer" @click="onAddSelectBoxIconClick($event)"  />
                    </div>
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <div class="p-col" />
                    <SelectButton v-model="IsSelectBoxActive" :options="off_on" optionLabel="name" optionValue="value" :unselectable=false  />
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <span style="white-space: pre-wrap; font-weight: bold;">
                        {{$t("MessageBeforeShowFormDialog.authenticationInfo")}}
                    </span>
                </div>
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <span style="white-space: pre-wrap;">
                        {{$t("MessageBeforeShowFormDialog.whatAuthenticationInfo")}}
                    </span>
                    <div class="p-col-6 p-nogutter" >
                        <img src='/images/turnstile-image.png' style="object-fit:contain; width: 100%; height: 100%;" />
                    </div>
                    <div class="p-col" />
                    <div style="align-self: end;">
                        <SelectButton v-model="IsAuthActive" :options="off_on" optionLabel="name" optionValue="value" :unselectable=false  />
                    </div>
                </div>
            </div>
        <template #footer>
            <div class="p-d-flex">
                <div class="p-col" />
                <Button :label="$t('utils.close')" icon="pi pi-times" @click="onClose" class="p-button-text" />
                <Button :label="$t('utils.save')" @click="updateShowMessage" :disabled="!editable" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip'
import SelectButton from 'primevue/selectbutton';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox'
import FormMessageEditor from '@/components/formMessageEditor';

export default {
    name: 'MessageBeforeShowFormDialog',
    props: ["displayDialog", "editable"],
    emits: ["closeDialog"],
    components: {
        Dialog,
        Button,
        Textarea,
        SelectButton,
        InlineMessage,
        Message,
        InputText,
        Checkbox,
        FormMessageEditor,
    },
    data: function() {
        return {
            off_on: [
                {
                    name: "OFF",
                    value: false,
                },
                {
                    name: "ON",
                    value: true,
                }
            ],
        }
    },
    created() {
    },
    computed: {
        IsActive: {
            get: function() {
                return  this.$store.getters["formTemplateShowMessageBeforeStore/getFormTemplateShowBeforeIsActive"]
            },
            set: function(value) {
                 this.$store.commit("formTemplateShowMessageBeforeStore/setFormTemplateShowBeforeIsActive", value)
            },
        },
        IsAuthActive: {
            get: function() {
                return  this.$store.getters["formTemplateShowMessageBeforeStore/getFormTemplateShowBeforeIsAuthActive"]
            },
            set: function(value) {
                 this.$store.commit("formTemplateShowMessageBeforeStore/setFormTemplateShowBeforeIsAuthActive", value)
            },
        },
        SelectBoxList: {
            get: function() {
                return  this.$store.getters["formTemplateShowMessageBeforeStore/getFormTemplateShowSelectboxBeforeList"]
            },
        },
        MessageList: {
            get: function() {
                return  this.$store.getters["formTemplateShowMessageBeforeStore/getFormTemplateShowMessageBeforeList"]
            },
        },
        IsSelectBoxActive: {
            get: function() {
                return  this.$store.getters["formTemplateShowMessageBeforeStore/getFormTemplateShowBeforeIsSelectBoxActive"]
            },
            set: function(value) {
                 this.$store.commit("formTemplateShowMessageBeforeStore/setFormTemplateShowBeforeIsSelectBoxActive", value)
            },
        },
    },
    watch: {
        displayDialog: function(value){
            if(!value) {
                this.$emit("closeDialog", false)
            }
        },
    },
    methods: {
        onClose() {
            this.$emit("closeDialog", false)
        },

        async updateShowMessage() {
            await this.$store.dispatch('formTemplateShowMessageBeforeStore/setFormTemplateShowBeforeMesasge', {})
            this.$emit("closeDialog", false)
        },

        onAddSelectBoxIconClick(e) {
            this.$store.commit('formTemplateShowMessageBeforeStore/addFormTemplateShowSelectboxBeforeList', {})
        },

        onMinusSelectBoxIconClick(e) {
            this.$store.commit('formTemplateShowMessageBeforeStore/minusFormTemplateShowSelectboxBeforeList', {})
        },

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style lang="scss" scoped>
</style>
