<template>
    <i v-tooltip="tooltipText">
        <fa icon="paper-plane" style="color: #549CF8;"/>
    </i>
</template>

<script>
import Tooltip from 'primevue/tooltip'

export default {
    name: 'sentPaperIcon',
    props: ['tooltipText'],
    emits: [],
    directives: {
        'tooltip': Tooltip,
    },
}
</script>

<style scoped>
</style>

