<template>
    <DataTable
            :scrollable="true"
            scrollHeight="flex"
            v-model:value="GridData" 
            v-model:selection="selectedData" 
            selectionMode="single" 
            dataKey="_id" 
            @row-select="selectedRow"
            class="p-datatable-sm createdFormDataTable datatable-width-100"
            v-model:filters="filters1" 
            filterDisplay="menu"
            :globalFilterFields="['DocumentName', 'DepartmentName', 'FromEmail', 'CompanyName', 'TemplateNote', 'OpenBegin', 'OpenEnd', 'IsPublicForm']"
            :lazy="true" :paginator="true" :rows="rows"
            :totalRecords="totalRecords" 
            :loading="loading" 
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
            :rowsPerPageOptions="$constants.rowsPerPageOptions"
            removableSort
            ref="createdTemplateformDataTableRef"
            :key="createdDataTableKey"
            :sortField="dynamicSortField"
            :sortOrder="dynamicSortOrder"
            >
                <template #header>
                    <div class="p-d-flex  p-jc-between">
                        <div style="place-self: center;" class="">
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-text p-button-plain refreshIcon" @click="onReloadFormDataColumnButtonClick" v-tooltip="$t('utils.refresh')" />
                        </div>
                        <div style="align-self: center; padding-right: 14px;" v-if="showGlobalFilter">
                            <Button type="button" icon="pi pi-filter-slash" :label="$t('utils.clear')" class="p-button-secondary p-button-outlined p-mr-2" @click="clearFilter1()" :class="{'fillInGlobalSearchButton': isGridFiltering}" :disabled="!isGridFiltering" />
                            <span class="p-input-icon-left p-input-icon-right">
                                <i class="pi pi-search" />
                                    <InputText style="padding: 5px 2rem; width: 260px;"  v-model="filters1['global'].value" placeholder="Keyword Search" @focus="$event.target.select()" @keydown.enter="globalFilterEnter" ref="globalFilterRef" @change="filters1['global'].value = filters1['global'].value.trim()" v-tooltip.bottom.focus="$t('formDataTable.globalSearchInputTextTooltip')" />
                                    <i class="pi pi-info-circle" v-tooltip="$t('formDataTable.globalSearch')" />
                            </span>
                        </div>
                    </div>
                </template>
                <template #empty>
                    {{$t('formDataTable.ForgetFilter')}}
                </template>
                <template #loading>
                    Please wait.
                </template>
            <Column selectionMode="single" v-if="addRadioColumn" headerStyle="width: 60px;" style="max-width: 60px;"></Column>
            <Column
                    :sortable="true"
                    headerStyle="width: 60px;" 
                    style="max-width: 60px;"
                    field="SortNumberByTemplate"
                    >
                    <template #body="slotProps">
                        <span class="text-ellipsis" v-tooltip="{ value: slotProps.data.SortNumberByTemplate}"  >
                            {{slotProps.data.SortNumberByTemplate}}
                        </span>
                    </template>
            </Column>

    <Column :style="documentNameMaxPixel" filterField="DocumentName" :showFilterMatchModes="false" v-if="showColumn.includes('DocumentName')"
                    headerStyle="flex: 3" 
                    style="flex: 3;"
    >
    <template #header="slotProps">
        {{$t('formDataTable.DocumentName')}}<span class='gridControlText'> ({{$t('formDataTable.ControlText')}}) </span>
    </template>
    <template #body="slotProps">
        <span class="text-ellipsis">
            <Highlighter class=""   
                         :highlightClassName="formDataTableHighlight"
                         :searchWords="highlightKeywords('DocumentName')"
                         :autoEscape="true"
                         :textToHighlight="slotProps.data.DocumentName "/>
            <span class="gridControlText" v-if="slotProps.data.ControlText " >(
                <Highlighter class=""   
                             :highlightClassName="formDataTableHighlight"
                             :searchWords="highlightKeywords('DocumentName')"
                             :autoEscape="true"
                             :textToHighlight="slotProps.data.ControlText "/>
                )</span>
        </span>
        <div style="flex: 1;" />
        <template v-if="slotProps.data.IsShared">
            <i class="pi pi-share-alt" v-tooltip="$t('formDataTable.IsShare')"  style="font-size: 0.6rem; margin-left: 2px;"  />
        </template>
        <template v-if="slotProps.data.IsPasswordLocked">
            <i class="pi pi-key" v-tooltip="$t('formDataTable.IsPasswordLockedTooltip')" style="font-size: 0.6rem; margin-left: 2px;" />
        </template>
        <template v-if="slotProps.data.IsBeforeShowMessage">
            <i class="pi pi-at" v-tooltip="$t('formDataTable.IsBeforeShowMessageTooltip')" style="font-size: 0.6rem; margin-left: 2px;" />
        </template>
        <template v-if="slotProps.data.IsAfterShowMessage">
            <i class="pi pi-comment" v-tooltip="$t('formDataTable.IsAfterShowMessageTooltip')" style="font-size: 0.6rem; margin-left: 2px;" />
        </template>
        <template v-if="slotProps.data.IsNotification">
            <i class="pi pi-bell" v-tooltip="$t('formDataTable.IsBell')" style="font-size: 0.6rem; margin-left: 2px;" />
        </template>
    </template>
    <template #filter="{filterModel}">
        <InputText  v-on="filterOn()"  ref="filterInputRef" v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
    </template>
    <template #filterapply="{filterModel, filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DocumentName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
    </template>
    <template #filterclear="{filterModel, filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DocumentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
    </template>
    </Column>
    <Column :style="defaultMaxPixel" 
                v-if="showColumn.includes('DepartmentName')"
                :header="$t('formDataTable.ToDepartment')" 
                filterField="DepartmentName" :showFilterMatchModes="false">
    <template #body="slotProps">
        <span
                class="text-ellipsis underline" 
                @click.stop="linkToDocumentList($event, slotProps.data)"
                >
                <!-- {{slotProps.data.DepartmentName}} -->
            <Highlighter class=""   
                         :highlightClassName="formDataTableHighlight"
                         :searchWords="highlightKeywords('DepartmentName')"
                         :autoEscape="true"
                         :textToHighlight="slotProps.data.DepartmentName "/>
        </span>
    </template>
    <template #filter="{filterModel}">
        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
    </template>
    <template #filterapply="{filterModel, filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
    </template>
    <template #filterclear="{filterModel, filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
    </template>
    </Column>
    <Column :header="$t('formDataTable.ToCompanyName')" :style="defaultMaxPixel" filterField="CompanyName"  :showFilterMatchModes="false" v-if="showColumn.includes('CompanyName')" >
    <template #body="slotProps">
        <span class="text-ellipsis underline" 
              @click.stop="linkToDocumentList($event, slotProps.data, true)"
              >
              <!-- {{slotProps.data.CompanyName}} -->
            <Highlighter class=""   
                         :highlightClassName="formDataTableHighlight"
                         :searchWords="highlightKeywords('CompanyName')"
                         :autoEscape="true"
                         :textToHighlight="slotProps.data.CompanyName "/>
        </span>
    </template>
    <template #filter="{filterModel}">
        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
    </template>
    <template #filterapply="{filterModel,filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
    </template>
    <template #filterclear="{filterModel,filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
    </template>
    </Column>
    <Column :header="$t('formDataTable.ConfirmedUserDisplayInCreated')" :headerStyle="avatarPixel" :style="avatarMaxPixel"  v-if="showColumn.includes('ConfirmedUserDisplayInCreated')" >
    <template #body="slotProps">
        <AvatarGroup class="">
        <template v-for="ConfirmedUserDisplay in slotProps.data.ConfirmedUserDisplayList">
            <Avatar 
                  :label="ConfirmedUserDisplay.Email.slice(0, 1)"
                  class="p-mr-2 border-none" 
                  shape="circle" 
                  v-tooltip="ConfirmedUserDisplay.Email + '\n' + $filters.formatDate(ConfirmedUserDisplay.StampedDate, null, 'HH:mm:ss')"
                  />
        </template >
        <template v-if="slotProps.data.ConfirmedUserExcessNumberText">
            <Avatar 
                  :label="'+' + slotProps.data.ConfirmedUserExcessNumberText" 
                  shape="circle"
                  style="border: none; color: #172b4d;" 
                  class="avatar-icon" 
                  @click.stop="showFormMemberListDialogClick($event, slotProps.data, 'Template')"
                  v-tooltip="$t('utils.details')"
                  />
        </template >
        <template v-else>
            <Avatar 
                  icon="pi pi-users" 
                  class="p-mr-2 avatar-icon" 
                  shape="circle" 
                  style="border: none; color: #172b4d;" 
                  @click.stop="showFormMemberListDialogClick($event, slotProps.data, 'Template')"
                  v-tooltip="$t('utils.details')"
                  />
        </template >
        <StampedUserListOverPanel ref="overlayPanel" :Type="StampedUserListOverPanelType"  :FormId="StampedUserListOverPanelId" :TemplateId="StampedUserListOverPanelTemplateId" />
        </AvatarGroup>
    </template >
    </Column>
    <!-- <Column  :header="$t('formDataTable.OpenBegin')" dataType="date" :headerStyle="datePixel" :style="dateMaxPixel" filterField="OpenBegin"  v-if="showColumn.includes('OpenBegin')" :showFilterOperator=false > -->
    <!-- <template #body="slotProps"> -->
    <!--     <span class="text-ellipsis"> -->
    <!--         {{$filters.formatDate(slotProps.data.OpenBegin)}} -->
    <!--     </span> -->
    <!-- </template> -->
    <!-- <template #filter="{filterModel}"> -->
    <!--     <Calendar ref="filterInputRef"  v-model="filterModel.value" :dateFormat="dateFormat" :placeholder="dateFormat"  :inputClass="{'p-invalid': filterModel.invalid}" /> -->
    <!-- </template> -->
    <!-- <template #filterapply="{filterModel, filterCallback}"> -->
    <!--     <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button> -->
    <!-- </template> -->
    <!-- <template #filterclear="{filterModel, filterCallback}"> -->
    <!--     <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button> -->
    <!-- </template> -->
    <!-- </Column> -->
    <!-- <Column dataType="date" :headerStyle="datePixel" :style="dateMaxPixel" :header="$t('formDataTable.OpenEnd')" filterField="OpenEnd"  v-if="showColumn.includes('OpenEnd')" :showFilterOperator=false > -->
    <!-- <template #body="slotProps"> -->
    <!--     <span class="text-ellipsis"> -->
    <!--         {{$filters.formatDate(slotProps.data.OpenEnd)}} -->
    <!--     </span> -->
    <!-- </template> -->
    <!-- <template #filter="{filterModel}"> -->
    <!--     <Calendar  ref="filterInputRef"  v-model="filterModel.value" :dateFormat="dateFormat" :placeholder="dateFormat"  :inputClass="{'p-invalid': filterModel.invalid}" /> -->
    <!-- </template> -->
    <!-- <template #filterapply="{filterModel,filterCallback}"> -->
    <!--     <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button> -->
    <!-- </template> -->
    <!-- <template #filterclear="{filterModel,filterCallback}"> -->
    <!--     <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button> -->
    <!-- </template> -->
    <!-- </Column> -->

    <Column :headerStyle="datePixel" :style="dateMaxPixel" :header="$t('formDataTable.UpdatedDate')" filterField="IsPublicForm" :showFilterOperator=false  >
        <template #body="slotProps">
            <template v-if="slotProps.data.isBeforePublicForm">
                <Button 
                    class="p-button p-button-secondary p-button-text validUrlButton" 
                    style="background: rgba(100, 116, 139, 0.16); border-color: transparent; color: #64748B; "
                    :label="$t('formDataTable.IsPublicFormDropDownOption.IsBefore')"
                    />
            </template>
            <template v-else-if="slotProps.data.isAfterPublicForm">
                <Button
                    class="p-button p-button-warning p-button-text validUrlButton"
                    style="  background-color: rgba(265, 158, 8, 0.16); "
                    label="ON"
                    />
            </template>
            <template v-else>
                <Button
                    class="p-button p-button-info p-button-text validUrlButton"
                    style="background: rgba(59, 130, 266, 0.16); border-color: transparent; color: #3B82F6; "
                    label="OFF"
                    />
            </template>
        </template>
        <template #filter="{filterModel}">
            <Dropdown
                    ref="filterInputRef" 
                    :options="IsPublicFormDropDownOption" 
                    v-model="filterModel.value" 
                    @change="onIsPublicFormDropDownChange"
                    optionValue="value" 
                    optionLabel="label"
            />
        </template>
        <template #filterapply="{filterModel, filterCallback}">
            <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('IsPublicForm', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
        </template>
        <template #filterclear="{filterModel, filterCallback}">
            <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('IsPublicForm', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
        </template>
    </Column>

    <Column :header="$t('formDataTable.TemplateNote')" filterField="TemplateNote" sortField="TemplateNote" :sortable="true" class="" :showFilterMatchModes="false"  headerStyle="width: 120px;" style="max-width: 120px;"  v-if="showColumn.includes('TemplateNote')">
    <template #body="slotProps">
        <span class="text-ellipsis" v-tooltip="{ value: slotProps.data.TemplateNote}" >
            <!-- {{slotProps.data.TemplateNote}} -->
            <Highlighter class=""   
                         :highlightClassName="formDataTableHighlight"
                         :searchWords="highlightKeywords('TemplateNote')"
                         :autoEscape="true"
                         :textToHighlight="slotProps.data.TemplateNote "/>
        </span>
    </template>
    <template #filter="{filterModel}">
        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}"  @change="filterModel.value = filterModel.value.trim()" />
    </template>
    <template #filterapply="{filterModel, filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('TemplateNote', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
    </template>
    <template #filterclear="{filterModel,filterCallback}">
        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('TemplateNote', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
    </template>
    </Column>
        <template #paginatorend>
            <div class="page-bottom-form-info">
                <template v-if="Status == 1 ">
                    {{$t("draft.header.confirmedDate")}}{{$filters.formatDate(ConfirmDate, null, "HH:mm:ss" )}}
                </template>
                <template v-else>
                    {{$t("draft.header.garbageDate")}}{{$filters.formatDate(GarbageDate, null,  "HH:mm:ss" )}}
                </template>
                {{$t("draft.header.templateId")}}
                <i  v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>:
                {{TemplateId}}
                {{$t("draft.header.formId")}}

            </div>
        </template>
        <template #additionTable>
            <template v-if="GridData && GridData.length" >
                <Button class="p-button-text focus-box-shadow-none" @click="onNextPageButtonClick($event, 'createdTemplateformDataTableRef')" v-if="!isLastPage" > {{$t('utils.next')}} > </Button>
                <template v-else >
                    {{$t('formDataTable.rowEnd')}}
                </template>
            </template>
        </template>
    </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';

import Column from 'primevue/column';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple';
import StampedUserListOverPanel from '@/components/StampedUserListOverPanel'




import InputText from 'primevue/inputtext';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import Dropdown from 'primevue/dropdown';

import isMobile from '@/mixins/device'
import Highlighter from 'vue-highlight-words'




export default {
    name: 'FormDataTableCreated',
    // props: ["GridData", "StampedUserListOverPanelType", "StampedUserListOverPanelId", "StampedUserListOverPanelTemplateId"],
    // props: ["GridData", "showColumn", "addRadioColumn", "totalRecords", "Status", "showGlobalFilter"],
    props: {
        "GridData": {
            type: Array
        },
        "showColumn": {
            type: Array
        },
        "addRadioColumn": {
            type: Boolean
        },
        "totalRecords": {
            type: Number
        },
        "Status": {
            type: Number
        },
        "showGlobalFilter": {
            type: Boolean,
            default: true
        },
        "rows": {
            type: Number,
            default: 10,
        },
    },
    emits: [ "selectedRow", "linkToDocumentList", "showFormMemberListDialogClick", "onPage", "onSort", "onFilter", "reloadTable"],
    components: {
        DataTable,
        DataView,
        Column,
        Avatar,
        AvatarGroup,
        Button,
        Calendar,
        StampedUserListOverPanel,
        InputText,
        Dropdown,
        Highlighter,
    },
    data: function() {
        return {
            isMobile,
            filters1: null,
            StampedUserListOverPanelType: "", StampedUserListOverPanelId: "", StampedUserListOverPanelTemplateId: "",
            defaultMaxPixel: "max-width: 400px",
            documentNameMaxPixel: "max-width: 600px",
            datePixel: "width: 110px",
            dateMaxPixel: "max-width: 110px",
            avatarPixel: "width: 122px;",
            avatarMaxPixel: "max-width: 122px;",
            loading: false,
            dynamicSortField: "",
            dynamicSortOrder: 0,
            isLastPage: false,
            IsPublicFormDropDownModel: "",
            IsPublicFormDropDownOption: [
                {
                    label: this.$i18n.tc('formDataTable.IsPublicFormDropDownOption.IsBefore'),
                    value: "isBefore"
                },
                {
                    label: "ON",
                    value: "isPublic"
                },
                {
                    label: "OFF",
                    value: "isAfter"
                },
            ],
        }
    },
    watch: {
        GridData: function(value, oldValue)  {
            const self = this;
            this.$nextTick(function() {
                if(self.$refs && self.$refs['createdTemplateformDataTableRef'] && self.$refs['createdTemplateformDataTableRef'].$refs)
                    self.isLastPage = self.$refs['createdTemplateformDataTableRef'].$refs.dtPaginator.isLastPage
                else
                    self.isLastPage = false
            })
        },
    },
    created() {
        if(this.$route.query & this.$route.query.s) {
            this.initFilters(this.$route.query.s)
            this.globalFilterEnter()
        } else {
            this.initFilters()
        }
        this.initSorts()
    },
    computed: {
        highlightKeywords() {
            return (filterProp) => {
                const keyword = this.filters1 && this.filters1[filterProp] ? this.filters1[filterProp].value : ""
                const globalKeyword = this.filters1 && this.filters1.global ? this.filters1.global.value : ""

                return [keyword, globalKeyword]
            }
        },
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return userInfo && userInfo.dateformat ? userInfo.dateformat.toLowerCase().replace("yyyy", "yy") : "yy/mm/dd";
            }
        },
        selectedData: {
            get: function() {
                const data = this.$store.getters["gridDataStore/getSelectedData"]
                return data
            },
        },
        createdDataTableKey: {
            get: function() {
                const keyCount = this.$store.getters["auth/getKeyCount"]
                return "createdDataTableKey" + "_" + keyCount
            }
        },

        isGridFiltering: {
            get: function() {
                const filterData = this.$store.getters["gridDataStore/getIsFiltering"] || {}
                if(filterData["Template"] && filterData["Template"][this.Status]) {
                    return true
                }
                return false
            },
        },

        GarbageDate: {
            get: function() {
                return this.$store.getters["editTemplate/getGarbageDate"]
            }
        },
        ConfirmDate: {
            get: function() {
                return this.$store.getters["editTemplate/getConfirmDate"]
            }
        },
        TemplateId: {
            get: function() {
                return this.$store.getters["editTemplate/getTemplateId"]
            }
        },
    },

    methods: {
        filterOn: function() {
            const self = this
            this.$nextTick(function() {
                if(self.$refs.filterInputRef.$el.children && self.$refs.filterInputRef.$el.children.length)
                    self.$refs.filterInputRef.$el.children[0].focus()
                else
                    self.$refs.filterInputRef.$el.focus()
            })
        },

        initSorts() {
            this.dynamicSortField = ""
            this.dynamicSortOrder = 0
            const sortData = this.$store.getters["gridDataStore/getGridSortData"] || {}

            if(sortData["Template"] && sortData["Template"][this.Status]) {
                this.dynamicSortField = Object.keys(sortData["Template"][this.Status])[0];
                this.dynamicSortOrder = sortData["Template"][this.Status][this.dynamicSortField];
                return;
            }
        },

        clearFilter1() {
            this.initFilters("", true);
            this.globalFilterEnter()
        },

        initFilters(globalValue="", forceReset=false) {
            if(!forceReset) {
                const filterData = this.$store.getters["gridDataStore/getGridFilterData"] || {}
                if(filterData["Template"] && filterData["Template"][this.Status]) {
                    this.filters1 = filterData["Template"][this.Status];
                    return;
                }
            }
            const DateFilterList = [
                {value: null, matchMode: FilterMatchMode.DATE_IS},
            ]
            // const DateFilter = {operator: FilterOperator.OR, constraints: DateFilterList};
            const DateFilter = {operator: FilterOperator.AND, constraints: DateFilterList};
            this.filters1 = {
                'global': {value: globalValue ? globalValue : null, matchMode: FilterMatchMode.CONTAINS},
                'SortNumberByTemplate': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DocumentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DepartmentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FromEmail': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CompanyName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CreatorName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'OpenBegin': DateFilter,
                'SendDate': DateFilter,
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'OpenEnd': DateFilter,
                'FormStatus': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SenderFormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                '_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateId': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'IsPublicForm': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
            if(globalValue) {
                const self = this;
                this.$nextTick(function() {
                self.$refs.globalFilterRef.$el.select()
                })
            }
        },


        selectedRow(event) {
            this.$emit("selectedRow", event)
        },


        async showFormMemberListDialogClick(e, record, Type) {
            await(this.StampedUserListOverPanelType = Type)
            await(this.StampedUserListOverPanelId = record._id)
            await(this.StampedUserListOverPanelTemplateId = record._id)
            // this.$emit("showFormMemberListDialogClick", {e, record, Type})
            this.$refs.overlayPanel.toggle(e, record.ConfirmedUserDisplayList, true)
        },

        async linkToDocumentList(e, record, isCompany=false) {
            this.$emit("linkToDocumentList", {record, isCompany})
        },

        onReloadFormDataColumnButtonClick() {
            this.$emit("reloadTable", true)
        },

        onFilter(e) {
            this.$emit("onFilter", e)
        },

        onSort(e) {
            this.$emit("onSort", e)
        },

        onPage(e) {
            this.$emit("onPage", e)
        },

        globalFilterEnter() {
            this.$store.commit("editTemplate/clear")
            this.$store.commit("viewPostedForm/clear")
            this.$store.commit('gridDataStore/setEditableForm', false)
            this.$emit("onFilter", {filters: this.filters1})
        },

        gridFilterCallback(type, filterModel, filterCallback) {
            if(filterModel.constraints) {
                let clear = true
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    if(!filterModel.constraints[i].value) {
                        filterModel.constraints[i].invalid = true
                        clear = false
                    } else {
                        filterModel.constraints[i].invalid = false
                    }
                }
                if(clear) {
                    filterCallback()
                }
            } else if(filterModel.value) {
                filterModel.invalid = false
                filterModel.target = true
                filterCallback()
            } else {
                filterModel.invalid = true
            }
        },

        gridFilterClearCallback(type, filterModel, filterCallback) { 
            if(filterModel.constraints) {
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    filterModel.constraints[i].invalid = false
                }
            } else {
                filterModel.invalid = false
            }
            filterModel.target = false
            filterCallback()
        },

        onNextPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToNext(event)
        },

        onPrevPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToPrev(event)
        },

        onIsPublicFormDropDownChange(event, t) {
            console.log(event, t)
        }
    },
    directives: {
        'tooltip': Tooltip,
        'ripple': Ripple
    },
}
</script>

<style scoped>
.validUrlButton {
    width: 70px;
    height: 22px; 
    padding: 2px 10px;
}
</style>
