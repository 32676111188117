<template>
    <div :class="class">
        <tiptapExtensionEditor :className="'formMessageEditor'" :content="content" :disabled="disabled" :placeholder="placeholder" :disabledFunctionList="disabledFunctionList" :disableEnterKey="disableEnterKey" @onUpdate="onUpdate"/>
    </div>
</template>

<script>
import tiptapExtensionEditor from "@/components/tiptap-extensions";

export default {
    props: {
        class: {
            type: String,
            default: "",
        },
        className: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        disabledFunctionList: {
            type: Array,
            default: [],
        },
        disableEnterKey: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onBlurContent'],
    components: {
        tiptapExtensionEditor,
    },

    data() {
        return {
        }
    },

    computed: {
    },

    mounted() {
    },

    methods: {
        onUpdate(e) {
            this.$emit('onBlurContent', e)
        }
    },


    beforeUnmount() {
    },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  text-align: left;
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.bubble-menu {
  display: flex;
  background-color: white;
  padding: 0.2rem;
  border-style: solid;
  border-width: 1px;
  border-color: #dee0e1;
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);


  /* button { */
  /*   border: none; */
  /*   background: none; */
  /*   color: #FFF; */
  /*   font-size: 0.85rem; */
  /*   font-weight: 500; */
  /*   padding: 0 0.2rem; */
  /*   opacity: 0.6; */
  /*  */
  /*   &:hover, */
  /*   &.is-active { */
  /*     opacity: 1; */
  /*   } */
  /* } */
  span:hover {
      background-color: rgba(64, 64, 64, 0.2);
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
.label-editor-disabled > div {
    border: none;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror.ProseMirror-focused {
    /* outline: none; */
    /* border: none !important; */
}
.disableEnterKey > div {
    white-space: nowrap;
}
</style>

