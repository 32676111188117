<template>
    <OverlayPanel ref="op" appendTo="body">
        <div style="width: 200px;">
            <div class="p-grid">
                <div class="p-col-12">
                    <span> {{$t('stampedUserListOverPanel.confirmedUser')}}</span>
                </div>
                <div class="p-col-12" style="max-height: 100px;overflow-y: scroll">
                    <template v-for="User in StampedUserList">
                        <Avatar 
                            :label="User.Email.slice(0, 1)"
                            class="p-col-2 p-mr-2" 
                            shape="circle" 
                            v-tooltip="User.Email + '\n' + $filters.formatDate(User.StampedDate, null, 'HH:mm:ss')"
                        />
                    </template>
                </div>

                <div class="p-col-12">
                    <span> {{$t('stampedUserListOverPanel.notConfirmedUser')}}</span> 
                </div>
                <div class="p-col-12" style="max-height: 100px;overflow-y: scroll">
                    <template v-for="User in NotStampedUserList">
                        <Avatar 
                            :label="User.Email.slice(0, 1)"
                            class="p-col-2 p-mr-2" 
                            shape="circle" 
                            v-tooltip="User.Email + '\n' "
                        />
                    </template>
                </div>
            </div>
            <div class="text-right">
                <span class="link" @click="onShareTemplateInfoClick">{{$t('stampedUserListOverPanel.toShareView')}}</span>
            </div>
        </div>
    </OverlayPanel>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import Avatar from 'primevue/avatar'
import Tooltip from 'primevue/tooltip'
import http from '../mixins/api';

export default {
    name: 'StampedUserListOverPanel',
    props: ['Type', 'FormId', 'TemplateId'],
    emits: [''],
    components: {
        OverlayPanel,
        Avatar,
    },
    data: function() {
        return {
            UserList: [],
            StampedUserList: [],
            NotStampedUserList: [],
        }
    },
    watch: {
    },
    computed: {
    },
    async created() {

    },
    methods: {
        toggle: async function(e, UserList, isShow) {
            this.$refs.op.toggle(e)
            if(isShow) {
                const res = await http.post("/form/GetStampFormInfo", {Type: this.Type, _id: this.FormId})
                if(res.Success) {
                    this.UserList = res.UserList
                    this.StampedUserList = res.StampedUserList
                    const StampedUserIdList = res.StampedUserList.map(u => u.WorkUserId)
                    this.NotStampedUserList = this.UserList.filter(x => !StampedUserIdList.includes(x._id))
                } else {
                    this.UserList = []
                    this.StampedUserList = []
                    this.NotStampedUserList = []
                }
            }
        },
        async onShareTemplateInfoClick(e) {
            let id = this.TemplateId
            this.$router.push({ name: 'changeRegistrationInfo', query: { activeIndex: 1, activeTemplateId: id } });
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
