<template>
    <template v-if="readOnly">
        <span class=" p-col p-nogutter p-fluid p-float-label" >
            <Dropdown class="p-col-12 text-left" :modelValue="item.Value" :options="item.LabelList" optionLabel="label" optionValue="value"
                v-bind:class="{'required_field': required == 'true'}"
                :placeholder="$t('xtype.dropDownField.placeHolder')"
                style="opacity: 1"
                disabled
            />
            <div v-if="item.Value=='other'" class="p-col-12 no_padding p-grid no_margin" >
                <span class="align-item-center p-col  p-fluid p-float-label no_padding" >
                    <InputText
                        class="p-col clear_disabled fill_textfield" type="text" v-model="item.LabelList.find(x => x.value == 'other').otherValue"  disabled />
                    <label style="">{{ item.Placeholder }}</label>
                </span>
            </div>
        </span>
    </template>
    <template v-else-if="preview">
        <Dropdown class="p-col text-left" v-model="previewValue" 
            :options="previewLabelList" optionLabel="label" optionValue="value"
            v-bind:class="{'required_field': required == 'true', 'fill_textfield': previewValue, 'p-filled': previewValue}"
            :placeholder="$t('xtype.dropDownField.placeHolder')"
            @show="showDropDownOptions"
        />
        <div v-if="previewValue=='other'" class="p-col-12 no_padding p-grid" >
            <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
            <InputText
                v-bind:class="{'fill_textfield': previewOtherValue }"
                class="p-col textfield " type="text" v-model="previewOtherValue"   
                autoFocus/>
                    <label style="">{{ item.Placeholder }}</label>
                </span>
        </div>
    </template>
    <template v-else>
        <Textarea v-if="disabled" class="p-col-12 p-nogutter textfield" v-model="selectValueArea" @input="input"  v-bind:class="{'required_field': required == 'true'}" disabled/>
        <Textarea v-else class="p-col-12 p-nogutter textfield" v-model="selectValueArea" @input="input"  v-bind:class="{'required_field': required == 'true'}" />
        <div class=" p-mt-1 p-col-12 no_padding p-grid align-item-center ">
            <Checkbox class="no_padding p-mr-1" v-model="item.AddOtherInSelectionField" :binary="true" @change="onAddOtherInSelectionFieldChange" /> <label> {{$t('xtype.dropDownField.otherLabel')}} </label>
            <div v-if="!item.AddOtherInSelectionField" class="p-col" />
            <InputText class="p-col p-ml-2" v-if="item.AddOtherInSelectionField" v-model="item.Placeholder" @input="input" :disabled="disabled" :placeholder="$t('xtype.dropDownField.otherInputPlaceholder')"  />
        </div>
    </template>
</template>

<script>
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';



export default {
    name: 'dropDownField',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: ["setDataInItem", "clearRequirePreviewField"],
    components: {
        Textarea,
        Dropdown,
        Checkbox,
        InputText,
    },
    created() {
        if(!this.selectValueArea) {
            this.selectValueArea = [{label: "", value: "1"}]
        }
        if(!this.item.Placeholder) {
            this.item.Placeholder = ""
        }
    },
    data() {
        return {
            allowField: null,
            previewValue: "",
            selectValueArea: this.item.LabelList.map(x => x.label).join('\n'),
            // labelValue: null,
            // inputValue: null,
            previewOtherValue: "",
        }
    },
    computed: {
        previewLabelList: {
            get() {
                const LabelList = this.item.LabelList
                console.log(LabelList.findIndex(x => x.value == "other"))
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.multiSelectField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
    },
    watch: {
        'item.AddOtherInSelectionField': function(AddOtherInSelectionField) {
            if (AddOtherInSelectionField) {
                if (this.item.Placeholder == "") {
                    this.item.Placeholder = this.$i18n.tc('xtype.dropDownField.otherInputPlaceholder')
                }
            }
        }
    },
    methods: {
        input: function() {
            const LabelList = this.selectValueArea.split("\n").filter(x => x).map((x, i) => {
                return {"label": x, "value": (i+1).toString()}
            })
            const data = {
                Id: this.Id,
                Xtype: "dropDownField",
                ItemId: this.item.ItemId,
                LabelList: LabelList,
                Placeholder: this.item.Placeholder ? this.item.Placeholder : "",
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewText)))
        },
        showDropDownOptions() {
            if(this.required == "true") {
                if(document.getElementsByClassName("p-dropdown-panel p-component")[0].classList.contains("required-dropdown-options")) {
                    document.getElementsByClassName("p-dropdown-panel p-component")[0].classList.remove("required-dropdown-options")
                } else {
                    if(!this.previewValue)
                        document.getElementsByClassName("p-dropdown-panel p-component")[0].classList.add("required-dropdown-options")
                }
            }
        },
        onAddOtherInSelectionFieldChange: function() {
            this.$store.commit('editTemplate/setEdited', true)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


