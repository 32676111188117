<template>
    <template v-if="readOnly">
        <span class=" p-col p-nogutter p-fluid " >
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-clock"></i>
                </span>
                <div class=" p-float-label">
                    <InputText style="opacity: 1" class="clear_disabled textfield" v-model="DateStringValue"  v-bind:class="{'required_field': required == 'true', 'fill_textfield': item.DateValue && item.DateValue.length != 0, 'p-filled': item.DateValue}"  disabled/>

                    <label style=""> {{ item.Placeholder }} </label>
                </div>
            </div>
        </span>
    </template>
    <template v-else-if="preview">
        <span class="align-item-center p-col p-nogutter p-fluid p-float-label" >
            <Calendar 
                class="" 
                :showIcon="true"
                v-bind:class="{'required_field': required == 'true', 'fill_textfield': previewText}"
                type="text" 
                v-model="previewText"
                :dateFormat="dateFormat"
                @date-select="changePreviewValue"
                hourFormat="24"
                icon="pi pi-clock"
                :showTime="true"
                :touchUI="isMobile.isMobile()"
            />
            <label style="margin-left: 0.5rem;left: 44px;">{{ item.Placeholder }}</label>
        </span>
    </template>
    <template v-else>
        <div class="p-col p-nogutter no_padding p-inputgroup">
            <span class="p-inputgroup-addon" style="background: white; color: #607D8B">
                <i class="pi pi-clock"></i>
            </span>
            <InputText v-if="disabled" class=" textfield" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}"  disabled/>
            <InputText v-else class=" textfield" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}" />
        </div>

    </template>
</template>

<script>
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import isMobile from '@/mixins/device'




export default {
    name: 'datetimeField',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: ["setDataInItem", "clearRequirePreviewField"],
    components: {
        Calendar,
        InputText,
    },
    created() {
    },
    data() {
        return {
            allowField: null,
            previewText: "",
            isMobile,
        }
    },
    computed: {
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return (userInfo && userInfo.dateformat) ? userInfo.dateformat.toLowerCase().replace("yyyy", "yy") : "yy/mm/dd";
            }
        },
        DateValue: function() {
            if(this.item.DateValue) {
                if (this.item.WriterBrowserTimezoneOffsetMinutes) {
                    return this.$moment(this.item.DateValue).add(this.item.WriterBrowserTimezoneOffsetMinutes - (new Date()).getTimezoneOffset(), 'minutes').toDate()
                }
                return new Date(this.item.DateValue)
            } else {
                return "";
            }
        },
        DateStringValue: function() {
            if(this.item.DateStringValue) {
                return this.$moment(this.item.DateStringValue).format('yyyy/MM/DD HH:mm')
            } else {
                return "";
            }
        },

    },
    methods: {
        input: function() {
            const data = {
                Id: this.Id,
                Xtype: "datetimeField",
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewText)))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>




