<template>
    <Panel v-if="item.ComponentType == 'Editor'" class="previewPanel tempateFormEditorComponent" v-bind:class="{'noheader_panel': !item.LabelTEXT, 'hasheader_panel': item.LabelTEXT , 'topComponentPanel': item.No == 1 }" >
        <template #header>
            <div class="p-d-flex " >
                <!-- <div class="panel-header-label" >  -->
                <!--     {{item.Label}} -->
                <!-- </div> -->
                <LabelEditor class="preview_form_label" style="flex: 1" :content="item.Label" :disabled="true"/>
            </div>
        </template>
        <div class="p-grid no_margin"
            v-if="((item.items[0].Xtype == 'fullEditor4Applicant') || (item.items[0].Xtype == 'fullEditor' && item.items[0].Placeholder))" >
            <div class="p-col-12 no_padding">
                <slot></slot>
            </div>
        </div>
    </Panel>
    <Panel v-else class="previewPanel" v-bind:class="{'noheader_panel': !item.LabelTEXT, 'hasheader_panel': item.LabelTEXT, 'topComponentPanel': item.No == 1 }" style="position: relative;">
        <template #header>
            <div class="p-d-flex " >
                <!-- <div class="panel-header-label" style="word-break: break-word; text-align: left;">  -->
                <!--     {{item.Label}} -->
                <!-- </div> -->
                <LabelEditor class="preview_form_label" style="flex: 1" :content="item.Label" :disabled="true"/>
            </div>
        </template>
        <div class="p-grid" >



            <!-- <div style="width: 210px;" class="no_margin align-item-center p-grid  p-jc-between" v-if="item.Label1" v-bind:class="{'clear_required_field': item.Label1}"> -->
            <!--     <div class="p-col p-grid "> -->
            <!--         <label class="itemLabel p-d-block" style="max-width: 100%;"> -->
            <!--             <span > {{ item.Label1 }} </span> -->
            <!--         </label> -->
            <!--     </div> -->
            <!--     <div class="p-col-fixed" style="width: 65px;"> -->
            <!--         <template v-if="item.Required == 'true'"> -->
            <!--             <label class="tag_option_required">{{$t("inputForm.required")}}</label> -->
            <!--         </template> -->
            <!--         <template v-else> -->
            <!--             <label class="tag_option_notrequired">{{$t("inputForm.optional")}}</label> -->
            <!--         </template> -->
            <!--     </div> -->
            <!-- </div> -->
            <!-- <div style="" class="p-col no_margin align-item-center p-grid"  -->
            <!--     v-bind:class="{'p-col-12': !item.Label1, 'required_field': required == 'true' &#38;&#38; (item.items.findIndex(x => $constants.notFillRequireFieldArea.includes(x.Xtype)) == -1)}"> -->
            <!--         <label class="p-col-12  p-d-block p-mx-auto no_padding_bottom label2_font" v-if="item.Label2" > {{ item.Label2 }} </label> -->
            <!--  -->
            <!--         <slot></slot> -->
            <!--  -->
            <!--         <label class="p-col-12  p-d-block p-mx-auto no_padding_bottom label3_font" v-if="item.Label3"  > {{ item.Label3 }} </label> -->
            <!-- </div> -->



            <div class="p-col-12 p-md-4 p-lg-4 no_margin align-item-center p-as-center" v-if="item.Label1TEXT" v-bind:class="{'clear_required_field': item.Label1TEXT}">
                <div class=" p-d-flex">
                    <!-- <label class="itemLabel" style="max-width: 80%; word-break:break-word;" > -->
                    <!--     <span  > {{ item.Label1 }} </span> -->
                    <!-- </label> -->
                    <LabelEditor class=" no_padding preview_form_label1" style="flex: 1; word-break:break-word;" :content="item.Label1" :disabled="true" />
                    <div class="p-col-fixed align-item-center" style="width: 65px; align-self: center; margin: 0 auto; margin-right: 0px !important;">
                        <template v-if="item.Required == 'true'">
                            <label class="tag_option_required text-right">{{$t("inputForm.required")}}</label>
                        </template>
                        <template v-else-if="item.Required == 'false'">
                            <label class="tag_option_notrequired text-right">{{$t("inputForm.optional")}}</label>
                        </template>
                        <template v-else>
                            <label class=""></label>
                        </template>
                    </div>
                </div>
            </div>
            <div style="" 
                 class="p-col no_margin align-item-center p-grid"
                v-bind:class="{
                    'p-col-12': !item.Label1TEXT, 
                    'required_field': required == 'true' && (item.items.findIndex(x => $constants.notFillRequireFieldArea.includes(x.Xtype)) == -1)
                }">
                <!-- <label class="p-col-12  p-d-block p-mx-auto no_padding_bottom preview_form_label2_font"  v-if="item.Label2" > {{ item.Label2 }} </label> -->
                <LabelEditor v-if="item.Label2TEXT" class="p-col-12 no_padding_side preview_form_label2" :content="item.Label2" :disabled="true" />
                <slot></slot>
                <!-- <label class="p-col-12  p-d-block p-mx-auto no_padding_bottom preview_form_label3_font" v-if="item.Label3"> {{ item.Label3 }} </label> -->
                <LabelEditor v-if="item.Label3TEXT" class="p-col-12 no_padding_side preview_form_label3" :content="item.Label3" :disabled="true" />
            </div>
        </div>
        <i style="position: absolute; left: 4px; bottom: 4px; font-size: 10px;"> {{item.No}} </i>
    </Panel>
</template>

<script>
import Panel from 'primevue/panel';
import LabelEditor from '@/components/templateFormParts/LabelEditor';



export default {
    name: 'templatePanel',
    props: ['item', 'Id', 'required' ,'readOnly'],
    components: {
        Panel,
        LabelEditor,
    },
    data() {
        return {
            allowField: null,
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.handle:hover {
    cursor: grab;
}
</style>


