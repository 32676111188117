<template>
    <template v-if="readOnly">

        <span class="p-col p-d-flex p-nogutter p-fluid p-inputgroup" >
            <span class="align-item-center p-col  p-fluid p-float-label " >
            <InputText class="textfield clear_disabled p-filled" 
                v-bind:class="{'required_field': required == 'true', 'fill_textfield': item.Value && item.Value.length != 0}"
                v-model="item.Value"  disabled/>
            <label style="">{{ item.Placeholder }}</label>
            </span>
            <span style="width: 80px;" class=" p-inputgroup-addon filledUnit" v-if="item.Unit">{{item.Unit }}</span>
        </span>


        <!-- <span class=" p-col p-nogutter p-fluid p-float-label" > -->
        <!--     <InputText class="textfield clear_disabled p-filled"  -->
        <!--         v-bind:class="{'required_field': required == 'true', 'fill_textfield': item.Value &#38;&#38; item.Value.length != 0}" -->
        <!--         v-model="item.Value"  disabled/> -->
        <!--     <label style="margin-left: 0.5rem;">{{ item.Placeholder }}</label> -->
        <!-- </span> -->
    </template>
    <template v-else-if="preview">
        <!-- <span class="align-item-center p-col p-nogutter p-fluid p-float-label" > -->
        <!--     <InputText class="  textfield" v-bind:class="{'required_field': required == 'true', 'fill_textfield': previewText.length != 0}"  type="text" v-model="previewText" @input="changePreviewValue"/> -->
        <!--     <label style="margin-left: 0.5rem;">{{ item.Placeholder }}</label> -->
        <!-- </span> -->

        <span class="align-item-center p-col p-d-flex p-nogutter p-fluid p-inputgroup" >
            <span class="align-item-center p-col  p-fluid p-float-label " >
                <InputText class="p-col  textfield" v-bind:class="{'required_field': required == 'true', 'fill_textfield': previewText.length != 0}"  type="text" v-model="previewText" @input="changePreviewValue"/>
                <label style="margin-left: 0.5rem;">{{ item.Placeholder }}</label>
            </span>
            <span style="width: 80px;" class=" p-inputgroup-addon filledUnit" v-if="item.Unit" >{{item.Unit }}</span>
        </span>


    </template>
    <template v-else>
        <div class="align-item-center p-col p-d-flex no_padding p-fluid p-inputgroup" >
            <InputText v-if="disabled" class="p-col textfield" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}"  disabled/>
            <InputText v-else class="p-col textfield" v-model="item.Placeholder" @input="input"  v-bind:class="{'required_field': required == 'true'}" />
            <span
                style="width: 80px;max-height: 100%;" 
                class=" p-inputgroup-addon cursor_pointer" 
                :class="{'filledTemplateUnit': item.Unit}"
                v-if="editUnit==false" 
                @click="onClickEditUnit($event, true)">
                {{item.Unit ? item.Unit : $t("xtype.textField.unit")}}
            </span>
            <InputText class=" p-inputgroup-addon"  style="flex: none;width: 80px;" v-model="item.Unit" ref="editUnitInputRef" @blur="onClickEditUnit($event, false)" v-else>{{}}</InputText>
        </div>
    </template>
</template>

<script>
import InputText from 'primevue/inputtext';



export default {
    name: 'textField',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: ["setDataInItem", "clearRequirePreviewField"],
    components: {
        InputText,
    },
    created() {
        // console.log(this.item)
        // if(this.item.ItemId) {
        //     this.itemId = this.item.ItemId
        // } else {
        //     this.itemId = uuid.v1()
        // }
    },
    data() {
        return {
            allowField: null,
            previewText: "",
            editUnit: false,
            // labelValue: null,
            // inputValue: null,
        }
    },
    methods: {
        input: function() {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                IsRecordable: this.item.IsRecordable,
                Unit: this.item.Unit
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewText)))
        },

        onClickEditUnit: function(e, editUnit) {
            this.editUnit = editUnit
            if(this.editUnit) {
                this.$nextTick(function() {
                    console.log(this.$refs.editUnitInputRef)
                    this.$refs.editUnitInputRef.$el.focus()
                })
            } else {
                this.input()
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

