<template>
    <div  style=""  class="p-grid p-nogutter formViewContainer">
        <div class="p-grid p-nogutter" style="position: absolute; top: 0px; width: 100%; z-index: 2;">
            <operationButtonBar :Type="Type" @reloadTable="reloadTable" :canOPE="canWatch" />
        </div>
        <div class="customScrollPanel formView" ref="mainPanelRef"
            v-on:scroll="handleScroll"
            id="formView"
        >
            <template v-if="!canWatch">
                <div style="height: 30px" />
                <Nico :degree="nicoDegree"/>
                <div class="p-mt-2 text-center">
                    <span class="p-p-2" style="font-weight: bold; font-size: 20px; background-color: #f8f9fa; border-radius: 6px;" > {{$t("utils.selectAForm")}} </span>
                </div>
            </template>

            <template v-else-if="Type == 'Template'">
                <editTemplateForm 
                    @setScrollAmount="setScrollAmount($event)" 
                />
            </template>
            <template v-else>
                <viewPostedForm 
                    @setScrollAmount="setScrollAmount($event)"
                />
            </template>

            <ScrollTop target="parent" sticky=false :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
            <ScrollTop :reverse=true target="parent" sticky=false :threshold="100" class="custom-scrollbottom" icon="pi pi-arrow-down" />
        </div>
    </div>
</template>

<script>
import operationButtonBar from '@/components/operationButtonBar'
import editTemplateForm from '@/components/editTemplateForm'
import viewPostedForm from '@/components/viewPostedForm'
import Nico from '@/components/Nico';
import ScrollTop from 'primevue/scrolltop';

export default {
    name: 'FormView',
    props: ['Type', 'propScrollAmount', 'propScrollPreviewAmount', 'propIsPreview', 'canWatch', 'nicoDegree'],
    emits: ["reloadTable"],
    components: {
        operationButtonBar,
        editTemplateForm,
        viewPostedForm,
        ScrollTop,
        Nico,
    },
    data: function() {
        return {
            scrollAmount: this.propScrollAmount,
            scrollPreviewAmount: this.propScrollPreviewAmount,
        }
    },
    watch: {
    },
    created() {
    },
    computed: {
        isPreview: {
            get: function() {
                const value = this.$store.getters["editTemplate/getIsPreview"]
                return value
            },
        },
    },
    methods: {
        handleScroll(e) {
            const scrollTop = e.target.scrollTop
            if(this.isPreview) {
                this.scrollPreviewAmount = scrollTop
            } else {
                this.scrollAmount = scrollTop
            }
        },

        setScrollAmount(isNextPreview) {
            let nextScrollTop = 0;
            if(this.isPreview) {
                nextScrollTop = this.scrollPreviewAmount
            } else {
                nextScrollTop = this.scrollAmount
            }
            this.scrollTop(nextScrollTop)
        },

        initScrollAmount: function() {
            this.scrollAmount = 0
            this.scrollPreviewAmount = 0
            this.scrollTop(0)
        },

        scrollTop: function(value) {
            const self = this
            this.$nextTick(function () {
                self.$refs.mainPanelRef.scrollTop = value
            })
        },

        reloadTable: function() {
            this.$emit("reloadTable", true)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep(.custom-scrolltop) {
    width: 2rem;
    height: 2rem;
    /* border-radius: 4px; */
    background-color: #e9ecef;
    right: 30px;
    bottom: 220px;

    &:hover {
		/* background-color: var(--primary-color); */
        background-color: #e9ecef;
	}

    .p-scrolltop-icon {
        font-size: 1rem;
        color: #495057;
    }
}
::v-deep(.custom-scrollbottom) {
    width: 2rem;
    height: 2rem;
    /* border-radius: 4px; */
    background-color: #e9ecef;
    right: 30px;
    bottom: 160px;

    &:hover {
		/* background-color: var(--primary-color); */
        background-color: #e9ecef;
	}

    .p-scrolltop-icon {
        font-size: 1rem;
        color: #495057;
    }
}
</style>
