<template>
    <Dialog :header="$t('EditFormPasswordDialog.header')" v-model:visible="displayEditFormPasswordDialog" class="" style="width: 30vw;" :modal=true  >
        <div class="p-grid no_margin">
            <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                {{$t('EditFormPasswordDialog.info')}}
            </div>
            <div class="p-col-12 p-nogutter p-grid" style="">
                <Password ref="passwordRef" v-model="password" class="p-col FormPasswordInput" :class="{'p-invalid': invalid}" :feedback="false" :defaultMasked="true" :disabled="!editable" toggleMask />
                <Button :label="$t('EditFormPasswordDialog.copyButton')" class="p-button-outlined p-button-secondary" @click="onCopyButtonClick" />
            </div>
            <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                <div class="p-col" />
                <SelectButton v-model="isON" :options="off_on" optionLabel="name" optionValue="value" :unselectable=false :disabled="!editable" />
            </div>
        </div>
        <template #footer>
            <div class="p-d-flex">
                <div class="p-col" />
                <Button :label="$t('utils.close')" icon="pi pi-times" @click="onClose" class="p-button-text" />
                <Button :label="$t('utils.save')" @click="setData" :disabled="!editable" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip';
import Password from 'primevue/password';
import SelectButton from 'primevue/selectbutton';
import http from '../mixins/api'
import helper from '@/mixins/helper';


export default {
    name: 'EditFormPasswordDialog',
    props: ["displayEditFormPasswordDialog", "editable"],
    emits: ["updateEditFormPasswordDialog"],
    components: {
        Dialog,
        Button,
        Password,
        SelectButton,
    },
    watch: {
        displayEditFormPasswordDialog: async function(value) {
            if(value) {
                this.getData()
            } else {
                // await this.setData()
                this.password = ""
                this.isON = false
            }
            this.$emit("updateEditFormPasswordDialog", value)
        }
    },
    data: function() {
        return {
            password: "",
            off_on: [
                {
                    name: "OFF",
                    value: false,
                },
                {
                    name: "ON",
                    value: true,
                }
            ],
            isON: false,
            invalid: false,
        }
    },
    computed: {
    },
    methods: {
        onClose() {
            this.$emit("updateEditFormPasswordDialog", false)
        },
        async setData() {
            const templateId = this.$store.getters["getSelectRowId"];
            const password = this.password
            if(!password && this.isON) {
                this.invalid = true
                return
            }
            const res = await http.post("/form/UpdateFormTemplatePassword", {templateId: templateId, password: password, isActive: this.isON})
            if(res.Success) {
                this.invalid = false
                this.onClose()
            }
        },

        async getData() {
            const templateId = this.$store.getters["getSelectRowId"];
            const res = await http.post("/form/GetFormTemplatePassword", {templateId: templateId})
            if(res.Success) {
                await (this.password = res.Password)
                this.isON = res.IsActive
                if (this.$refs.passwordRef && this.$refs.passwordRef.$el.childNodes[0]) {
                    this.$refs.passwordRef.$el.childNodes[0].select()
                }
            }
        },

        onCopyButtonClick() {
            helper.copyTextToClipboard(this.password)
        },

    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
</style>
