<template>
    <fullEditorsEditor @setDataInItem="input($event)" :content="item.Placeholder" :disabled="preview || readOnly" :templateId="templateId"  :applicantMode="preview || readOnly"/>
</template>

<script>
import fullEditorsEditor from '@/components/templateFormParts/fullEditorsEditor'


export default {
    name: 'fullEditor',
    props: ['item', 'Id', 'readOnly', 'preview', 'disabled' , 'required', 'templateId'],
    emits: ['setDataInItem'],
    components: {
        fullEditorsEditor
    },
    data() {
        return {
        }
    },
    watch: {
    },
    mounted() {
    },

    beforeUnmount() {
    },

    computed: {
    },
    methods: {
        input: function(e={}) {
            const data = {
                Id: this.Id,
                Xtype: "fullEditor",
                ItemId: this.item.ItemId,
                Placeholder: e && e.html ? e.html : this.item.Placeholder,
            }

            this.$emit("setDataInItem", data)
        },
    },
    directives: {
    },
}
</script>

<style scoped>
</style>
