<template>
    <div style="width: 48px; height: 48px;margin: 0 auto;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"  
            :style="{
                transform: `rotate(${degree ? -degree+'deg' : defaultDegree+'deg'})`,
                fill: '#666'
            }"
        >
            <title>116_h_24</title>
            <rect class="a" width="48" height="48"/>
            <circle class="b" cx="24" cy="24" r="22"/><circle cx="15" cy="18" r="3"/><circle cx="33" cy="18" r="3"/>
            <path class="c" d="M33.8,36a10,10,0,0,0-19.6,0"/>
        </svg>
    </div>
</template>

<script>

export default {
    name: 'Nico',
    props: ['degree'],
    emits: [],
    components: {
    },
    data: function() {
        return {
            defaultDegree: -45
        }
    },
    created() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
}
</script>

<style scoped>
.a,.b,.c{fill:rgba(0,0,0,0);}
.b,.c{stroke:#666;stroke-miterlimit:10;stroke-width:2px;}
.c{stroke-linecap:round;}
</style>
