<template>
    <Button style="" class=" upgradeButton" @click="toUpgradeButtonClick" > {{$t("upgradePopup.upgradeButton")}} </Button>
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'UpgradeButton',
    emits: [''],
    components: {
        Button,
    },
    data: function() {
        return {
        }
    },
    created() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
        toUpgradeButtonClick: function() {
            const DepartmentList = this.$store.getters["userBranchInfo/getBelongDepartmentList"]
            if(DepartmentList.length) {
                const departmentId = DepartmentList[0]._id
                this.$router.push({ path: `/plans/info/${departmentId}`});
            }
        }
    },
}
</script>

<style scoped>
.upgradeButton {
    background-color: #EDDBF4; 
    color: #172b4d; 
    border-radius: 4px; 
    border-color: #EDDBF4;
}
.upgradeButton:active,
.upgradeButton:hover {
    border-color: #EDDBF4;
    background-color: #DFC0EB;
    color: #172b4d; 
}
</style>

