<template>
    <Dialog v-model:visible="displayDialog"  class="mailDialog" contentStyle="position: relative;"  :style="{width: '40vw'}">
        <template #header>
            <template v-if="readOnly">
                <span class="p-dialog-title">
                    {{$t("mailDialog.header.sentEmail")}}
                </span>
            </template>
            <template v-else>
                <span class="p-dialog-title">
                    <template v-if="OPE=='Accept'">
                        {{$t("mailDialog.header.receivedEmail")}}
                    </template>
                    <template v-else-if="OPE=='Reject'">
                        {{$t("mailDialog.header.notReceivedEmail")}}
                    </template>
                    <template v-else-if="OPE=='Done'">
                        {{$t("mailDialog.header.doneEmail")}}
                    </template>
                </span>
            </template>
        </template>
        <template v-if="readOnly">
            <div class="p-grid">
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <div class="p-col-12 p-nogutter p-fluid text-right" style="margin-bottom: 10px;">
                        <label class="" style="">{{$t("mailDialog.sendDate")}}
                            {{ $filters.formatDate(SentDate, null, "HH:mm:ss") }} 
                        </label>
                    </div>

                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">From: </label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="From">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">ReplyTo: </label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="ReplyToAddress">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">To: </label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="To">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">CC: </label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="CC">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">BCC: </label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="BCC">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.subject")}}</label>
                        <div class="p-col" style="white-space: break-spaces; word-break: break-word;" v-html="Subject">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid p-field p-grid" style="">
                        <div class="p-col-12" style="white-space: break-spaces; word-break: break-word;" v-html="Content">  </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="IsBulkTo">
            <div class="p-grid">
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.bulkFrom")}} </label>
                        <div class="p-col">
                            <div class="p-inputgroup">
                                <InputText type="text" v-model="From" ref="fromNameRef" :disabled="!editFromName" :placeholder="$t('mailDialog.blankBulkCompanyName')" />
                                <Button icon="pi pi-pencil" class="p-button-secondary p-button-outlined" @click="onEditFromNameClick" v-tooltip="$t('mailDialog.FromNameInfo')" />
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.bulkReplayTo")}} </label>
                        <div class="p-col">
                            <div class="p-inputgroup">
                                <InputText type="text" v-model="ReplyToAddress" disabled="true" readonly />
                                <Button icon="pi pi-sync" class="p-button-secondary p-button-outlined" @click="onIsReplyToButtonClick" v-tooltip="$t('mailDialog.ReplyToAddressInfo')" />
                                <div style="display: none;" > {{isReplyTo}} </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">To: </label>
                        <div class="p-col">
                            <InputText type="text" :modelValue="To ? To : $t('mailDialog.ToBulkMailText')"  :disabled=true />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="" >
                        <label class="p-col-fixed" style="width:100px">CC: </label>
                        <div class="p-col p-nogutter  p-grid">
                            <div class="p-pr-1" style="align-self: center;">
                                <RadioButton id="ccONRadio" name="cc" value="true" v-model="isOnCC" />
                                <label for="ccONRadio"> ON </label>
                                <RadioButton id="ccOFFRadio" name="cc" value="false" v-model="isOnCC" />
                                <label for="ccOFFRadio"> OFF </label>
                            </div>
                            <div class="p-col">
                                <InputText :class="{'p-invalid': invalidCCEmails}" :disabled="isOnCC == 'false'" v-model="CCGroupEmails"  @paste.prevent="onPasteCc" class="silverBack" :placeholder="$t('mailDialog.BCCEmailsPlaceholder')"  />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="" >
                        <label class="p-col-fixed" style="width:100px">BCC: </label>
                        <div class="p-col">
                            <InputText :class="{'p-invalid': invalidBCCEmails}" v-model="BCCGroupEmails"  @paste.prevent="onPasteBcc" :placeholder="$t('mailDialog.BCCEmailsPlaceholder')"  />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.subject")}}</label>
                        <div class="p-col">
                            <InputText type="text" v-model="Subject" />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid p-field p-grid" style="">
                        <div class="p-col-12">
                            <Textarea type="text" v-model="DefaultText"  :autoResize="true" rows="5" />
                        </div>
                        <div class="p-col-12" style="white-space: break-spaces;" v-html="Footer" v-if="!beforeSendView">  </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="" v-if="!From">
                        <span style="font-size: small;">{{$t('mailDialog.bulkMailInfo')}}</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="p-grid">
                {{$t("mailDialog.sendEmailDialodText1")}} <br />
                {{$t("mailDialog.sendEmailDialodText2")}} <br />
                {{$t("mailDialog.WriterBrowserLanguage", {WriterBrowserLanguage: WriterBrowserLanguage})}} <br />
                
                <div class="p-col-12 p-nogutter  p-fluid p-grid" style="">
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.from")}} </label>
                        <div class="p-col">
                            <div class="p-inputgroup">
                                <InputText type="text" v-model="From" ref="fromNameRef" :disabled="!editFromName" :placeholder="$t('formDataTable.blankCompanyName')" />
                                <Button icon="pi pi-pencil" class="p-button-secondary p-button-outlined" @click="onEditFromNameClick" v-tooltip="$t('mailDialog.FromNameInfo')" />
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.replayTo")}} </label>
                        <div class="p-col">
                            <div class="p-inputgroup">
                                <InputText type="text" v-model="ReplyToAddress" disabled="true" readonly />
                                <Button icon="pi pi-sync" class="p-button-secondary p-button-outlined" @click="onIsReplyToButtonClick" v-tooltip="$t('mailDialog.ReplyToAddressInfo')" />
                                <div style="display: none;" > {{isReplyTo}} </div>
                            </div>
                        </div>
                    </div>


                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">To: </label>
                        <div class="p-col">
                            <InputText type="text" :modelValue="To" :disabled=true />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="" >
                        <label class="p-col-fixed" style="width:100px">CC: </label>
                        <div class="p-col p-nogutter  p-grid">
                            <div class="p-pr-1" style="align-self: center;">
                                <RadioButton id="ccONRadio" name="cc" value="true" v-model="isOnCC" />
                                <label for="ccONRadio"> ON </label>
                                <RadioButton id="ccOFFRadio" name="cc" value="false" v-model="isOnCC" />
                                <label for="ccOFFRadio"> OFF </label>
                            </div>
                            <div class="p-col">
                                <MultiSelect :disabled="isOnCC == 'false'" v-model="CCGroupUserIds" class="silverBack" :options="GroupUsers" optionLabel="Name" optionValue="_id" display="chip"  />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="" >
                        <label class="p-col-fixed" style="width:100px">BCC: </label>
                        <div class="p-col">
                            <!-- <InputText type="text" :modelValue="BCC" :disabled=true /> -->
                            <MultiSelect v-model="BCCUserIds" :options="GroupUsers" optionLabel="Name" optionValue="_id" display="chip"  />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid  p-field p-grid" style="">
                        <label class="p-col-fixed" style="width:100px">{{$t("mailDialog.subject")}}</label>
                        <div class="p-col">
                            <InputText type="text" v-model="Subject" />
                        </div>
                    </div>
                    <div class="p-col-12 p-nogutter p-fluid p-field p-grid" style="">
                        <!-- <div class="p-col-12" style="white-space: break-spaces;" v-html="DefaultText">  </div> -->
                        <div class="p-col-12">
                            <Textarea type="text" v-model="DefaultText"  :autoResize="true" rows="5" />
                        </div>
                        <!-- <div class="p-col-12"> -->
                        <!--     <Textarea type="text" v-model="TemplateText"  :autoResize="true" rows="5" /> -->
                        <!-- </div> -->
                        <div class="p-col-12" style="white-space: break-spaces;" v-html="Footer" v-if="!beforeSendView">  </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <template v-if="readOnly">
                <div class="p-d-flex p-jc-between">
                    <Button :label="$t('utils.close')" icon="pi pi-times" @click="onClose" class="p-button-text"/>
                </div>
            </template>
            <template v-else>
                <div class="p-d-flex p-jc-between">
                    <Button :label="$t('mailDialog.forceClose')" icon="pi pi-times" @click="onClose" class="p-button-text"/>
                    <Button style="margin: 0px;" :label="$t('utils.send')" icon="pi pi-check" @click="onSendMail" class="p-button-outlined p-button-info" />
                </div>
            </template>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import Tooltip from 'primevue/tooltip'
import validator from "@/mixins/validator"

import http from '../mixins/api'

export default {
    name: 'mailDialog',
    props: ["displayDialog", "beforeSendView", 'readOnly', 'OPE'],
    emits: ["closeDialog"],
    components: {
        Dialog,
        InputText,
        Button,
        Textarea,
        MultiSelect,
        RadioButton,
    },
    data: function() {
        return {
            editFromName: false,
            isOnCC: "false",
            invalidCCEmails: false,
            invalidBCCEmails: false,
        }
    },
    created() {
        this.editFromName = false
    },
    computed: {
        SentDate: {
            get: function() {
                return this.$store.getters["sentMailStore/getSentDate"]
            },
        },
        From: {
            get: function() {
                return this.$store.getters["sentMailStore/getFromCompanyName"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setFromCompanyName", value)
            },
        },
        To: {
            get: function() {
                return this.$store.getters["sentMailStore/getTo"]
            },
        },
        IsBulkTo: {
            get: function() {
                return this.$store.getters["sentMailStore/getIsBulkTo"]
            },
        },
        CC: {
            get: function() {
                return this.$store.getters["sentMailStore/getCC"]
            },
        },
        BCC: {
            get: function() {
                return this.$store.getters["sentMailStore/getBCC"]
            },
        },
        Subject: {
            get: function() {
                return this.$store.getters["sentMailStore/getSubject"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setSubject", value)
            }
        },
        Content: {
            get: function() {
                return this.$store.getters["sentMailStore/getContent"]
            },
        },
        DefaultText: {
            get: function() {
                return this.$store.getters["sentMailStore/getDefaultText"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setDefaultText", value)
            }
        },
        TemplateText: {
            get: function() {
                return this.$store.getters["sentMailStore/getTemplateText"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setTemplateText", value)
            }
        },
        Footer: {
            get: function() {
                return this.$store.getters["sentMailStore/getFooter"]
            },
        },
        WriterBrowserLanguage: {
            get: function() {
                return this.$store.getters["sentMailStore/getWriterBrowserLanguage"]
            },
        },
        GroupUsers: {
            get: function() {
                return this.$store.getters["sentMailStore/getGroupUsers"]
            },
        },
        BCCUserIds: {
            get: function() {
                return this.$store.getters["sentMailStore/getBCCGroupUserIds"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setBCCGroupUserIds", value)
            },
        },
        BCCGroupEmails: {
            get: function() {
                return this.$store.getters["sentMailStore/getBCCGroupEmails"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setBCCGroupEmails", value)
            },
        },
        CCGroupUserIds: {
            get: function() {
                return this.$store.getters["sentMailStore/getCCGroupUserIds"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setCCGroupUserIds", value)
            },
        },
        CCGroupEmails: {
            get: function() {
                return this.$store.getters["sentMailStore/getCCGroupEmails"]
            },
            set: function(value) {
                this.$store.commit("sentMailStore/setCCGroupEmails", value)
            },
        },
        ReplyToAddress: {
            get: function() {
                return this.$store.getters["sentMailStore/getReplyToAddress"]
            },
        },
        isReplyTo: {
            get: function() {
                const isReplyTo = this.$store.getters["sentMailStore/getIsReplyTo"]
                const userInfo = this.$store.getters["auth/getUserInfo"];
                let replyToAddress = ""
                if(isReplyTo) {
                    replyToAddress = userInfo && userInfo.Email ? userInfo.Email : "";
                } else {
                    replyToAddress = "no-reply@bankinvoice.com";
                }
                this.$store.commit("sentMailStore/setReplyToAddress", replyToAddress)
            },
        },
    },
    watch: {
        displayDialog: function(value){
            this.editFromName = false
            if(!value) {
                this.$emit("closeDialog", {sent: false})
            }
            this.isOnCC = "false"
        },
        isOnCC: function(value) {
            if(value == "true") {

            } else {
                if(this.IsBulkTo) {
                    this.CCGroupEmails = "" 
                    this.invalidCCEmails = false
                } else {
                    this.CCGroupUserIds = []
                }
            }
        },
    },
    methods: {
        onClose() {
            this.$emit("closeDialog", {sent: false})
        },

        onSendMail() {
            if(this.IsBulkTo) {
                const BCCValid = this.BCCGroupEmails.split(',').some(x => !x ? false : !validator.isEmail(x))
                const CCValid = this.CCGroupEmails.split(',').some(x => !x ? false : !validator.isEmail(x))

                this.invalidCCEmails = CCValid
                this.invalidBCCEmails = BCCValid
                if(CCValid || BCCValid) {
                    return 
                }
            }
            this.$emit("closeDialog", {sent: true})
        },
        onEditFromNameClick() {
            this.editFromName = !this.editFromName
            if(this.editFromName) {
                const self = this
                this.$nextTick(function() {
                    self.$refs.fromNameRef.$el.select()
                })
            }
        },
        onIsReplyToButtonClick() {
            const isReplyTo = !this.$store.getters["sentMailStore/getIsReplyTo"]
            this.$store.commit("sentMailStore/setIsReplyTo", isReplyTo)
            const userInfo = this.$store.getters["auth/getUserInfo"];
            userInfo.isReplyTo = isReplyTo
            this.$store.commit("auth/setUserInfo", userInfo);
        },

        onPasteCc(e) {
            const pasted = e.clipboardData.getData('text');
            this.CCGroupEmails = pasted.replace(/(\r\n|\n|\r|\t)/g, ",")
        },

        async onPasteBcc(e) {
            const pasted = e.clipboardData.getData('text');
            const text = pasted.replace(/(\r\n|\n|\r|\t)/g, ",")
            this.BCCGroupEmails = text
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
