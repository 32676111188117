<template>
    <div class="p-grid no_margin" style="">
        <template v-for="paymentMethod in paymentMethodList">
            <div class="p-col-12 p-grid" v-bind:class="{'paymentMethodActiveBox': activePaymentMethodId == paymentMethod.id}">
                <RadioButton class="p-col-fixed" style="width: 50px;"  :id="paymentMethod.id" @change="onCardChange" v-model="activePaymentMethodId" :value="paymentMethod.id" />
                <label class="p-col p-grid cursor_pointer" :for="paymentMethod.id"  >
                    <span class="text-ellipsis p-col-12"> {{paymentMethod.card.brand}} ****{{paymentMethod.card.last4}}</span>
                    <template v-if="activePaymentMethodId == paymentMethod.id">
                        <div class="p-col-12 p-grid" >
                            <span class="text-ellipsis p-col-12">email: {{paymentMethod.billing_details.email}}</span>
                            <span class="text-ellipsis p-col-12">name: {{paymentMethod.billing_details.name}}</span>
                            <span class="text-ellipsis p-col-12"> exp_year: {{paymentMethod.card.exp_year}}</span>
                            <span class="text-ellipsis p-col-12"> exp_month: {{paymentMethod.card.exp_month}}</span>
                        </div>
                    </template>
                </label>
            </div>
        </template>
    </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';

import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import http from '@/mixins/api';


export default {
    name: 'paymentMentodList',
    props: ['departmentId', 'activePaymentMethodId'],
    emits: ['onActivePaymentMethodIdEmit'],
    components: {
        RadioButton,
        Dialog,
    },
    data: function() {
        return {
            paymentMethodList: [],
        }
    },
    watch: {
        departmentId: async function() {
            const res = await http.post("/customer/GetCustomersPaymentMethods", {DepartmentId: this.departmentId})
            console.log(res)
            this.paymentMethodList = res.result
        }
    },
    async created() {
    },
    computed: {
    },
    methods: {
        onCardChange: function() {
            this.$emit('onActivePaymentMethodIdEmit', this.activePaymentMethodId)
        },
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
</style>


