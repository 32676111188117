<template>
    <Dialog v-model:visible="display" :modal=true>
        <template #header>
            {{$t('inviteUserDialog.header')}}
        </template>
        <div class="p-grid p-fluid">
            <div class="p-col-12" style="padding: 0.25rem;">
                <div class="p-inputgroup">
                    <AutoComplete style="margin-right: 10px;" forceSelection :multiple="true" :dropdown="true" v-model="DepartmentList" :suggestions="filteredDepartmentList" @complete="searchDepartment($event)" field="Name" @item-select="addDepartmentList" :placeholder="$t('inviteUserDialog.inviteFieldPlaceholder')" />
                    <Button @click="addMemberInTemplate"> {{$t('inviteUserDialog.invite')}} </Button>
                </div>
            </div>
        </div>
        <div style="margin-top: 6px; margin-bottom: 6px;">
            <span>{{$t('inviteUserDialog.shareMemberText')}}</span>
        </div>
        <DataView :value="MemberList" :layout="list">
            <template #list="slotProps">
                <div class="p-col-12">
                    <template v-if="slotProps.data.Type == 'Department' && !slotProps.data.IsInvited">
                        {{slotProps.data.Name }} {{$t('inviteUserDialog.department')}}
                    </template>
                    <template v-if="slotProps.data.Type == 'User' && !slotProps.data.IsInvited">
                        {{slotProps.data.Name }} {{$t('inviteUserDialog.creator')}}
                    </template>
                    <template v-if="slotProps.data.Type == 'Department' && slotProps.data.IsInvited">
                        {{slotProps.data.Name }} {{$t('inviteUserDialog.sharedDepartment')}}
                    </template>
                    <template v-if="slotProps.data.Type == 'User' && slotProps.data.IsInvited">
                        {{slotProps.data.Name }} {{$t('inviteUserDialog.sharedUser')}}
                    </template>

                    <Button icon="pi pi-times" 
                            class="p-button-rounded p-button-danger p-button-text" @click="removeShareMemberInTemplate($event, slotProps.data)" 
                            v-if="slotProps.data.IsInvited" 
                            style="height: 1rem; width: 1rem;"
                            v-tooltip="$t('inviteUserDialog.unShareButtonTooltip')"
                        />

                </div>
            </template>
        </DataView>
        <div class="text-right">
            <span class="link" @click="onShareTemplateInfoClick">{{$t('inviteUserDialog.toShareView')}}</span>
        </div>
    </Dialog>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataView from 'primevue/dataview';
import AutoComplete from 'primevue/autocomplete';
import Chips from 'primevue/chips';
import Tooltip from 'primevue/tooltip'

import http from '../../mixins/api';

export default {
    name: 'inviteUserDialog',
    props: ['items', 'displayProp'],
    components: {
        AutoComplete,
        Chips,
        InputText,
        DataView,
        OverlayPanel,
        Dialog,
        Button,
    },
    data() {
        return {
            display: this.displayProp,
            MemberList: null,
            DepartmentList: null,
            filteredDepartmentList: null,
        }
    },
    watch: {
        display: async function(val) {
            if(val) {
                this.$store.dispatch("userBranchInfo/setMemberList")
                await this.loadVisibleTemplateMember()
            }
        }
    },
    computed: {
    },
    methods: {
        loadVisibleTemplateMember: async function() {
            const res = await http.post("/user/GetVisibleTemplateMember", {templateId: this.$store.getters["getSelectRowId"]})
            if(res.Success) {
                res.MemberList.sort(function(a, b) {
                    if(a.Type == "Department") return -1;
                    else return 1;
                })
                const member = res.MemberList.filter(x => !x.IsInvited)
                const InvitedMember = res.MemberList.filter(x => x.IsInvited)
                this.MemberList = [...member, ...InvitedMember]
                await this.$store.dispatch('gridDataStore/updateTemplateRow', {id: this.$store.getters["getSelectRowId"]})
            }
        },
        closeButtonClick: function(e) {
            this.display = false;
        },
        toggle: function(e) {
            this.display = true;
        },
        async searchDepartment(event) {
            const regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
            const query = event.query
            this.filteredDepartmentList = []
            this.$store.commit("userBranchInfo/setDepartmentListByQuery", query)
            let nextFilteredDepartmentList = []
            let member = this.$store.getters["userBranchInfo/getDepartmentListInSuggesting"]
            const CompanyList = this.$store.getters["userBranchInfo/getCompanyList"]
            for(let i = 0; i < member.length; i++) {
                const Company = CompanyList.find(x => x._id == member[i].CompanyId)
                const CompanyName = Company ? " (" + Company.Name + ")" : " ()"
                // member[i].Name = member[i].Name + CompanyName
                nextFilteredDepartmentList.push({
                    _id: member[i]._id,
                    Name: member[i].Name + CompanyName,
                    Type: member[i].Type,
                    IsOPE: member[i].IsOPE,
                    IsActive: member[i].IsActive,
                    CompanyId: member[i].CompanyId,
                })
            }

            nextFilteredDepartmentList = nextFilteredDepartmentList.map(m => {m.Type = "Department"; return m})
            if(regexp.test(query)) {
                nextFilteredDepartmentList.push({
                    Name: this.$i18n.tc("inviteUserDialog.inviteByEmail") + query,
                    Email: query,
                    Type: "User",
                    IsTmp: true
                })
            }



            this.filteredDepartmentList.splice(0, this.filteredDepartmentList.length, ...nextFilteredDepartmentList)
        },

        addDepartmentList(event) {
            const value = event.value
            if(value.IsTmp) {
                const DepartmentList = this.DepartmentList
                const tmpIndex = DepartmentList.findIndex(x => x.IsTmp == true)
                value.IsTmp = false
                value.Name = value.Email
                this.DepartmentList.splice(tmpIndex, 1, value)

            }
        },

        async addMemberInTemplate(event) {
            const DepartmentList = this.DepartmentList
            const postData = []
            const inviteDataList = []
            for(let i = 0; i < DepartmentList.length; i++) {
                inviteDataList.push({
                    Type: DepartmentList[i].Type ? DepartmentList[i].Type : "Department",
                    Email: DepartmentList[i].Email ? DepartmentList[i].Email : null,
                    DepartmentId: DepartmentList[i]._id
                })
            }
            const res = await http.post("/form/InviteDepartmentInTemplate", {templateId: this.$store.getters["getSelectRowId"], inviteDataList: inviteDataList})
            if(res.Message) {
                if(res.Success) {
                    this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
            }
            await this.loadVisibleTemplateMember()
        },

        async removeShareMemberInTemplate(event, data) {
            if(data.IsInvited){ 
                const self = this
                this.$confirm.require({
                    message: this.$i18n.tc("inviteUserDialog.isDeleteShareMember"),
                    accept: async function() {
                        const res = await http.post("/form/RemoveShareMemberInTemplate", {templateId: self.$store.getters["getSelectRowId"], removeInfoId: data.VisibleId})
                        self.$toast.add({severity:'success', summary: res.Message, life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        await self.loadVisibleTemplateMember()
                    },
                    reject: async function(){
                        self.$toast.add({severity:'error', summary: res.Message, life: self.$constants.errorHelperLife, group: self.$constants.defaultToastGroup});
                        await self.loadVisibleTemplateMember()
                    }
                })
            }

        },

        async onShareTemplateInfoClick(e) {
            const id = this.$store.getters["getSelectRowId"]
            this.$router.push({ name: 'changeRegistrationInfo', query: { activeIndex: 1, activeTemplateId: id } });
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>

