import VueScrollTo from 'vue-scrollto';

export default {
    name: 'scroll',

    To(ToId, ContainerId, options={}) {
        options = {
            container: "#" + ContainerId,
            x: false,
            y: true
        }
        VueScrollTo.scrollTo("#"+ToId, 500, options)
    }
}
