<template>
    <div class="p-field p-col no_margin no_padding">
        <template v-if="readOnly">
            <template v-for="(x, index) in item.LabelList">
                <div class="p-field-radiobutton p-col p-nogutter no_padding p-fluid" v-if="x.label" v-bind:class="{no_margin_bottom: index == item.LabelList.length - 1}">
                    <!-- <RadioButton value="x.value" v&#45;model="item.Value" :disabled="true"/> -->
                    <RadioButton :value="x.value.toString()" v-model="item.Value" :disabled="true"/>
                    <label> {{x.label}} </label>
                </div>
            </template>
            <div v-if="item.Value == 'other'" class="p-col-12 no_padding" >
                <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
                <InputText
                    v-bind:class="{'fill_textfield': item.LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col clear_disabled " type="text" v-model="item.LabelList.find(x => x.value == 'other').otherValue"   
                    disabled/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </template>
        <template v-else-if="preview">
            <template v-for="(x, index) in previewLabelList">
                <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid  " v-bind:class="{no_margin_bottom: (index == item.LabelList.length - 1)}">
                    <RadioButton :value="x.value.toString()" v-model="previewCheckboxValue" @change="changePreviewValue" />
                    <label> {{x.label}} </label>
                </div>
            </template>
            <div v-if="previewCheckboxValue == 'other'" class="p-col-12 no_padding" >
                <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
                <InputText
                    v-bind:class="{'fill_textfield': previewOtherValue }"
                    class="p-col textfield " type="text" v-model="previewOtherValue"   
                    autoFocus/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>

            </div>
        </template>
        <template v-else>
            <template v-for="(x, index) in item.LabelList">
                <div class="p-field-radiobutton p-col p-nogutter no_padding p-fluid " v-bind:class="{no_margin_bottom: index == (item.LabelList.length - 1)}">
                    <RadioButton :disabled="true"/>
                    <!-- <InputText class="p-col-12 clear_disabled" v-model="x.label" @input="input" v-bind:class="{empty_field: !x.label}"  :disabled="disabled"/> -->
                    <InputText v-model="x.label" @input="input" v-bind:class="{empty_field: !x.label}"  :disabled="disabled"/>
                </div>
            </template>
            <div class=" p-mt-1 p-ml-0 p-mr-0 p-col-12 no_padding p-grid align-item-center ">
                <Checkbox class="p-mr-1" v-model="item.AddOtherInSelectionField" :binary="true" @change="onAddOtherInSelectionFieldChange" /> <label> {{$t('xtype.radioBoxGroupField.otherLabel')}} </label>
                <div v-if="!item.AddOtherInSelectionField" class="p-col" />
                <InputText class="p-col" v-if="item.AddOtherInSelectionField" v-model="item.Placeholder" @input="input" :disabled="disabled" :placeholder="$t('xtype.radioBoxGroupField.otherInputPlaceholder')" />
            </div>
        </template>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox'



export default {
    name: 'radioBoxGroupField',
    props: ['item', 'Id', 'readOnly', 'preview', 'disabled'],
    emits: ["clearRequirePreviewField"],
    components: {
        InputText,
        RadioButton,
        Checkbox,
    },
    data() {
        return {
            boxList: [],
            previewCheckboxValue: [],
            previewOtherValue: "",
        }
    },
    created() {
        if(!this.item.Placeholder) {
            this.item.Placeholder = ""
        }
    },
    computed: {
        previewLabelList: {
            get() {
                const LabelList = this.item.LabelList
                console.log(LabelList.findIndex(x => x.value == "other"))
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.radioBoxGroupField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
    },
    watch: {
        'item.AddOtherInSelectionField': function(AddOtherInSelectionField) {
            if (AddOtherInSelectionField) {
                if (this.item.Placeholder == "") {
                    this.item.Placeholder = this.$i18n.tc('xtype.radioBoxGroupField.otherInputPlaceholder')
                }
            }
        }
    },
    methods: {
        input: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "radioBoxGroupField",
                ItemId: this.item.ItemId,
                LabelList: this.item.LabelList,
                Placeholder: this.item.Placeholder ? this.item.Placeholder : "",
            }

            this.$emit("setDataInItem", data)
        },
        
        changePreviewValue: function() {
            console.log(String(!Boolean(this.previewCheckboxValue)))
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewCheckboxValue)))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>





