<template>
    <template v-if="readOnly">
        <label class="p-col-fixed align-item-center" style="width:40px">{{ item.Label }}</label>
    </template>
    <template v-else-if="preview">
        <label class="p-col-fixed align-item-center" style="width:40px">{{ item.Label }}</label>
    </template>
    <template v-else>
        <InputText v-if="disabled" style="width: 30px" class=" textfield " v-model="item.Label" @input="input"  v-bind:class="{'required_field': required == 'true'}"  disabled/>
        <InputText v-else style="width: 30px" class=" textfield" v-model="item.Label" @input="input"  v-bind:class="{'required_field': required == 'true'}" />
    </template>
</template>

<script>
import http from '../../mixins/api'
import InputText from 'primevue/inputtext';
import { uuid } from "vue-uuid";
import store from '@/store'



export default {
    name: 'simpleText',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: ["setDataInItem", "clearRequirePreviewField"],
    components: {
        InputText,
    },
    created() {
        // this.input()
    },
    data() {
        return {
            allowField: null,
            previewText: "",
        }
    },
    methods: {
        input: function() {
            const data = {
                Id: this.Id,
                Xtype: "simpleText",
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                IsRecordable: false,
            }

            this.$emit("setDataInItem", data)
        },
        changePreviewValue: function() {
            this.$emit("clearRequirePreviewField", String(!Boolean(this.previewText)))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


