<template>
    <template v-if="fullTable">
        <FormDataTable ref="dataTable" :Type="Type" :Status="Status" :Loading="Loading" @selectRow="selectRow" v-if="viewDataTable" @reloadTable="reloadTable" />
        <template v-if="canWatch">
            <FormView
                    ref="FormView"
                    :Status="Status"
                    :Type="Type" 
                    :canWatch="true"
                    :nicoDegree="degree"
                    />
        </template>
    </template>
    <template v-else>
        <Splitter layout="vertical" style="height: 100%;" stateKey="stateKey" ref="topSplitter" class="mainFormViewSplitterPanel">
            <SplitterPanel :size="30" minSize="10" style="overflow: hidden;position: relative;" ref="topSplitterPanel"  >
                <FormDataTable ref="dataTable" :Type="Type" :Status="Status" :Loading="Loading" @selectRow="selectRow" @reloadTable="reloadTable" 
                @startTopSplitterPanel="startTopSplitterPanel"
                @touchStartTopSplitterPanel="touchStartTopSplitterPanel"
                @moveTopSplitterPanel="moveTopSplitterPanel"
                @endTopSplitterPanel="endTopSplitterPanel"
                />
            </SplitterPanel>
            <SplitterPanel :size="70" minSize=10 maxSize=90 style="position: relative;">
                <template v-if="showSpinner">
                    <div class="progressSpinnerContainer" style="z-index:2;">
                        <ProgressSpinner />
                    </div>
                </template>
                <FormView
                    ref="FormView"
                    :Status="Status"
                    :Type="Type" 
                    @reloadTable="reloadTable"
                    :canWatch="canWatch"
                    :nicoDegree="degree"
                />
            </SplitterPanel>
        </Splitter>
    </template>
</template>

<script>
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Nico from '@/components/Nico';
import FormDataTable from '@/components/FormDataTable'
import FormView from '@/components/FormView'
import ProgressSpinner from 'primevue/progressspinner';
import isMobile from '@/mixins/device'

export default {
    name: 'MainPaperFormView',
    // props: ['Type', 'Status', 'canWatch', 'fullTable'],
    props: {
        Type: String,
        Status: String, 
        canWatch: String, 
        fullTable: {
            type: Boolean,
            default: function() {
                return isMobile.isMobile()
            }
        },
    },
    emits: [''],
    components: {
        Splitter,
        SplitterPanel,
        FormDataTable,
        Nico,
        FormView,
        ProgressSpinner,
    },
    data: function() {
        return {
            Loading: false,
            tryFetchDataCount: 0,
            degree: 45,
            countSpeed: 20,
            countTimer: null,
            isMobile,
        }
    },
    created() {
        this.timer()
    },
    unmounted() {
        clearInterval(this.countTimer);
    },
    computed: {
        showSpinner: {
            get: function() {
                return this.$store.getters["gridDataStore/getShowSpinner"]
            }
        },
        viewDataTable: {
            get: function() {
                const view = this.$store.getters["gridDataStore/getViewDataTable"]
                return view
            },
            set: function(value) {
                this.$store.commit("gridDataStore/setViewDataTable", value)
            }
        },
        Loading: {
            get: function() {
                const view = this.$store.getters["gridDataStore/getLoading"]
                return view
            },
            set: function(value) {
                this.$store.commit("gridDataStore/setLoading", value)
            }
        }
    },
    watch: {
        Type: {
            immediate: true,
            handler(value) {
                this.$store.commit("gridDataStore/setGridPageData", {Type: this.Type, Status: this.Status, gridPageData: 0})
                // this.$store.commit("gridDataStore/setGridPageData", {Type: this.Type, Status: this.Status})
                this.fetchData()
            }
        },
        Status: {
            immediate: true,
            handler(value) {
                this.$store.commit("gridDataStore/setGridPageData", {Type: this.Type, Status: this.Status, gridPageData: 0})
                this.$store.commit("editTemplate/clear")
                this.$store.commit("viewPostedForm/clear")
                // this.$store.commit("gridDataStore/setGridPageData", {Type: this.Type, Status: this.Status})
                this.fetchData()
            }
        },
        $route: {
            immediate: true,
            handler(newValue, oldValue) {
                if (!oldValue) {
                    this.fetchData({DepartmentId: this.$route.params.departmentId, DetailFormStatusId: this.$route.params.detailFormStatusId})
                } else if(newValue.params.departmentId != oldValue.params.departmentId || newValue.params.detailFormStatusId != oldValue.params.detailFormStatusId || newValue.params.templateFormId != oldValue.params.templateFormId) {
                    this.fetchData({DepartmentId: this.$route.params.departmentId, DetailFormStatusId: this.$route.params.detailFormStatusId})
                }
            }
        },
        canWatch: {
            immediate: true,
            handler(value) {
                if(value) {
                    clearInterval(this.countTimer);
                }
            }
        }
    },
    methods: {
        timer() {
            const self = this;
            this.countTimer = setInterval(function(){
                self.degree++
                if(self.canWatch) {
                    clearInterval(self.countTimer);
                }
            }, self.countSpeed);
        },
        async fetchData(record={}) {
            this.tryFetchDataCount++
            const tryFetchDataCount = this.tryFetchDataCount
            this.$store.commit('gridDataStore/setLoading', true)
            const self = this;
            setTimeout(async function() {
                if(tryFetchDataCount == self.tryFetchDataCount) {
                    await self.$store.dispatch('gridDataStore/fetchGridDataList', {Type: self.Type, Status: self.Status, DepartmentId: record.DepartmentId ? record.DepartmentId : self.$route.params.departmentId, DetailFormStatusId: record.DetailFormStatusId ? record.DetailFormStatusId : self.$route.params.detailFormStatusId})
                    self.$store.commit('gridDataStore/setLoading', false)
                }
            }, 100)
        },

        async selectRow(event, value) {
            if(this.$refs.FormView) {
                this.$refs.FormView.initScrollAmount()
            }
        },

        async reloadTable() {
            this.fetchData()
        },

        async startTopSplitterPanel(e) {
            this.$refs.topSplitter.onGutterMouseDown(e, 0)
        },

        async touchStartTopSplitterPanel(e) {
            this.$refs.topSplitter.onGutterTouchStart(e, 0)
        },

        async moveTopSplitterPanel(e) {
            this.$refs.topSplitter.onGutterTouchMove(e, 0)
        },

        async endTopSplitterPanel(e) {
            this.$refs.topSplitter.onGutterTouchEnd(e, 0)
        },
    },
}
</script>

<style scoped>
</style>
